/* eslint-disable @typescript-eslint/no-explicit-any */
import thunk from 'redux-thunk';
import * as reduxModule from 'redux';
import { createHashHistory } from 'history';
import storage from 'redux-persist/lib/storage/session';
import { routerMiddleware } from 'connected-react-router';
import { persistStore, persistReducer } from 'redux-persist';
import { applyMiddleware, compose, createStore } from 'redux';
import Api from '../services';
import createReducer from './reducers';

/*
Fix for Firefox redux dev tools extension
https://github.com/zalmoxisus/redux-devtools-instrument/pull/19#issuecomment-400637274
 */
(reduxModule as any).__DO_NOT_USE__ActionTypes.REPLACE = '@@redux/INIT';

const composeEnhancers =
  process.env.NODE_ENV !== 'production' &&
  typeof window === 'object' &&
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

export const history = createHashHistory();
const rootReducer = createReducer(history);

const persistConfig = {
  key: 'root',
  blacklist: ['modals'],
  storage,
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const enhancer = composeEnhancers(applyMiddleware(thunk.withExtraArgument({ Api }), routerMiddleware(history)));

export default () => {
  const store = createStore(persistedReducer, enhancer);
  const persistor = persistStore(store);
  return { store, persistor };
};
