import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IFormState } from '../../helpers/form';
import { IStore } from '../../types';
import * as Actions from '../../store/actions';
import { IAddStudent, schemaUpdate } from '../../validation/addStudent';
import StudentForm from './Form';

interface IProps {
  studentId: string;
  onSubmit: (form: IFormState<IAddStudent>) => void;
}

const ChildDetail = (props: IProps) => {
  const dispatch = useDispatch();
  const student = useSelector((state: IStore) => state.students.item);
  const fetchStudent = useCallback(() => {
    dispatch(Actions.fetchStudentById(props.studentId));
  }, [dispatch, props.studentId]);

  useEffect(() => {
    fetchStudent();
  }, [fetchStudent]);

  return (
    <React.Fragment>
      {student && student.id === props.studentId && (
        <StudentForm
          onSubmit={props.onSubmit}
          initialData={{ ...student, routes: student.routes.map((r) => r.id) }}
          schema={schemaUpdate}
          name="Update student"
        />
      )}
    </React.Fragment>
  );
};

export default ChildDetail;
