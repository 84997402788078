import axios from 'axios';
import { config } from '../config';
import { IStudent, IListParams, IGetListAPIResponse } from '../types';
import { IAddStudent } from '../validation/addStudent';

interface IGetAllChildsData {
  items: IStudent[];
  totalCount: number;
}

export default class RoutesService {
  private apiBaseUrl: string;

  private headers: { [key: string]: string } = {};

  constructor() {
    this.apiBaseUrl = config.apiBaseUrl;
  }

  public setAccessToken(token: string): RoutesService {
    this.headers['X-Session-Token'] = token;
    return this;
  }

  public async fetchAll(params: IListParams): Promise<IGetAllChildsData> {
    const response = await axios.request<IGetListAPIResponse<IStudent[]>>({
      params,
      method: 'get',
      url: `${this.apiBaseUrl}/children`,
      headers: this.headers,
    });
    const {
      data: items,
      headers: { 'x-total-count': totalCount },
    } = response;
    return { items, totalCount: Number(totalCount) };
  }

  public async addStudent(data: IAddStudent): Promise<IStudent> {
    const response = await axios.request<{ data: IStudent }>({
      method: 'post',
      url: `${this.apiBaseUrl}/children`,
      headers: this.headers,
      data: {
        ...data,
        routes: data.routes.map((r) => ({ id: r })),
      },
    });
    const { data: newChild } = response;
    return newChild;
  }

  public async fetchStudent(id: string): Promise<IStudent> {
    const response = await axios.request<{ data: IStudent }>({
      method: 'get',
      url: `${this.apiBaseUrl}/children/${id}`,
      headers: this.headers,
    });
    const { data: child } = response;
    return child;
  }

  public async updateStudent(id: string, data: IAddStudent): Promise<IStudent> {
    const response = await axios.request<{ data: IStudent }>({
      method: 'post',
      url: `${this.apiBaseUrl}/children/${id}/update`,
      headers: this.headers,
      data: {
        ...data,
        routes: data.routes.map((r) => ({ id: r })),
      },
    });
    const { data: child } = response;
    return child;
  }
}
