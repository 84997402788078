import axios from 'axios';
import { config } from '../config';
import { IAssistant, IListParams, IGetListAPIResponse } from '../types';
import { IAddAssistant } from '../validation/addAssistant';

interface IGetAllAssistantsData {
  items: IAssistant[];
  totalCount: number;
}

export default class AssitantsService {
  private apiBaseUrl: string;

  private headers: { [key: string]: string } = {};

  constructor() {
    this.apiBaseUrl = config.apiBaseUrl;
  }

  public setAccessToken(token: string): AssitantsService {
    this.headers['X-Session-Token'] = token;
    return this;
  }

  public async fetchAll(params: IListParams): Promise<IGetAllAssistantsData> {
    const response = await axios.request<IGetListAPIResponse<IAssistant[]>>({
      params,
      method: 'get',
      url: `${this.apiBaseUrl}/assistants`,
      headers: this.headers,
    });
    const {
      data: items,
      headers: { 'x-total-count': totalCount },
    } = response;
    return { items, totalCount: Number.parseInt(totalCount) };
  }

  public async addAssistant(data: IAddAssistant): Promise<IAssistant> {
    const response = await axios.request<{ data: IAssistant }>({
      method: 'post',
      url: `${this.apiBaseUrl}/assistants`,
      headers: this.headers,
      data,
    });
    const { data: newAssistant } = response;
    return newAssistant;
  }

  public async fetchAssistant(id: string): Promise<IAssistant> {
    const response = await axios.request<{ data: IAssistant }>({
      method: 'get',
      url: `${this.apiBaseUrl}/assistants/${id}`,
      headers: this.headers,
    });
    const { data: assistant } = response;
    return assistant;
  }

  public async updateAssistant(id: string, data: IAddAssistant): Promise<IAssistant> {
    const response = await axios.request<{ data: IAssistant }>({
      method: 'post',
      url: `${this.apiBaseUrl}/assistants/${id}/update`,
      headers: this.headers,
      data,
    });
    const { data: assistant } = response;
    return assistant;
  }

  public async disableAssistant(id: string): Promise<IAssistant> {
    const response = await axios.request<{ data: IAssistant }>({
      method: 'post',
      url: `${this.apiBaseUrl}/assistants/${id}/block`,
      headers: this.headers,
    });

    const { data: assistant } = response;
    return assistant;
  }

  public async activateAssistant(id: string): Promise<IAssistant> {
    const response = await axios.request<{ data: IAssistant }>({
      method: 'post',
      url: `${this.apiBaseUrl}/assistants/${id}/activate`,
      headers: this.headers,
    });

    const { data: assistant } = response;
    return assistant;
  }

  public async deleteAssistant(id: string): Promise<void> {
    await axios.request<void>({
      method: 'delete',
      url: `${this.apiBaseUrl}/assistants/${id}`,
      headers: this.headers,
    });
  }
}
