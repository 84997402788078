import * as Yup from 'yup';

export interface IAddStudent {
  firstName: string;
  lastName: string;
  pickUpNotes: string;
  routes: Array<string>;
  externalId: number | null;
}

export const schema = Yup.object().shape({
  firstName: Yup.string()
    .label('First name')
    .matches(/^[a-zA-Z]+$/, 'First name must contain alphabetic characters.')
    .required(),
  lastName: Yup.string()
    .required()
    .label('Last name')
    .matches(/^[a-zA-Z]+$/, 'Last name must contain alphabetic characters.'),
  pickUpNotes: Yup.string(),
  routes: Yup.array().of(Yup.string()),
  externalId: Yup.number().nullable(),
});

export const schemaUpdate = Yup.object().shape({
  firstName: Yup.string()
    .label('First name')
    .matches(/^[a-zA-Z]+$/, 'First name must contain alphabetic characters.'),
  lastName: Yup.string()
    .label('Last name')
    .matches(/^[a-zA-Z]+$/, 'First name must contain alphabetic characters.'),
  pickUpNotes: Yup.string(),
  routes: Yup.array().of(Yup.string()),
  externalId: Yup.number().nullable(),
});

export const initialData: IAddStudent = {
  firstName: '',
  lastName: '',
  pickUpNotes: '',
  routes: [],
  externalId: null,
};
