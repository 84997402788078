import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Route, Redirect, RouteProps } from 'react-router';
import { IStore } from '../types';
import * as Actions from '../store/actions';
import Sync from './user/Sync';

type IProps = {} & RouteProps;

const ProtectedRoute: React.FunctionComponent<IProps> = (props) => {
  const dispatch = useDispatch();
  const auth = useSelector((state: IStore) => state.auth);
  const isAuthenticated = auth && auth.accessToken;
  const needSync = auth.user?.districts?.length === 0;

  const fetchDistrictData = useCallback(() => {
    dispatch(Actions.getSelfDistrictsData());
  }, [dispatch]);

  useEffect(() => {
    fetchDistrictData();
  }, [fetchDistrictData]);

  if (!isAuthenticated) {
    return <Redirect to="/login" />;
  }

  return needSync ? <Route {...props} exact path="/sync" component={Sync} /> : <Route {...props} />;
};

export default ProtectedRoute;
