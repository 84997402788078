import React from 'react';
import { Fab, Grid, Link } from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/GetApp';
import { makeStyles } from '@material-ui/core/styles';
import Dropzone from 'react-dropzone';
import download from 'js-file-download';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import * as Actions from '../../store/actions';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    '& .MuiFormControl-root': {
      margin: theme.spacing(2),
      width: 400,
    },
  },
  container: {
    minWidth: 400,
    padding: theme.spacing(2),
  },
  modalHeader: {
    fontFamily: 'Muli',
    fontSize: '35px',
    lineHeight: 0.51,
    color: '#2d323e',
    marginBottom: '40px',
  },
  textField: {
    marginTop: 0,
    fontFamily: 'Muli',
    marginBottom: '26px',
  },
  upload: {
    cursor: 'pointer',
    textAlign: 'center',
    border: '1px dashed',
    width: '100%',
    padding: theme.spacing(2),
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    paddingTop: '16%',
    paddingBottom: '16%',
  },
  uploadActive: {
    background: 'rgba(0,109,179,.5)',
  },
  dropzoneContainer: {
    width: '100%',
  },
  dropzoneWrapper: {
    width: '100%',
    position: 'relative',
    paddingTop: '35%',
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

const buildId = (suffix: string) => {
  return `upload-routes-form-${suffix}`;
};

interface IProps {
  onSubmit: () => void;
}

export const UploadRoutesForm = (props: IProps) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const importFiles = (files: File[]) => {
    dispatch(Actions.importRoutes(files));
    props.onSubmit();
  };

  const preventDefault = (event: React.SyntheticEvent) => event.stopPropagation();

  return (
    <Grid container direction="column" justify="center" alignItems="flex-end">
      <Grid container direction="column" justify="center" alignItems="flex-start" className={classes.container}>
        <div className={classes.modalHeader} id={buildId('title')}>
          Upload Routes
        </div>
        <Grid item className={classes.dropzoneContainer}>
          <Dropzone onDrop={importFiles} accept={'.csv'} multiple={false}>
            {({ getRootProps, getInputProps, isDragActive }) => (
              <section className={classes.dropzoneWrapper}>
                <div
                  className={clsx(classes.upload, {
                    [classes.uploadActive]: isDragActive,
                  })}
                  {...getRootProps()}
                >
                  <input {...getInputProps()} />
                  {isDragActive ? (
                    <p>Drop the csv file here</p>
                  ) : (
                    <p>Drag and drop file or click here to open a file selection dialog</p>
                  )}
                </div>
              </section>
            )}
          </Dropzone>
        </Grid>
      </Grid>
      <Grid container direction="column" justify="space-between">
        <Grid item key={2} className={classes.margin}>
          <Link
            rel="noreferrer"
            target="_blank"
            href={`${window.location.origin}/Instructions.pdf`}
            onClick={preventDefault}
          >
            View instructions
          </Link>
        </Grid>
        <Grid item key={1}>
          <Fab
            variant="extended"
            color="primary"
            aria-label="add"
            className={classes.margin}
            onClick={() => {
              const template = 'title,schoolName,direction,firstName,lastName,pickUpNotes';
              const binary = new Blob([template], {
                type: 'text/plain',
              });
              download(binary, `template.csv`);
            }}
          >
            <DownloadIcon className={classes.extendedIcon} />
            Download template
          </Fab>
        </Grid>
      </Grid>
    </Grid>
  );
};
