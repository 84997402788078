import React, { useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../types';
import * as Actions from '../../store/actions';

interface IProps {
  tripId: string;
}

const useStyles = makeStyles(() => ({
  signatureImage: {
    width: '320px',
    height: 'auto',
  },
  signatureImageContainer: {
    width: '320px',
    overflow: 'hidden',
    border: '2px dashed rgba(0,0,0,.3)',
  },
}));

export const TripSignature = (props: IProps) => {
  const dispatch = useDispatch();

  const fetchTripSignature = useCallback(() => {
    dispatch(Actions.fetchTripSignature(props.tripId));
  }, [dispatch, props.tripId]);

  useEffect(() => {
    fetchTripSignature();
  }, [fetchTripSignature]);

  const { currentSignature } = useSelector((state: IStore) => state.trips);
  const classes = useStyles();

  return (
    <React.Fragment>
      <div className={classes.signatureImageContainer}>
        <img src={currentSignature} alt="Tracker trip signature" className={classes.signatureImage} />
      </div>
    </React.Fragment>
  );
};
