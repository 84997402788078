/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { IconButton, TablePagination } from '@material-ui/core';
import {
  KeyboardArrowRight,
  KeyboardArrowLeft,
  LastPage as LastPageIcon,
  FirstPage as FirstPageIcon,
} from '@material-ui/icons';
import { makeStyles, useTheme, Theme, createStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { IListParams } from '../types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
  }),
);

interface ITablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onChangePage: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
}

function ListPaginationActions(props: ITablePaginationActionsProps) {
  const classes = useStyles();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0 || count <= rowsPerPage}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0 || count <= rowsPerPage}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

type RowsPerPageOptions = Array<number | { label: string; value: number }>;

interface IListPaginationProps {
  rowsPerPageOptions?: RowsPerPageOptions;
  colSpan?: number;
  totalCount?: number;
  params?: IListParams;
  updateDataAction?: (params: IListParams) => {};
}

const ListPagination = (props: IListPaginationProps) => {
  const dispatch = useDispatch();
  const params: IListParams = props.params || { limit: 20, offset: 0 };

  const rowsPerPageOptions: RowsPerPageOptions = props.rowsPerPageOptions || [5, 10, 20, 100];
  const { colSpan = 3, totalCount = 0 } = props;

  const paramsPage = Math.round(params.offset / params.limit);
  const [page, setPage] = React.useState(paramsPage || 0);
  const [limit, setLimit] = React.useState(params.limit || 20);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
    if (props.updateDataAction) {
      dispatch(props.updateDataAction({ offset: newPage * limit, limit }));
    }
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const $rowsPerPage = parseInt(event.target.value, 10);
    setLimit($rowsPerPage);
    setPage(0);
    if (props.updateDataAction) {
      const params = {
        offset: 0,
        limit: $rowsPerPage,
      };
      dispatch(props.updateDataAction(params));
    }
  };
  return (
    <TablePagination
      rowsPerPageOptions={rowsPerPageOptions}
      colSpan={colSpan}
      count={totalCount}
      rowsPerPage={limit}
      page={page}
      SelectProps={{
        inputProps: { 'aria-label': 'rows per page' },
        native: true,
      }}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
      ActionsComponent={ListPaginationActions}
    />
  );
};

export default ListPagination;
