/* eslint-disable @typescript-eslint/no-explicit-any */
import MuiPhoneNumber from 'material-ui-phone-number';
import React, { useEffect, useCallback } from 'react';
import {
  Button,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Checkbox,
  ListItemText,
  FormHelperTextTypeMap,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  Tooltip,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { OverrideProps } from '@material-ui/core/OverridableComponent';
import { IStore } from '../../types';
import { useForm, IFormState } from '../../helpers/form';
import { IAddAssistant, schema, schemaUpdate } from '../../validation/addAssistant';
import * as Actions from '../../store/actions';
import { DoneButton } from '../UI/buttons/DoneButton';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    '& .MuiFormControl-root': {
      margin: theme.spacing(2),
      width: 400,
    },
  },
  noBorderRadius: {
    borderRadius: 0,
  },
  selectDone: {
    position: 'fixed',
    width: 400,
    borderRadius: 0,
  },
  textField: {
    marginTop: 0,
    fontFamily: 'Muli',
    marginBottom: '26px',
    minWidth: '270px',
  },
  textFieldWrapper: {
    marginRight: '10px',
  },
  modalHeader: {
    fontFamily: 'Muli',
    fontSize: '35px',
    lineHeight: 0.51,
    color: '#2d323e',
    marginBottom: '40px',
  },
  container: {
    minWidth: 400,
    padding: theme.spacing(2),
  },
  rowContainer: {
    minWidth: 400,
    padding: 0,
  },
  paper: {
    width: 250,
    height: 230,
    overflow: 'auto',
    padding: '10px',
  },
  button: {
    margin: theme.spacing(0.5, 0),
    minWidth: '10px',
  },
  routesTitleWrapper: { position: 'relative', paddingTop: '15px' },
  routesTitle: { top: '-35px', left: '25px' },
  routesListItem: { padding: '0' },
  spaceTop: { marginTop: theme.spacing(2) },
}));

interface IProps {
  onSubmit: (form: IFormState<IAddAssistant>) => void;
  initialData: IAddAssistant;
  schema: typeof schema | typeof schemaUpdate;
  name: string;
  additional?: JSX.Element;
}

const buildId = (suffix: string) => {
  return `create-assistant-form-${suffix}`;
};

const textInput: Partial<OverrideProps<FormHelperTextTypeMap<{}, 'p'>, 'p'>> = {
  style: {
    paddingLeft: 0,
    marginLeft: 0,
    fontFamily: 'Muli',
    fontSize: '10px',
    color: '#d75a4a',
    fontWeight: 'bold',
  },
};

function not(a: string[], b: string[]) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: string[], b: string[]) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

export const AssistantForm = (props: IProps) => {
  const dispatch = useDispatch();
  const { items: routes, params: routesParams } = useSelector((state: IStore) => state.routes.list);
  const fetchRoutes = useCallback(() => {
    dispatch(Actions.fetchRoutes(routesParams));
  }, [dispatch, routesParams]);

  useEffect(() => {
    fetchRoutes();
  }, [fetchRoutes]);
  const classes = useStyles();
  const [formState, setValue, handleChanges, handleSubmit] = useForm<IAddAssistant>(
    props.initialData,
    props.schema,
    props.onSubmit,
  );
  const { values, errors } = formState;

  const handleMobilePhoneChange = (value: any, context?: any) => {
    const event = {
      target: { name: 'mobilePhone', value: (value as string).replace(/[+()\- ]/g, ''), context },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      persist() {},
    };
    handleChanges(event as React.ChangeEvent<any>);
  };

  const handleRoutesChange = (value: string[]) => {
    const event = {
      target: { name: 'routes', value },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      persist() {},
    };
    handleChanges(event as React.ChangeEvent<any>);
  };

  const [checked, setChecked] = React.useState<string[]>([]);
  const [left, setLeft] = React.useState<string[]>(
    not(
      routes.map(({ id }) => id),
      values.routes,
    ),
  );
  const [right, setRight] = React.useState<string[]>([...values.routes]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const setRoutes = (value: string[]) => {
    handleRoutesChange(value);
    setRight(value);
  };

  const handleToggle = (value: string) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setRoutes(right.concat(left));
    setLeft([]);
  };

  const handleCheckedRight = () => {
    setRoutes(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRoutes(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRoutes([]);
  };

  const customList = (items: string[]) => (
    <Paper className={classes.paper}>
      <List dense component="div" role="list">
        {items.map((value: string) => {
          const labelId = `transfer-list-item-${value}-label`;
          const route = routes.find(({ id }) => id === value);

          return (
            <ListItem
              key={value}
              role="listitem"
              button
              onClick={handleToggle(value)}
              className={classes.routesListItem}
            >
              <ListItemIcon style={{ minWidth: '30px' }}>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={route?.title} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Paper>
  );

  return (
    <Grid container direction="column" justify="center" alignItems="flex-end">
      <Grid container direction="column" justify="center" alignItems="flex-start" className={classes.container}>
        <div className={classes.modalHeader} id={buildId('title')}>
          {values.internal ? props.name : "You can't edit the imported data"}
        </div>
        <Grid item xs={11}>
          <Grid container direction="row" justify="flex-start" alignItems="flex-start" className={classes.rowContainer}>
            <Grid className={classes.textFieldWrapper}>
              <Tooltip placement="left" title="Please enter the first name" arrow>
                <TextField
                  name="firstName"
                  label="First name"
                  value={values.firstName}
                  error={!!errors.firstName}
                  helperText={errors.firstName}
                  onChange={handleChanges}
                  required
                  FormHelperTextProps={textInput}
                  className={classes.textField}
                  variant="outlined"
                  disabled={!values.internal}
                />
              </Tooltip>
            </Grid>
            <Grid className={classes.textFieldWrapper}>
              <Tooltip placement="left" title="Please enter the last name" arrow>
                <TextField
                  name="lastName"
                  label="Last name"
                  required
                  value={values.lastName}
                  error={!!errors.lastName}
                  onChange={handleChanges}
                  helperText={errors.lastName}
                  FormHelperTextProps={textInput}
                  className={classes.textField}
                  variant="outlined"
                  disabled={!values.internal}
                />
              </Tooltip>
            </Grid>
          </Grid>
          <Grid container direction="row" justify="flex-start" alignItems="flex-start" className={classes.rowContainer}>
            <Grid className={classes.textFieldWrapper}>
              <Tooltip placement="left" title="Please enter a PIN" arrow>
                <TextField
                  name="password"
                  label="Four-Digit Pin"
                  required
                  type="text"
                  value={values.password}
                  error={!!errors.password}
                  onChange={handleChanges}
                  helperText={errors.password}
                  FormHelperTextProps={textInput}
                  className={classes.textField}
                  onFocus={() => {
                    setValue('password', '');
                  }}
                  onBlur={() => {
                    setValue('rawPassword', values.password);
                    if (values.password) setValue('password', '****');
                  }}
                  variant="outlined"
                  disabled={!values.internal}
                />
              </Tooltip>
              <TextField name="rawPassword" type="hidden" value={values.rawPassword} />
            </Grid>
            <Grid className={classes.textFieldWrapper}>
              <Tooltip placement="left" title="Please enter a phone number" arrow>
                <MuiPhoneNumber
                  name="mobilePhone"
                  label="Mobile Phone"
                  required
                  onChange={handleMobilePhoneChange}
                  value={values.mobilePhone}
                  error={!!errors.mobilePhone}
                  helperText={errors.mobilePhone}
                  defaultCountry={'us'}
                  onlyCountries={['us']}
                  disableAreaCodes={true}
                  countryCodeEditable={false}
                  FormHelperTextProps={textInput}
                  className={classes.textField}
                  variant="outlined"
                  disabled={!values.internal}
                />
              </Tooltip>
            </Grid>
          </Grid>
          <FormControl>
            <Grid container spacing={2} justify="center" alignItems="center" className={classes.root}>
              <Grid item>
                <div className={classes.routesTitleWrapper}>
                  <InputLabel id="assistant--unassigned-routes" className={classes.routesTitle} shrink={false}>
                    Unassigned Routes
                  </InputLabel>
                  {customList(left)}
                </div>
              </Grid>
              <Grid item>
                <Grid container direction="column" alignItems="center">
                  <Button
                    variant="outlined"
                    size="small"
                    className={classes.button}
                    onClick={handleAllRight}
                    disabled={left.length === 0 || !values.internal}
                    aria-label="move all right"
                  >
                    ≫
                  </Button>
                  <Button
                    variant="outlined"
                    size="small"
                    className={classes.button}
                    onClick={handleCheckedRight}
                    disabled={leftChecked.length === 0 || !values.internal}
                    aria-label="move selected right"
                  >
                    &gt;
                  </Button>
                  <Button
                    variant="outlined"
                    size="small"
                    className={classes.button}
                    onClick={handleCheckedLeft}
                    disabled={rightChecked.length === 0 || !values.internal}
                    aria-label="move selected left"
                  >
                    &lt;
                  </Button>
                  <Button
                    variant="outlined"
                    size="small"
                    className={classes.button}
                    onClick={handleAllLeft}
                    disabled={right.length === 0 || !values.internal}
                    aria-label="move all left"
                  >
                    ≪
                  </Button>
                </Grid>
              </Grid>
              <Grid item>
                <div className={classes.routesTitleWrapper}>
                  <InputLabel id="assistant--unassigned-routes" className={classes.routesTitle} shrink={false}>
                    Assigned Routes
                  </InputLabel>
                  {customList(right)}
                </div>
              </Grid>
            </Grid>
            <Grid container direction="row" justify="flex-start" alignItems="flex-start" className={classes.spaceTop}>
              {props.additional}
            </Grid>
          </FormControl>
        </Grid>
      </Grid>

      <Grid container direction="column" justify="center" alignItems="flex-end">
        <DoneButton
          id={buildId('submit-button')}
          onClick={(event: React.ChangeEvent<any>) => {
            handleSubmit(event);
          }}
          disabled={!values.internal}
        />
      </Grid>
    </Grid>
  );
};
