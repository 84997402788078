import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { IStore } from '../types';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

function ProgressBar(props: IProps) {
  const classes = useStyles();

  return props.loading ? (
    <div className={classes.root}>
      <LinearProgress />
    </div>
  ) : (
    <React.Fragment />
  );
}

function mapStateToProps(state: IStore) {
  return {
    loading: state.loading,
  };
}

type IProps = {} & ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(ProgressBar);
