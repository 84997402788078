import * as Actions from '../actions';
import { IAssistant, IListParams } from '../../types';

interface IInitialState {
  item: IAssistant | null;
  list: {
    items: IAssistant[];
    params: IListParams;
    totalCount: number;
  };
}

const initialState: IInitialState = {
  item: null,
  list: {
    items: [],
    params: {
      limit: 100,
      offset: 0,
    },
    totalCount: 0,
  },
};

const assistants = (state = initialState, action: Actions.AssistantActionTypes | Actions.ErrorActionTypes) => {
  switch (action.type) {
    case Actions.FETCH_ASSISTANTS_BEGIN: {
      return {
        ...state,
        list: {
          ...initialState.list,
          items: [],
        },
      };
    }
    case Actions.FETCH_ASSISTANTS_SUCCESS: {
      const { items, totalCount } = action.payload;
      return {
        ...state,
        list: { ...state.list, items, totalCount },
      };
    }
    case Actions.CREATE_ASSISTANT_SUCCESS: {
      const newAssistant = action.payload;
      return {
        ...state,
        list: { ...state.list, items: [...state.list.items, newAssistant] },
      };
    }
    case Actions.FETCH_ASSISTANT_BEGIN: {
      return {
        ...state,
        item: null,
      };
    }
    case Actions.FETCH_ASSISTANT_SUCCESS:
    case Actions.UPDATE_ASSISTANT_SUCCESS:
    case Actions.DISABLE_ASSISTANT_SUCCESS:
    case Actions.ACTIVATE_ASSISTANT_SUCCESS: {
      const assistant = action.payload;
      return {
        ...state,
        item: assistant,
        list: {
          ...state.list,
          items: state.list.items.map((i) => (i.id === assistant.id ? assistant : i)),
        },
      };
    }
    case '@@router/LOCATION_CHANGE':
    default: {
      return state;
    }
  }
};

export default assistants;
