import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Grid, Card, Button, TextField, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useForm, IFormState } from '../../helpers/form';
import { IAddUser, schemaAddUser } from '../../validation/addUser';
import * as Actions from '../../store/actions';
import { IStore } from '../../types';

const useStyles = makeStyles((theme) => ({
  form: {
    textAlign: 'center',
    '& .MuiFormControl-root': {
      margin: theme.spacing(2),
      width: 400,
    },
  },
  grid: {
    background: '#22262f',
  },
  card: {
    width: '515px',
    borderRadius: '4px',
    'text-align': 'center',
  },
  modalHeader: {
    fontFamily: 'Muli',
    fontSize: '35px',
    lineHeight: 0.51,
    color: '#2d323e',
    marginBottom: '40px',
  },
}));

const Join = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  // eslint-disable-next-line no-useless-escape
  const matched = search.match(/(\?|\&)email=([\w\.\@]+)\&?/);
  const email = matched && matched[2];

  const isSend = useSelector((state: IStore) => state.users.send);

  const onSubmit = (form: IFormState<IAddUser>) => {
    dispatch(Actions.addUser({ ...form.values }));
  };
  const classes = useStyles();

  const [formState, , handleChanges, handleSubmit] = useForm<IAddUser>(
    { username: email || '', password: '', firstName: '', lastName: '', passwordConfirmation: '' },
    schemaAddUser,
    onSubmit,
  );
  const { values, errors } = formState;

  return (
    <Grid container direction="column" justify="center" alignItems="center" className={classes.grid}>
      <Card className={classes.card}>
        {isSend ? (
          <div>
            <div>Thank you for the information.</div>
            <div>Your request is being processed. You will receive the confirmation by email soon.</div>
            <div>Please, close this page.</div>
          </div>
        ) : (
          <form className={classes.form} autoComplete="off">
            <div className={classes.modalHeader} id={'join-form-title'}>
              Join to DTTA
            </div>
            <Tooltip placement="left" title="Please enter the email" arrow>
              <TextField
                name="username"
                label="email"
                value={values.username}
                error={!!errors.username}
                helperText={errors.username}
                onChange={handleChanges}
                required
                variant="outlined"
              />
            </Tooltip>
            <Tooltip placement="left" title="Please enter the password" arrow>
              <TextField
                name="password"
                label="Password"
                value={values.password}
                error={!!errors.password}
                helperText={errors.password}
                onChange={handleChanges}
                required
                variant="outlined"
              />
            </Tooltip>
            <Tooltip placement="left" title="Please сonfirm the password" arrow>
              <TextField
                name="passwordConfirmation"
                label="Password Confirmation"
                value={values.passwordConfirmation}
                error={!!errors.passwordConfirmation}
                helperText={errors.passwordConfirmation}
                onChange={handleChanges}
                required
                variant="outlined"
              />
            </Tooltip>
            <Tooltip placement="left" title="Please enter the first name" arrow>
              <TextField
                name="firstName"
                label="First name"
                value={values.firstName}
                error={!!errors.firstName}
                helperText={errors.firstName}
                onChange={handleChanges}
                required
                variant="outlined"
              />
            </Tooltip>
            <Tooltip placement="left" title="Please enter the last name" arrow>
              <TextField
                name="lastName"
                label="Last name"
                value={values.lastName}
                error={!!errors.lastName}
                helperText={errors.lastName}
                onChange={handleChanges}
                required
                variant="outlined"
              />
            </Tooltip>
            <div style={{ marginBottom: '20px' }}>
              <Button
                variant="contained"
                color="primary"
                onClick={(event: React.ChangeEvent<any>) => {
                  handleSubmit(event);
                }}
              >
                Create
              </Button>
            </div>
          </form>
        )}
      </Card>
    </Grid>
  );
};

export default Join;
