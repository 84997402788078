import React from 'react';
import { Route, Switch } from 'react-router';
import Main from './Main';
import Logout from './Logout';
import Assistants from './assistant';
import AssistantDetail from './assistant/Details';
import Schools from './school';
import SchoolDetails from './school/Details';
import Routes from './route';
import RouteDetails from './route/Details';
import Students from './student';
import StudentDetails from './student/Details';
import Trips from './trip';
import Invite from './user';
import Profile from './profile';

const routes = () => (
  <Switch>
    <Route exact path="/" component={Main} />
    {/* <Route exact path="/dashboard" component={Dashboard} /> */}
    <Route exact path="/trackers" component={Assistants} />
    <Route exact path="/trackers/:id" component={AssistantDetail} />
    <Route exact path="/schools" component={Schools} />
    <Route exact path="/schools/:id" component={SchoolDetails} />
    <Route exact path="/routes" component={Routes} />
    <Route exact path="/routes/:id" component={RouteDetails} />
    <Route exact path="/students" component={Students} />
    <Route exact path="/students/:id" component={StudentDetails} />
    <Route exact path="/trips" component={Trips} />
    <Route exact path="/profile" component={Profile} />
    <Route exact path="/invite" component={Invite} />
    <Route exact path="/logout" component={Logout} />
  </Switch>
);

export default routes;
