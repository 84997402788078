import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import { Grid, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Fab } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import List, { IDataItem } from '../List';
import { IStore } from '../../types';
import * as Actions from '../../store/actions';
import CommonDialog from '../UI/dialog';
import { InviteUserForm } from './InviteUserForm';
import { PlusButton } from '../UI/buttons/PlusButton';
import { ReloadButton } from '../UI/buttons/ReloadButton';

const useStyles = makeStyles((theme) => ({
  // form: {
  //   textAlign: 'center',
  //   '& .MuiFormControl-root': {
  //     margin: theme.spacing(2),
  //     width: 400,
  //   },
  // },
  // grid: {
  //   background: '#22262f',
  // },
  // card: {
  //   width: '515px',
  //   borderRadius: '4px',
  //   'text-align': 'center',
  // },
  modalHeader: {
    fontFamily: 'Muli',
    fontSize: '35px',
    lineHeight: 0.51,
    color: '#2d323e',
    marginBottom: '40px',
  },
  firstColumn: {
    minWidth: 150,
  },
  table: {
    // minWidth: 700,
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  margin: {
    marginTop: theme.spacing(3),
    margin: theme.spacing(1),
  },
}));

const emptyForm = { id: '', isOpen: false, email: '', name: '' };

const UserList = () => {
  const dispatch = useDispatch();
  const { items, params } = useSelector((state: IStore) => state.users.list);
  const [{ id, isOpen, email, name }, setOpen] = useState(emptyForm);
  const [isInviteFormOpen, setInviteFormOpen] = useState(false);

  const handleInviteFormClose = () => {
    setInviteFormOpen(false);
  };

  const setHeader = useCallback(() => {
    dispatch(Actions.setSubheader('Admin Invites'));
  }, [dispatch]);

  const fetchUsers = useCallback(() => {
    dispatch(Actions.fetchUsers({ ...params }));
  }, [dispatch, params]);

  useEffect(() => {
    setHeader();
  }, [setHeader]);

  useEffect(() => {
    fetchUsers();
    const interval = setInterval(() => {
      fetchUsers();
    }, 10 * 1000);
    return () => clearInterval(interval);
  }, [fetchUsers]);

  const toConfirmHeader = [{ title: 'email' }, { title: 'First name' }, { title: 'Last name' }];
  const invitedHeader = [{ title: email }];

  const invited: IDataItem[] = items
    .filter(({ status }) => status === 'invited')
    .map((item) => {
      return {
        id: item.id,
        values: [item.email],
      };
    });

  const toConfirm: IDataItem[] = items
    .filter(({ status }) => status === 'blocked')
    .map((item) => {
      return {
        id: item.id,
        values: [item.email, item.firstName, item.lastName],
        onClick: () => {
          if (item.status !== 'blocked') return;
          setOpen({ id: item.id, isOpen: true, email: item.email, name: `${item.firstName} ${item.lastName}` });
        },
      };
    });

  const activate = () => {
    dispatch(Actions.activateUser(id));
    setOpen(emptyForm);
  };

  const handleClose = () => {
    setOpen(emptyForm);
  };
  const classes = useStyles();

  const rows = [{ name, email }];

  return (
    <React.Fragment>
      <CommonDialog onClose={handleInviteFormClose} aria-labelledby="invite-user-dialog" open={isInviteFormOpen}>
        <InviteUserForm onSubmit={() => setInviteFormOpen(false)} />
      </CommonDialog>
      <CommonDialog onClose={handleClose} aria-labelledby="activate-user-dialog" open={isOpen}>
        <div className={classes.modalHeader} id={'join-form-title'}>
          Admin Activation
        </div>
        <TableContainer>
          <Table className={classes.table} aria-label="customized table" size="small">
            <TableHead>
              <TableRow>
                <TableCell className={classes.firstColumn}>Name</TableCell>
                <TableCell align="left">Email</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell>{row.email}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Grid container direction="row" justify="space-between">
          <Fab
            variant="extended"
            size="medium"
            color="primary"
            aria-label="add"
            className={classes.margin}
            onClick={activate}
          >
            <ThumbUpIcon className={classes.extendedIcon} />
            Activate
          </Fab>
          <Fab
            variant="extended"
            size="medium"
            color="default"
            aria-label="add"
            className={classes.margin}
            onClick={handleClose}
          >
            <ThumbDownIcon className={classes.extendedIcon} />
            Skip
          </Fab>
        </Grid>
      </CommonDialog>
      <List
        title="Pending confirmation by the invited user"
        header={invitedHeader}
        data={invited}
        buttons={[
          <PlusButton
            id={'invite-admin-button'}
            key={0}
            onClick={() => setInviteFormOpen(true)}
            style={{ marginRight: '10px' }}
          />,
          <ReloadButton onClick={() => dispatch(Actions.fetchUsers({ ...params }))} key={0} />,
        ]}
      />
      <List title="Confirm registration" header={toConfirmHeader} data={toConfirm} />
    </React.Fragment>
  );
};

export default UserList;
