import React from 'react';
import moment from 'moment';

interface IProps {
  value?: string | Date;
  format?: string;
  timestamp?: boolean;
}

const Date = (props: IProps) => {
  const date = props.timestamp ? moment(props.value, 'X') : moment(props.value);
  return <React.Fragment>{date.format(props.format || 'YYYY-MM-DD HH:mm')}</React.Fragment>;
};

export default Date;
