import axios from 'axios';
import { config } from '../config';
import { ISchool, IListParams, IGetListAPIResponse } from '../types';
import { IAddSchool } from '../validation/addSchool';

interface IGetAllSchoolsData {
  items: ISchool[];
  totalCount: number;
}

export default class SchoolsService {
  private apiBaseUrl: string;

  private headers: { [key: string]: string } = {};

  constructor() {
    this.apiBaseUrl = config.apiBaseUrl;
  }

  public setAccessToken(token: string): SchoolsService {
    this.headers['X-Session-Token'] = token;
    return this;
  }

  public async fetchAll(params: IListParams): Promise<IGetAllSchoolsData> {
    const response = await axios.request<IGetListAPIResponse<ISchool[]>>({
      params,
      method: 'get',
      url: `${this.apiBaseUrl}/facilities`,
      headers: this.headers,
    });
    const {
      data: items,
      headers: { 'x-total-count': totalCount },
    } = response;
    return { items, totalCount: Number.parseInt(totalCount) };
  }

  public async addSchool(data: IAddSchool): Promise<ISchool> {
    const response = await axios.request<{ data: ISchool }>({
      method: 'post',
      url: `${this.apiBaseUrl}/facilities`,
      headers: this.headers,
      data,
    });
    const { data: newSchool } = response;
    return newSchool;
  }

  public async fetchSchool(id: string): Promise<ISchool> {
    const response = await axios.request<{ data: ISchool }>({
      method: 'get',
      url: `${this.apiBaseUrl}/facilities/${id}`,
      headers: this.headers,
    });
    const { data: school } = response;
    return school;
  }

  public async updateSchool(id: string, data: IAddSchool): Promise<ISchool> {
    const response = await axios.request<{ data: ISchool }>({
      method: 'post',
      url: `${this.apiBaseUrl}/facilities/${id}/update`,
      headers: this.headers,
      data,
    });
    const { data: school } = response;
    return school;
  }
}
