import { ThunkAction } from 'redux-thunk';
import { ITrip, IListParams, IStore, IApi, ICreateTrip } from '../../types';
import { showError } from './error.actions';
import { showNotification } from './notification.actions';
import { startLoading, completeLoading } from './loading.actions';

export const FETCH_TRIPS_BEGIN = 'FETCH_TRIPS_BEGIN';

export const FETCH_TRIPS_SUCCESS = 'FETCH_TRIPS_SUCCESS';

export const EXPORT_TRIPS_BEGIN = 'EXPORT_TRIPS_BEGIN';

export const EXPORT_TRIPS_SUCCESS = 'EXPORT_TRIPS_SUCCESS';

export const FETCH_TRIP_SIGNATURE_SUCCESS = 'FETCH_TRIP_SIGNATURE_SUCCESS';

export const CREATE_TRIPS_BEGIN = 'CREATE_TRIPS_BEGIN';

export const CREATE_TRIPS_SUCCESS = 'CREATE_TRIPS_SUCCESS';

type ListParams = IListParams & { checkedAtFrom?: string; checkedAtTo?: string };

interface ITripsFetchBegin {
  type: typeof FETCH_TRIPS_BEGIN;
}

interface ITripsFetchSuccess {
  type: typeof FETCH_TRIPS_SUCCESS;
  payload: {
    items: ITrip[];
    totalCount: number;
    params: ListParams;
  };
}

interface ITripsExportBegin {
  type: typeof EXPORT_TRIPS_BEGIN;
}

interface ITripsExportSuccess {
  type: typeof EXPORT_TRIPS_SUCCESS;
}

interface ITripSignatureSuccess {
  type: typeof FETCH_TRIP_SIGNATURE_SUCCESS;
  payload: string;
}

interface ITripCreateBegin {
  type: typeof CREATE_TRIPS_BEGIN;
}

interface ITripCreateSuccess {
  type: typeof CREATE_TRIPS_SUCCESS;
}

export type TripActionTypes =
  | ITripsFetchBegin
  | ITripsFetchSuccess
  | ITripsExportBegin
  | ITripsExportSuccess
  | ITripSignatureSuccess
  | ITripCreateBegin
  | ITripCreateSuccess;

export const fetchTrips = (params: ListParams): ThunkAction<void, IStore, { Api: IApi }, TripActionTypes> => async (
  dispatch,
  getState,
  { Api },
) => {
  try {
    dispatch(startLoading());
    dispatch({ type: FETCH_TRIPS_BEGIN });
    const trips = await Api.TripsService.setAccessToken(getState().auth.accessToken).fetchAll(params);
    return dispatch({
      type: FETCH_TRIPS_SUCCESS,
      payload: { ...trips, params },
    });
  } catch (e) {
    dispatch(showError(e));
  } finally {
    dispatch(completeLoading());
  }
};

export const fetchTripSignature = (id: string): ThunkAction<void, IStore, { Api: IApi }, TripActionTypes> => async (
  dispatch,
  getState,
  { Api },
) => {
  try {
    dispatch(startLoading());
    const image = await Api.TripsService.setAccessToken(getState().auth.accessToken).fetchTripSignature(id);
    return dispatch({
      type: FETCH_TRIP_SIGNATURE_SUCCESS,
      payload: image,
    });
  } catch (e) {
    dispatch(showError(e));
  } finally {
    dispatch(completeLoading());
  }
};

export const exportTrips = (
  params: IListParams & { checkedAtFrom?: string; checkedAtTo?: string },
): ThunkAction<void, IStore, { Api: IApi }, TripActionTypes> => async (dispatch, getState, { Api }) => {
  try {
    dispatch(startLoading());
    dispatch({ type: EXPORT_TRIPS_BEGIN });
    await Api.TripsService.setAccessToken(getState().auth.accessToken).export(params);
    return dispatch({
      type: EXPORT_TRIPS_SUCCESS,
    });
  } catch (e) {
    dispatch(showError(e));
  } finally {
    dispatch(completeLoading());
  }
};

export const createTripLogs = (data: ICreateTrip): ThunkAction<void, IStore, { Api: IApi }, TripActionTypes> => async (
  dispatch,
  getState,
  { Api },
) => {
  try {
    dispatch(startLoading());
    dispatch({ type: CREATE_TRIPS_BEGIN });
    await Api.TripsService.setAccessToken(getState().auth.accessToken).createLog(data);
    dispatch({
      type: CREATE_TRIPS_SUCCESS,
    });
    dispatch(showNotification('Saved successfully'));
    return dispatch(fetchTrips(getState().trips.list.params));
  } catch (e) {
    dispatch(showError(e));
  } finally {
    dispatch(completeLoading());
  }
};
