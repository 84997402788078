import React from 'react';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AccountCircle, VpnKey } from '@material-ui/icons';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Grid, TextField, InputAdornment, Button, Typography } from '@material-ui/core';
import { useForm, IFormState } from '../helpers/form';
import { IStore, ISignInForm } from '../types';
import { initialData, schema } from '../validation/signInForm';
import ErrorSnackbar from './ErrorSnackbar';

const buildId = (suffix: string) => {
  return `login-form-${suffix}`;
};

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      minWidth: 300,
      // padding: theme.spacing(2),
      paddingBottom: 0,
    },
    logoWrap: {
      width: '116px',
      height: '116px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: '#03a9f4',
      marginBottom: '49px',
    },
    logo: {
      width: '40px',
      height: 'auto',
    },
    loginHeader: {
      textAlign: 'center',
      fontFamily: 'Muli',
      fontSize: '17px',
      lineHeight: '30px',
      color: '#000000',
      opacity: 0.87,
      textTransform: 'uppercase',
      marginBottom: '30px',
    },
    loginText: {
      marginTop: '30px',
      textAlign: 'center',
      fontFamily: 'Muli',
      fontSize: '15px',
      lineHeight: '30px',
      color: '#000000',
      opacity: 0.87,

      b: {
        fontWeight: 'bold',
      },
    },
    username: {
      marginTop: 0,
      fontFamily: 'Muli',
      marginBottom: '26px',
    },
    password: {
      marginTop: 0,
      fontFamily: 'Muli',
    },
    resetPassword: {
      marginBottom: '26px',
      fontSize: '14px',
      marginTop: '5px',
    },
  }),
);

interface IProps {
  onSubmit: (form: IFormState<ISignInForm>) => void;
  needRedirect?: boolean;
}

function SignInForm(props: IProps) {
  const classes = useStyles();
  const accessToken = useSelector((state: IStore) => state.auth?.accessToken);

  const [formState, , handleChanges, handleSubmit] = useForm<ISignInForm>(initialData, schema, props.onSubmit);
  const { values, errors } = formState;
  const handleEnter = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleSubmit(event);
    }
  };

  if (props.needRedirect && accessToken) {
    return <Redirect to="/trackers" />;
  }
  return (
    <Grid item xs={11}>
      <TextField
        required
        id={buildId('username')}
        label="Username"
        defaultValue="User name"
        variant="outlined"
        className={classes.username}
        autoComplete="admin-login"
        name="username"
        value={values.username}
        error={!!errors.username}
        helperText={errors.username}
        onChange={handleChanges}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <AccountCircle />
            </InputAdornment>
          ),
        }}
      />

      <TextField
        required
        id={buildId('password')}
        label="Password"
        type="password"
        autoComplete=""
        variant="outlined"
        className={classes.password}
        name="password"
        value={values.password}
        error={!!errors.password}
        helperText={errors.password}
        onChange={handleChanges}
        onKeyDown={handleEnter}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <VpnKey />
            </InputAdornment>
          ),
        }}
      />
      <Typography className={classes.resetPassword}>
        <Link to="/restore">Forgot Password?</Link>
      </Typography>
      <ErrorSnackbar />
      <Button variant="contained" color="primary" id={buildId('submit-button')} onClick={handleSubmit}>
        SIGN IN
      </Button>
    </Grid>
  );
}

export default SignInForm;
