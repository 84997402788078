import { ThunkAction } from 'redux-thunk';
import { IStore } from '../../types';
import { IApi } from '../../types';

export const LOADING_STARTED = 'LOADING_STARTED';
export const LOADING_COMPLETED = 'LOADING_COMPLETED';

export const SHOW_PROGRESS = 'SHOW_PROGRESS';
export const HIDE_PROGRESS = 'HIDE_PROGRESS';

interface ILoadingStarted {
  type: typeof LOADING_STARTED;
}

interface ILoadingCompleted {
  type: typeof LOADING_COMPLETED;
}

interface IShowProgress {
  type: typeof SHOW_PROGRESS;
  message: string;
}

interface IHideProgress {
  type: typeof HIDE_PROGRESS;
}

export type LoadingActionTypes = ILoadingStarted | ILoadingCompleted | IShowProgress | IHideProgress;

export const startLoading = (): ThunkAction<void, IStore, { Api: IApi }, LoadingActionTypes> => async (dispatch) => {
  return dispatch({
    type: LOADING_STARTED,
  });
};

export const completeLoading = (): ThunkAction<void, IStore, { Api: IApi }, LoadingActionTypes> => async (dispatch) => {
  return dispatch({
    type: LOADING_COMPLETED,
  });
};

export const showProgress = (
  message: string,
  delay: number,
): ThunkAction<void, IStore, { Api: IApi }, LoadingActionTypes> => async (dispatch) => {
  dispatch({
    type: SHOW_PROGRESS,
    message,
  });
  setTimeout(
    () =>
      dispatch({
        type: HIDE_PROGRESS,
        message,
      }),
    delay,
  );
};
