import React from 'react';
import { Route, Switch } from 'react-router';
import { ThemeProvider } from '@material-ui/styles';
import { ConnectedRouter } from 'connected-react-router';
import './App.css';
import './assets/css/index.css';
import { history } from './store';
import theme from './config/theme';
import ProgressBar from './components/ProgressBar';
import PublicLayout from './components/PublicLayout';
import ProtectedRoute from './components/ProtectedRoute';
import ProtectedLayout from './components/ProtectedLayout';
import Join from './components/user/join';
import ForgotPassword from './components/ForgotPassword';
import SetPassword from './components/SetPassword';

function App() {
  return (
    <ConnectedRouter history={history}>
      <ThemeProvider theme={theme}>
        <ProgressBar />
        <Switch>
          <Route exact path="/login" component={PublicLayout} />
          <Route exact path="/join" component={Join} />
          <Route exact path="/restore" component={ForgotPassword} />
          <Route exact path="/password-reset-confirm/:code" component={SetPassword} />
          <ProtectedRoute path="/" component={ProtectedLayout} />
        </Switch>
      </ThemeProvider>
    </ConnectedRouter>
  );
}

export default App;
