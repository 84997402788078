import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../types';
import CustomizedSnackbar from './CustomizedSnackbar';
import * as Actions from '../store/actions';

function SuccessSnackbar() {
  const notification = useSelector((state: IStore) => state.notification);
  const dispatch = useDispatch();
  return (
    <CustomizedSnackbar
      variant={notification.type || 'success'}
      open={!!notification.message}
      message={notification.message}
      onClose={() => dispatch(Actions.hideNotification())}
    />
  );
}

export default SuccessSnackbar;
