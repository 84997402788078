import axios from 'axios';
import download from 'js-file-download';
import { config } from '../config';
import { ITrip, IListParams, IGetListAPIResponse, ICreateTrip } from '../types';

interface IGetAllTripsData {
  items: ITrip[];
  totalCount: number;
}

interface ITripSignatureResponse {
  image: string;
}

export default class TripsService {
  private apiBaseUrl: string;

  private headers: { [key: string]: string } = {};

  constructor() {
    this.apiBaseUrl = config.apiBaseUrl;
  }

  public setAccessToken(token: string): TripsService {
    this.headers['X-Session-Token'] = token;
    return this;
  }

  public async fetchAll(
    params: IListParams & { checkedAtFrom?: string; checkedAtTo?: string },
  ): Promise<IGetAllTripsData> {
    const response = await axios.request<IGetListAPIResponse<ITrip[]>>({
      params,
      method: 'get',
      url: `${this.apiBaseUrl}/trips/transactions`,
      headers: this.headers,
    });
    const {
      data: items,
      headers: { 'x-total-count': totalCount },
    } = response;
    return { items, totalCount: Number.parseInt(totalCount) };
  }

  public async export(params: IListParams & { checkedAtFrom?: string; checkedAtTo?: string }): Promise<void> {
    const response = await axios.request<{ data: BinaryType }>({
      params,
      method: 'get',
      url: `${this.apiBaseUrl}/trips/transactions/export`,
      headers: this.headers,
    });
    const { data: file } = response;
    download(file, `trips-log-${params.checkedAtFrom}-${params.checkedAtTo}.csv`);
  }

  public async fetchTripSignature(id: string): Promise<string> {
    const response = await axios.request<{ data: ITripSignatureResponse }>({
      method: 'get',
      url: `${this.apiBaseUrl}/trips/${id}/assistant-signature`,
      headers: this.headers,
    });
    const {
      data: { image },
    } = response;
    return image;
  }

  public async createLog(data: ICreateTrip): Promise<void> {
    await axios.request({
      method: 'post',
      url: `${this.apiBaseUrl}/trips`,
      headers: this.headers,
      data,
    });
  }
}
