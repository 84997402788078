export const SET_DRAWER = '[APP SETTINGS] SET_DRAWER';
export const SET_ACTIVITY = '[APP SETTINGS] SET_ACTIVITY';
export const SET_SUBHEADER = '[APP SETTINGS] SET_SUBHEADER';

interface IDrawerOpen {
  type: typeof SET_DRAWER;
  payload: boolean;
}

interface IActivityOpen {
  type: typeof SET_ACTIVITY;
  payload: boolean;
}

interface ISubheaderSet {
  type: typeof SET_SUBHEADER;
  payload: string;
}

export type AppSettingsActionTypes = IDrawerOpen | IActivityOpen | ISubheaderSet;

export function setDrawer(drawer: boolean): IDrawerOpen {
  return {
    type: SET_DRAWER,
    payload: drawer,
  };
}

export function setActivity(activity: boolean): IActivityOpen {
  return {
    type: SET_ACTIVITY,
    payload: activity,
  };
}

export function setSubheader(subheader: string): ISubheaderSet {
  return {
    type: SET_SUBHEADER,
    payload: subheader,
  };
}
