import { ThunkAction } from 'redux-thunk';
import { IListParams, IStore, IUser, IApi } from '../../types';
import { IApiErrorResponse, showError } from './error.actions';
import { startLoading, completeLoading } from './loading.actions';
import { IAddUser } from '../../validation/addUser';
import { showNotification } from './notification.actions';

export const CHECK_USER_USE_SUCCESS = 'CHECK_USER_USE_SUCCESS';

export const CREATE_USER_BEGIN = 'CREATE_USER_BEGIN';

export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';

export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';

export const ACTIVATE_USER_SUCCESS = 'ACTIVATE_USER_SUCCESS';

interface IUserUseBegin {
  type: typeof CHECK_USER_USE_SUCCESS;
}

interface IUserCreateBegin {
  type: typeof CREATE_USER_BEGIN;
}

interface IUserCreateSuccess {
  type: typeof CREATE_USER_SUCCESS;
  payload: IUser;
}

interface IUsersFetchSuccess {
  type: typeof FETCH_USERS_SUCCESS;
  payload: {
    items: IUser[];
    totalCount: number;
  };
}

interface IUserActivateSuccess {
  type: typeof ACTIVATE_USER_SUCCESS;
  payload: string;
}

export type UserActionTypes =
  | IUserUseBegin
  | IUserCreateBegin
  | IUserCreateSuccess
  | IUsersFetchSuccess
  | IUserActivateSuccess;

export const addUser = (params: IAddUser): ThunkAction<void, IStore, { Api: IApi }, UserActionTypes> => async (
  dispatch,
  getState,
  { Api },
) => {
  try {
    dispatch(startLoading());
    dispatch({
      type: CREATE_USER_BEGIN,
    });
    const newUser = await Api.UserService.setAccessToken(getState().auth.accessToken).addUser(params);
    return dispatch({
      type: CREATE_USER_SUCCESS,
      payload: newUser,
    });
  } catch (e) {
    dispatch(showError(e));
  } finally {
    dispatch(completeLoading());
  }
};

export const fetchUsers = (
  params: IListParams & { status?: string },
): ThunkAction<void, IStore, { Api: IApi }, UserActionTypes> => async (dispatch, getState, { Api }) => {
  try {
    dispatch(startLoading());
    const users = await Api.UserService.setAccessToken(getState().auth.accessToken).fetchAll(params);
    return dispatch({
      type: FETCH_USERS_SUCCESS,
      payload: users,
    });
  } catch (e) {
    dispatch(showError(e));
  } finally {
    dispatch(completeLoading());
  }
};

export const activateUser = (id: string): ThunkAction<void, IStore, { Api: IApi }, UserActionTypes> => async (
  dispatch,
  getState,
  { Api },
) => {
  try {
    dispatch(startLoading());
    dispatch({
      type: CREATE_USER_BEGIN,
    });
    await Api.UserService.setAccessToken(getState().auth.accessToken).activateUser(id);
    dispatch({
      type: ACTIVATE_USER_SUCCESS,
      payload: id,
    });
    dispatch(showNotification('Admin is successfully activated'));
    return dispatch(fetchUsers({ ...getState().users.list.params, status: 'blocked' }));
  } catch (e) {
    dispatch(showError(e));
  } finally {
    dispatch(completeLoading());
  }
};

export const checkUserUse = (params: {
  username: string;
}): ThunkAction<void, IStore, { Api: IApi }, UserActionTypes> => async (dispatch, getState, { Api }) => {
  try {
    dispatch(startLoading());
    await Api.UserService.setAccessToken(getState().auth.accessToken).checkUserUse(params);
    dispatch(showNotification(`Admin invite is successfully sent to ${params.username}`));
    return dispatch({
      type: CHECK_USER_USE_SUCCESS,
    });
  } catch (error) {
    const apiErrorResponse = error as IApiErrorResponse;
    if (apiErrorResponse.response.status === 409) {
      return dispatch(
        showError({
          ...error,
          response: {
            ...error.response,
            data: { ...error.response.data, message: `Admin with email ${params.username} is already registered` },
          },
        }),
      );
    }
    return dispatch(showError(error));
  } finally {
    dispatch(completeLoading());
  }
};
