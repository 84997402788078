import { History } from 'history';
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import auth from './auth.reducer';
import error from './error.reducer';
import assistants from './assistants.reducer';
import loading from './loading.reducer';
import progress from './progress.reducer';
import navigation from './navigation.reducer';
import appSettings from './appSettings.reducer';
import notification from './notification.reducer';
import schools from './schools.reducer';
import routes from './routes.reducer';
import students from './students.reducer';
import trips from './trips.reducer';
import users from './user.reducer';

const createReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    navigation,
    assistants,
    auth,
    loading,
    error,
    progress,
    appSettings,
    notification,
    schools,
    routes,
    students,
    trips,
    users,
  });

export default createReducer;
