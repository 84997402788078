import clsx from 'clsx';
import * as React from 'react';
import { useSelector } from 'react-redux';
import Header from './Header';
import { IStore } from '../types';
import theme from '../config/theme';
import Navigator from './Navigator';
import { buildId } from '../helpers/utils';
import ErrorSnackbar from './ErrorSnackbar';
import ProtectedRoutes from './ProtectedRoutes';
import SuccessSnackbar from './SuccessSnackbar';
import homeIcon from '../assets/images/icons/home.svg';
import CssBaseline from '@material-ui/core/CssBaseline';
import arrowRight from '../assets/images/icons/arrowRightBreadcrumbs.png';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';

const drawerWidth = 240;

const styles = createStyles({
  root: {
    width: '100%',
    display: 'flex',
    minHeight: '100vh',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: '66px',
  },
  activityOpen: {
    width: '330px',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  activityClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: '0',
  },
  app: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  main: {
    flex: 1,
    maxHeight: '100%',
    overflow: 'scroll',
    background: '#f5f5f5',
  },
  mainWrapper: {
    padding: theme.spacing(6, 4),
  },
  footer: {
    padding: theme.spacing(2),
    background: '#eaeff1',
  },
  wrapper: {
    display: 'flex',
    height: '100%',
    paddingTop: '61px',
  },
  activityWrap: {
    borderTop: '1px solid #e0e0e0',
    borderLeft: '1px solid #e0e0e0',
    background: 'white',
    overflow: 'scroll',
    maxHeight: '100%',
  },
  subHeader: {
    backgroundColor: '#2d323e',
    height: '100px',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '28px',
  },
  subHeaderTitle: {
    fontFamily: 'Muli',
    fontSize: '35px',
    lineHeight: 0.51,
    color: '#ffffff',
    marginTop: '30px',
  },
  homeIcon: {
    width: '16px',
    cursor: 'pointer',
  },
  arrowRight: {
    width: '6px',
    marginLeft: '15px',
    marginRight: '15px',
  },
  breadcrumbItem: {
    fontFamily: 'Muli',
    fontSize: '13px',
    lineHeight: 1.38,
    textTransform: 'uppercase',
    color: '#ffffff',
  },
  breadcrumbsBlock: {
    display: 'flex',
    alignItems: 'center',
  },
});

export interface IAppProps extends WithStyles<typeof styles> {}

function ProtectedLayout(props: IAppProps) {
  const appSettings = useSelector((state: IStore) => state.appSettings);
  const { classes } = props;
  return (
    <div className={classes.root}>
      <CssBaseline />
      <nav
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: appSettings.isDrawerOpen,
          [classes.drawerClose]: !appSettings.isDrawerOpen,
        })}
      >
        <Navigator />
      </nav>
      <div className={classes.app}>
        <Header />
        <div className={classes.wrapper}>
          <main className={classes.main}>
            <div className={classes.subHeader}>
              <div>
                <div className={classes.breadcrumbsBlock}>
                  <img src={homeIcon} className={classes.homeIcon} alt={'home icon'} />
                  {appSettings.subheader !== 'Dashboard' && (
                    <React.Fragment>
                      <img src={arrowRight} className={classes.arrowRight} alt={'arrow rigth'} />
                      <div className={classes.breadcrumbItem} id={buildId(props, 'page-title')}>
                        {appSettings.subheader}
                      </div>
                    </React.Fragment>
                  )}
                </div>
                {/*<div className={classes.subHeaderTitle} id={buildId(props, 'page-title')}>*/}
                {/*  {appSettings.subheader}*/}
                {/*</div>*/}
              </div>
            </div>
            <div className={classes.mainWrapper}>
              <ErrorSnackbar />
              <SuccessSnackbar />
              <ProtectedRoutes />
            </div>
          </main>
        </div>
        {/* <footer className={classes.footer}></footer> */}
      </div>
    </div>
  );
}

export default withStyles(styles)(ProtectedLayout);
