import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Card, TextField, Grid, Tooltip } from '@material-ui/core';
import { useForm, IFormState } from '../../helpers/form';
import { IStore } from '../../types';
import * as Actions from '../../store/actions';
import List from '../List';
import { IUpdateUser, schemaUpdateUser } from '../../validation/addUser';
import { DoneButton } from '../UI/buttons/DoneButton';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiFormControl-root': {
      marginBottom: theme.spacing(2),
    },
  },
  tableContainer: {
    position: 'relative',
  },
}));

const Profile = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { user } = useSelector((state: IStore) => state.auth);
  const { districts, currentDistrictId } = user;

  const [formState, , handleChanges, handleSubmit] = useForm<IUpdateUser>(
    { username: user.email, firstName: user.firstName, lastName: user.lastName },
    schemaUpdateUser,
    (form: IFormState<IUpdateUser>) => {
      dispatch(Actions.updateSelf(form.values));
    },
  );
  const { values, errors } = formState;

  const setHeader = useCallback(() => {
    dispatch(Actions.setSubheader('Admin Profile'));
  }, [dispatch]);

  useEffect(() => {
    setHeader();
  }, [setHeader]);

  const data = districts.map((district, index: number) => ({
    values: [
      index + 1,
      district.name,
      district.hasToken ? 'Stored' : 'Missing',
      district.id === currentDistrictId ? (
        'Current'
      ) : (
        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={() => dispatch(Actions.setCurrentDistrict(district.id))}
        >
          Set as current
        </Button>
      ),
    ],
  }));

  const header = [
    {
      title: '#',
      sort: false,
    },
    {
      title: 'Title',
      sort: false,
    },
    {
      title: 'TL Token',
      sort: false,
    },
    {
      title: 'Current',
      sort: false,
    },
  ];

  return (
    <React.Fragment>
      <Card className={classes.root}>
        <Grid>
          <TextField
            name="username"
            label="email"
            value={values.username}
            error={!!errors.username}
            helperText={errors.username}
            onChange={handleChanges}
            disabled
            variant="outlined"
          />
          <Tooltip placement="left" title="Please enter the first name" arrow>
            <TextField
              name="firstName"
              label="First name"
              value={values.firstName}
              error={!!errors.firstName}
              helperText={errors.firstName}
              onChange={handleChanges}
              variant="outlined"
            />
          </Tooltip>
          <Tooltip placement="left" title="Please enter the last name" arrow>
            <TextField
              name="lastName"
              label="Last name"
              value={values.lastName}
              error={!!errors.lastName}
              helperText={errors.lastName}
              onChange={handleChanges}
              variant="outlined"
            />
          </Tooltip>
        </Grid>
        <Grid>
          <div className={classes.tableContainer}>
            <List title="Districts" header={header} data={data} />
          </div>
        </Grid>
        <Grid container direction="column" justify="center" alignItems="flex-end">
          <DoneButton
            id={'submit-button'}
            onClick={(event: React.ChangeEvent<any>) => {
              handleSubmit(event);
            }}
          />
        </Grid>
      </Card>
    </React.Fragment>
  );
};

export default Profile;
