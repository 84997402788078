import React, { FunctionComponent } from 'react';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { ButtonProps } from '@material-ui/core/Button';
import { RoundButton } from './RoundButton';

interface IProps {}

export const UploadButton: FunctionComponent<IProps & ButtonProps> = (props) => {
  return (
    <RoundButton {...props} color="primary">
      <CloudUploadIcon />
    </RoundButton>
  );
};
