import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AssistantList from './List';
import { IStore } from '../../types';
import * as Actions from '../../store/actions';

const Assistants = () => {
  const dispatch = useDispatch();
  const { items, params, totalCount } = useSelector((state: IStore) => state.assistants.list);

  const fetchAssistants = useCallback(() => {
    dispatch(Actions.fetchAssistants(params));
  }, [dispatch, params]);

  useEffect(() => {
    fetchAssistants();
  }, [fetchAssistants]);

  return (
    <React.Fragment>
      <AssistantList items={items.length ? items : []} params={params} totalCount={totalCount} />
    </React.Fragment>
  );
};

export default Assistants;
