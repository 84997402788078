import React, { useState, useRef } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import {
  Button,
  Checkbox,
  Grid,
  Select,
  Stepper,
  Step,
  StepLabel,
  Typography,
  MenuItem,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import SignatureCanvas from 'react-signature-canvas';
import * as UUID from 'uuid';
import moment from 'moment';
import { IStore } from '../../types';
import * as Actions from '../../store/actions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    input: {
      width: '300px',
      marginBottom: '15px',
    },
    canvasPad: {
      border: '2px solid',
      width: '320px',
      height: '320px',
    },
    center: {
      textAlign: 'center',
    },
  }),
);

function getSteps() {
  return ['Select tracker', 'Select route', 'Select students', 'Confirm by signature'];
}

interface ISummary {
  assistantId: string;
  routeId: string;
  students: string[];
  direction: string;
}

export default function CreateTrip(props: { onFinish: () => void }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const sigCanvas = useRef({}) as React.MutableRefObject<SignatureCanvas>;

  const { items: assistants } = useSelector((state: IStore) => state.assistants.list);
  const selectedRoute = useSelector((state: IStore) => state.routes.item);

  const [summary, setSummary] = useState<ISummary>({
    assistantId: '',
    routeId: '',
    students: [],
    direction: 'toSchool',
  });

  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set<number>());
  const steps = getSteps();

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
    if (summary.routeId) dispatch(Actions.fetchRouteById(summary.routeId));
  };

  const handleBack = () => {
    switch (activeStep) {
      case 1:
        setSummary({ ...summary, routeId: '' });
        break;
      case 2:
        setSummary({ ...summary, students: [] });
        break;
      default:
        break;
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const finish = () => {
    if (sigCanvas.current.isEmpty()) {
      return dispatch(Actions.showError(new Error('Please sign')));
    }
    const signature = sigCanvas.current.toDataURL();
    const trip = {
      id: UUID.v4(),
      startDate: moment().startOf('day').toDate(),
      finishDate: moment().startOf('day').toDate(),
      routeId: summary.routeId,
      assistantId: summary.assistantId,
      direction: summary.direction,
      deviceId: UUID.v4(),
      passengers: summary.students.map((id) => {
        const student = selectedRoute.students.find((s) => s.id === id);
        return {
          childId: id,
          checkedAt: moment().startOf('day').toDate(),
          firstName: student?.firstName as string,
          lastName: student?.lastName as string,
        };
      }),
      signature,
    };

    dispatch(Actions.createTripLogs(trip));
    props.onFinish();
  };

  const isChecked = (id: string) => summary.students.some((student) => student === id);

  const isDisabled = (step: number) => {
    switch (step) {
      case 0:
        return Boolean(summary.assistantId);
      case 1:
        return Boolean(summary.routeId);
      case 2:
        return Boolean(summary.students.length);
      default:
        return true;
    }
  };

  function getStepContent(step: number) {
    switch (step) {
      case 0:
        return (
          <Select
            name="assistandId"
            labelId="assistant-select"
            id="assistant-select"
            displayEmpty
            onChange={(event) => {
              setSummary({ ...summary, assistantId: event.target.value as string });
            }}
            value={summary.assistantId}
            className={classes.input}
          >
            <MenuItem value="" disabled>
              Please select a tracker
            </MenuItem>
            {assistants.map((assistant) => (
              <MenuItem key={assistant.id} value={assistant.id}>
                {`${assistant.firstName} ${assistant.lastName}`}
              </MenuItem>
            ))}
          </Select>
        );
      case 1:
        return (
          <div>
            <Select
              name="routeId"
              labelId="assistant-select"
              id="route-select"
              displayEmpty
              onChange={(event) => {
                setSummary({ ...summary, routeId: event.target.value as string });
              }}
              value={summary.routeId}
              className={classes.input}
            >
              <MenuItem value="" disabled>
                Please select a route
              </MenuItem>
              {assistants
                .find((a) => a.id === summary.assistantId)
                ?.routes.map((route) => (
                  <MenuItem key={route.id} value={route.id}>
                    {route.title}
                  </MenuItem>
                ))}
            </Select>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="direction"
                name="direction"
                value={summary.direction}
                onChange={(event) => {
                  setSummary({ ...summary, direction: event.target.value });
                }}
              >
                <FormControlLabel value="toSchool" control={<Radio />} label="From school" />
                <FormControlLabel value="fromSchool" control={<Radio />} label="To school" />
              </RadioGroup>
            </FormControl>
          </div>
        );
      case 2:
        return selectedRoute ? (
          <FormControl component="fieldset">
            <FormLabel component="legend">Please mark students</FormLabel>
            <FormGroup>
              {selectedRoute.students.map((student) => (
                <FormControlLabel
                  key={student.id}
                  control={
                    <Checkbox
                      checked={isChecked(student.id)}
                      onChange={(event) => {
                        const selected = event.target.checked
                          ? [...summary.students, student.id]
                          : summary.students.filter((id) => id !== student.id);
                        setSummary({ ...summary, students: selected });
                      }}
                      name={student.id}
                    />
                  }
                  label={`${student.firstName} ${student.lastName}`}
                />
              ))}
            </FormGroup>
          </FormControl>
        ) : null;
      case 3:
        return (
          <Grid>
            <Typography className={classes.center}>Please sign below</Typography>
            <div className={classes.canvasPad}>
              <SignatureCanvas
                penColor="black"
                ref={sigCanvas}
                canvasProps={{ width: 320, height: 320, className: 'sigCanvas' }}
              />
            </div>
          </Grid>
        );
      default:
        return 'Unknown step';
    }
  }

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: { optional?: React.ReactNode } = {};
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <Grid container justify="center" alignItems="center">
        <Grid item className={classes.input}>
          <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
        </Grid>
      </Grid>
      <Grid container alignItems="center" direction="column">
        <Grid item>
          <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={activeStep === steps.length - 1 ? finish : handleNext}
            className={classes.button}
            disabled={!isDisabled(activeStep)}
          >
            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
