export interface IConfig {
  apiBaseUrl: string;
  websocketBaseUrl: string;
  googleApiKey: string;
}

export const config: IConfig = {
  apiBaseUrl: `${process.env.REACT_APP_API_URI || ''}`,
  websocketBaseUrl: process.env.REACT_APP_WEBSOCKET_URI || '',
  googleApiKey: process.env.GOOGLE_API_KEY || '',
};
