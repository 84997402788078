import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent } from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

interface IProps extends Omit<DialogProps, 'open' | 'onClose'> {
  open?: boolean;
  onClose?: (event: React.MouseEvent<any>) => void;
  dialogContentStyles?: object;
  enableBackdropForClose?: boolean;
  width?: number;
}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    zIndex: 10,
  },
  dialogContent: {
    paddingLeft: '60px',
    paddingTop: '65px',
    paddingRight: '37px',
    paddingBottom: '33px',
  },
}));

const CommonDialog: FunctionComponent<IProps> = (props) => {
  const classes = useStyles();

  return (
    <Dialog
      open={false}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      disableBackdropClick={!props.enableBackdropForClose}
      PaperProps={{
        style: {
          maxWidth: `${props.width || 660}px`,
          width: '100%',
          borderRadius: '4px',
        },
      }}
      {...props}
    >
      {props.onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={props.onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
      <DialogContent className={classes.dialogContent} style={props.dialogContentStyles}>
        {props.children}
      </DialogContent>
    </Dialog>
  );
};

export default CommonDialog;
