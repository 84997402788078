/* eslint-disable @typescript-eslint/no-explicit-any */
import { Schema } from 'yup';
import React, { useState } from 'react';
import get from 'lodash/get';
import set from 'lodash/set';
import isObject from 'lodash/isObject';
import cloneDeepWith from 'lodash/cloneDeepWith';
import { makeValidate } from '../validation';

export interface IFormState<T> {
  isValid: boolean;
  values: T;
  touched: { [K in keyof T]: boolean };
  errors: { [K in keyof T]: any };
}

export const useForm = <T>(
  initialData: T,
  validationSchema: Schema<T>,
  onSubmit: (state: IFormState<T>) => void,
): [
  IFormState<T>,
  (path: string, value: any, dontValidate?: boolean) => void,
  (event: React.ChangeEvent<any>) => void,
  (event: React.ChangeEvent<any>) => void,
  () => void,
] => {
  const validate = makeValidate<T>(validationSchema);

  const initErrors = cloneDeepWith(initialData, (v) => (isObject(v) ? undefined : false));
  // noinspection UnnecessaryLocalVariableJS
  const initTouched = initErrors;

  const initialState = {
    isValid: false,
    values: initialData,
    touched: initTouched,
    errors: initErrors,
  };

  const [formState, setFormState] = useState<IFormState<T>>(initialState);

  // Hack to force update form data
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  if (initialState?.values?.id && initialState.values.id !== formState?.values?.id) {
    setFormState(initialState);
  }

  const reset = () => {
    setFormState(initialState);
  };

  const handleChange = (event: React.ChangeEvent<any>) => {
    event.persist();
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    setValue(event.target.name, event.target.type === 'checkbox' ? event.target.checked : event.target.value);
  };

  const setValue = (name: string, value: any, dontValidate = false) => {
    setFormState((state: IFormState<T>) => {
      const updatedState = {
        ...state,
        values: {
          ...state.values,
          ...set({}, name, value),
        },
        touched: {
          ...state.touched,
          ...set({}, name, true),
        },
      };
      if (dontValidate) {
        return updatedState;
      }
      const errors = validate(updatedState.values);
      return {
        ...updatedState,
        isValid: !errors,
        errors: {
          ...updatedState.errors,
          ...set({}, name, get(errors, name)),
        },
      };
    });
  };

  const handleSubmit = (event: React.ChangeEvent<any>) => {
    event.preventDefault();
    const errors = validate(formState.values);
    const updatedState = {
      ...formState,
      isValid: !errors,
      errors: {
        ...initErrors,
        ...errors,
      },
    };
    setFormState(updatedState);
    if (updatedState.isValid) {
      // reset();
      onSubmit(updatedState);
    }
  };

  return [formState, setValue, handleChange, handleSubmit, reset];
};
