/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FunctionComponent, useState, useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import useReactRouter from 'use-react-router';
import * as UUID from 'uuid';
import clsx from 'clsx';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import { Table, TableHead, TableRow, TableCell, TableFooter } from '@material-ui/core';
import get from 'lodash/get';
import listStyles from '../assets/jss/components/listStyle';
import iconUp from '../assets/images/icons/sort-up.svg';
import iconDown from '../assets/images/icons/sort-down.svg';
import { buildId } from '../helpers/utils';

import ListPagination from './ListPagination';
import { IListParams } from '../types';

const styles = createStyles({
  ...listStyles,
  tableCell: {
    fontFamily: 'Muli',
    fontSize: '13px',
    lineHeight: '24px',
    paddingTop: '8px',
    paddingBottom: '8px',
    '&:first-child': {
      paddingLeft: '66px',
    },
    '&:last-child': {
      paddingRight: '45px',
    },
  },
  tableRowHover: {
    transition: 'all 0.3s',
    '&:hover': {
      background: 'rgba(3, 155, 229, 0.22)',
    },
  },
  tableWrapper: {
    background: 'white',
    borderRadius: '8px',
    // overflow: 'hidden',
    border: '1px solid #e0e0e0',
    paddingTop: '81px',
    paddingBottom: '60px',
    position: 'relative',
  },
  tableWrapperNoBorder: {
    border: 'none',
    borderRadius: 0,
  },
  tableWrapperFullWidth: {
    width: '100%',
  },
  tableRowHead: {
    background: 'none',
  },
  tableCellHead: {
    fontWeight: 'normal',
    fontSize: '12px',
    opacity: 0.54,
  },
  tableHeader: {
    position: 'absolute',
    opacity: 0.87,
    fontFamily: 'Muli',
    fontSize: '20px',
    lineHeight: 1.2,
    color: '#000000',
    top: '35px',
    left: '66px',
    marginTop: '20px',
  },
  tableButtons: {
    position: 'absolute',
    left: '50px',
    top: '-25px',
  },
  searchIcon: {
    width: '20px',
    marginRight: '10px',
    marginTop: '25px',
  },
  searchInputWrap: {
    maxWidth: '200px',
  },
  searchWrap: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    top: '-10px',
    right: '30px',
  },
  tableRow: {
    verticalAlign: 'top',
  },
  sortIcon: {
    width: '8px',
    height: '6px',
  },
  sortIcons: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // justifyContent: 'center',
    marginLeft: '5px',
  },
  headerCellBlock: {
    display: 'flex',
    alignItems: 'center',
  },
  headerCellBlockPointer: {
    cursor: 'pointer',
  },
});

interface ICell {
  title: string;
  width?: string;
  sort?: boolean;
}

export interface IDataItem {
  id?: string;
  link?: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  values: React.ReactNode[];
}

interface IProps extends WithStyles<typeof styles> {
  title?: string | React.ReactNode;
  header: ICell[];
  data?: IDataItem[];
  body?: React.ReactNode;
  buttons?: React.ReactNode[];
  component?: React.FunctionComponent;
  withHover?: boolean;
  noBorder?: boolean;
  fullWidth?: boolean;
  style?: {};
  updateDataAction?: (params: IListParams) => {};
  totalCount?: number;
  showPagination?: boolean;
  paginationColSpan?: number;
  paginationParams?: IListParams;
}

const buildListHeaderId = (props: IProps, i: number) => {
  const header = props.header[i] && props.header[i].title ? props.header[i].title.replace(/ +/, '-').toLowerCase() : i;
  return buildId(props, `list-header-${header}`);
};
const buildListCellId = (props: IProps, i: number, ii: number) => {
  const header =
    props.header[ii] && props.header[ii].title ? props.header[ii].title.replace(/ +/, '-').toLowerCase() : ii;
  return buildId(props, `list-${i}-field-${header}`);
};

const List: FunctionComponent<IProps & RouteComponentProps> = (props) => {
  const { classes } = props;
  const { history } = useReactRouter();
  const [tableData, setTableData] = useState(props.data);

  useEffect(() => {
    setTableData(props.data);
  }, [props]);

  const handleRowClick = (link?: string) => {
    if (link) {
      history.push(link);
    }
  };

  const [sortingState, setSortingState] = useState<boolean[]>([]);
  useEffect(() => {
    const arr = Array(props.header.length).fill(true);
    setSortingState(arr);
  }, [props.header]);

  const sortColumn = (columnIndex: number) => {
    const parseValue = (value: any) => {
      const result = value;
      if (get(result, 'props.children.0.props.value')) {
        return get(result, 'props.children.0.props.value');
      }
      if (get(result, 'props.value')) {
        return get(result, 'props.value');
      }
      if (get(result, 'props.wallet.name')) {
        return get(result, 'props.wallet.name', '');
      }
      if (get(result, 'type') === 'span' && get(result, 'props.children')) {
        const children = get(result, 'props.children', []);
        return Array.isArray(children) ? children.join('') : children;
      }
      return result;
    };
    if (tableData) {
      const sortedData = [...tableData];
      sortedData?.sort((a: any, b: any) => {
        if (sortingState[columnIndex]) {
          if (parseValue(a.values[columnIndex]) > parseValue(b.values[columnIndex])) return 1;
          if (parseValue(b.values[columnIndex]) > parseValue(a.values[columnIndex])) return -1;
        } else {
          if (parseValue(a.values[columnIndex]) < parseValue(b.values[columnIndex])) return 1;
          if (parseValue(b.values[columnIndex]) < parseValue(a.values[columnIndex])) return -1;
        }
        return 0;
      });
      setSortingState((state) => state.map((value, key) => (key === columnIndex ? !value : false)));
      setTableData(sortedData);
    }
  };

  return (
    <React.Fragment>
      <div
        className={clsx(classes.tableWrapper, {
          [classes.tableWrapperNoBorder]: props.noBorder,
          [classes.tableWrapperFullWidth]: props.fullWidth,
        })}
        style={props.style || {}}
      >
        {props.title && (
          <div key="0" className={classes.tableHeader} id={buildId(props, 'list-title')}>
            {props.title}
          </div>
        )}
        <div key="1" className={classes.tableButtons}>
          {props.buttons?.map((button, index) => (
            <span key={index.toString()}>{button}</span>
          ))}
        </div>
        <Table key="2" className={classes.table} aria-labelledby="tableTitle" size="medium" aria-label="enhanced table">
          <TableHead>
            <TableRow className={classes.tableRowHead}>
              {props.header.map((cell: ICell, i) => (
                <TableCell
                  key={i}
                  component="td"
                  scope="row"
                  className={[classes.tableCell, classes.tableCellHead].join(' ')}
                >
                  <div
                    id={buildListHeaderId(props, i)}
                    className={clsx(classes.headerCellBlock, {
                      [classes.headerCellBlockPointer]: cell.sort,
                    })}
                  >
                    {cell.sort ? (
                      <div
                        style={{ display: 'flex', cursor: 'pointer', alignItems: 'center' }}
                        onClick={() => sortColumn(i)}
                      >
                        {cell.title}
                        <div className={classes.sortIcons}>
                          <img src={iconUp} className={classes.sortIcon} alt={'sort up'} />
                          <img src={iconDown} className={classes.sortIcon} alt={'sort down'} />
                        </div>
                      </div>
                    ) : (
                      cell.title
                    )}
                  </div>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <tbody>
            {props.body ? props.body : null}
            {!props.body &&
              // eslint-disable-next-line react/display-name
              tableData?.map((row, i) => (
                <TableRow
                  key={(row.id as string) || UUID.v4()}
                  className={clsx(classes.tableRow, {
                    [classes.tableRowHover]: props.withHover,
                  })}
                  onClick={row.onClick ? row.onClick : () => handleRowClick(row.link)}
                  style={row.onClick || row.link ? { cursor: 'pointer' } : undefined}
                >
                  {row.values.map((value, ii) => (
                    <TableCell
                      key={ii}
                      component="td"
                      scope="row"
                      className={classes.tableCell}
                      id={buildListCellId(props, i, ii)}
                    >
                      {value}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
          </tbody>
          <TableFooter>
            <TableRow>
              {props.showPagination && (
                <ListPagination
                  totalCount={props.totalCount}
                  updateDataAction={props.updateDataAction}
                  colSpan={props.paginationColSpan}
                  params={props.paginationParams}
                />
              )}
            </TableRow>
          </TableFooter>
        </Table>
      </div>
    </React.Fragment>
  );
};

export default withRouter(withStyles(styles)(List));
