import React from 'react';
import { FormHelperTextTypeMap, Grid, TextField, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { OverrideProps } from '@material-ui/core/OverridableComponent';
import { useForm, IFormState } from '../../helpers/form';
import { IAddSchool, schema, schemaUpdate } from '../../validation/addSchool';
import { DoneButton } from '../UI/buttons/DoneButton';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    '& .MuiFormControl-root': {
      margin: theme.spacing(2),
      width: 400,
    },
  },
  container: {
    minWidth: 400,
    padding: theme.spacing(2),
  },
  modalHeader: {
    fontFamily: 'Muli',
    fontSize: '35px',
    lineHeight: 0.51,
    color: '#2d323e',
    marginBottom: '40px',
  },
  textField: {
    marginTop: 0,
    fontFamily: 'Muli',
    marginBottom: '26px',
  },
}));

const buildId = (suffix: string) => {
  return `create-school-form-${suffix}`;
};

interface IProps {
  onSubmit: (form: IFormState<IAddSchool>) => void;
  initialData: IAddSchool;
  schema: typeof schema | typeof schemaUpdate;
  name: string;
}

const textInput: Partial<OverrideProps<FormHelperTextTypeMap<{}, 'p'>, 'p'>> = {
  style: {
    paddingLeft: 0,
    marginLeft: 0,
    fontFamily: 'Muli',
    fontSize: '10px',
    color: '#d75a4a',
    fontWeight: 'bold',
  },
};

const SchoolForm = (props: IProps) => {
  const [formState, , handleChanges, handleSubmit] = useForm<IAddSchool>(
    props.initialData,
    props.schema,
    props.onSubmit,
  );
  const { values, errors } = formState;

  const classes = useStyles();

  return (
    <Grid container direction="column" justify="center" alignItems="flex-end">
      <Grid container direction="column" justify="center" alignItems="flex-start" className={classes.container}>
        <div className={classes.modalHeader} id={buildId('title')}>
          {values.externalId ? "You can't edit the imported data" : props.name}
        </div>
        <Grid item xs={11}>
          <Tooltip placement="left" title="Please enter the name of the school" arrow>
            <TextField
              name="name"
              label="Name"
              value={values.name}
              error={!!errors.name}
              helperText={errors.name}
              onChange={handleChanges}
              required
              FormHelperTextProps={textInput}
              className={classes.textField}
              variant="outlined"
              disabled={Boolean(values.externalId)}
            />
          </Tooltip>
          <Tooltip placement="left" title="Please enter the address of the school" arrow>
            <TextField
              name="address"
              label="Address"
              value={values.address}
              error={!!errors.address}
              onChange={handleChanges}
              helperText={errors.address}
              FormHelperTextProps={textInput}
              className={classes.textField}
              variant="outlined"
              disabled={Boolean(values.externalId)}
            />
          </Tooltip>
          <Tooltip placement="left" title="Please specify contact person" arrow>
            <TextField
              name="contactInfo"
              label="Contact Info"
              value={values.contactInfo}
              error={!!errors.contactInfo}
              onChange={handleChanges}
              helperText={errors.contactInfo}
              FormHelperTextProps={textInput}
              className={classes.textField}
              variant="outlined"
              disabled={Boolean(values.externalId)}
            />
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container direction="column" justify="center" alignItems="flex-end">
        <DoneButton
          id={buildId('submit-button')}
          onClick={(event: React.ChangeEvent<any>) => {
            handleSubmit(event);
          }}
          disabled={Boolean(values.externalId)}
        />
      </Grid>
    </Grid>
  );
};

export default SchoolForm;
