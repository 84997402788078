import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Grid, Card, CardContent, Typography } from '@material-ui/core';
import * as Actions from '../store/actions';
import { IFormState } from '../helpers/form';
import { IStore, IForgotPasswordForm } from '../types';
import ForgotPasswordForm from './ForgotPasswordForm';

const useStyles = makeStyles(() =>
  createStyles({
    grid: {
      background: '#22262f',
    },
    card: {
      width: '515px',
      borderRadius: '4px',
      'text-align': 'center',
    },
  }),
);

function ForgotPassword() {
  const dispatch = useDispatch();
  const isSend = useSelector((state: IStore) => state.auth?.send);

  const onSubmit = (form: IFormState<IForgotPasswordForm>) => {
    dispatch(Actions.restoreAccess(form.values.username));
  };

  const classes = useStyles();

  return (
    <Grid container direction="column" justify="center" alignItems="center" className={classes.grid}>
      <Card className={classes.card}>
        {isSend ? (
          <CardContent>
            <Typography>
              Instructions for further action will come in a few minutes to the typed email. Please, check the email
            </Typography>
          </CardContent>
        ) : (
          <CardContent>
            <ForgotPasswordForm onSubmit={onSubmit} />
            <input type="hidden" id="app-version" value={`${process.env.REACT_APP_VERSION}`} />
          </CardContent>
        )}
      </Card>
    </Grid>
  );
}

export default ForgotPassword;
