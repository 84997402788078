import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ChildList from './List';
import { IStore } from '../../types';
import * as Actions from '../../store/actions';

const Route = () => {
  const dispatch = useDispatch();
  const { items, params, totalCount } = useSelector((state: IStore) => state.students.list);
  const { items: routes, params: routesParams } = useSelector((state: IStore) => state.routes.list);

  const fetchRoutes = useCallback(() => {
    dispatch(Actions.fetchRoutes(routesParams));
  }, [dispatch, routesParams]);

  const fetchStudents = useCallback(() => {
    dispatch(Actions.fetchStudents(params));
  }, [dispatch, params]);

  useEffect(() => {
    fetchRoutes();
  }, [fetchRoutes]);

  useEffect(() => {
    fetchStudents();
  }, [fetchStudents]);

  return (
    <React.Fragment>
      <ChildList
        items={items.length ? items : []}
        params={params}
        totalCount={totalCount}
        routes={routes.length ? routes : []}
      />
    </React.Fragment>
  );
};

export default Route;
