import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from '../../store/actions';
import { IFormState } from '../../helpers/form';
import { IAddRoute, schema, initialData } from '../../validation/addRoute';
import { IRoute, IListParams, IStore } from '../../types';
import List, { IDataItem } from '../List';
import RouteForm from './Form';
import { PlusButton } from '../UI/buttons/PlusButton';
import { buildId, mapDirection } from '../../helpers/utils';
import { UploadButton } from '../UI/buttons/UploadButton';
import CommonDialog from '../UI/dialog';
import { UploadRoutesForm } from './UploadForm';
import RouteDetail from './Details';

interface IProps {
  items: IRoute[];
  params: IListParams;
  totalCount: number;
}

const RouteList = (props: IProps) => {
  const dispatch = useDispatch();
  const schools = useSelector((state: IStore) => state.schools.list.items);

  const setHeader = useCallback(() => {
    dispatch(Actions.setSubheader('Routes'));
  }, [dispatch]);

  useEffect(() => {
    setHeader();
  }, [setHeader]);

  const [isCreateFormOpen, setCreateFormOpen] = useState(false);
  const onCreateFormSubmit = (form: IFormState<IAddRoute>) => {
    dispatch(Actions.addRoute({ ...form.values }));
    setCreateFormOpen(false);
  };
  const handleCreateFormClose = () => {
    setCreateFormOpen(false);
  };

  const [isUploadFormOpen, setUploadFormOpen] = useState(false);
  const handleUploadFormClose = () => {
    setUploadFormOpen(false);
  };

  const [activeRouteId, setActiveRoute] = useState<string>('null');
  const [isUpdateOpen, setUpdateOpen] = useState(false);
  const onUpdateSubmit = (form: IFormState<IAddRoute>) => {
    dispatch(Actions.updateRouteById(activeRouteId, { ...form.values }));
  };
  const handleUpdateClose = () => {
    setUpdateOpen(false);
  };

  const header = [
    { title: 'Title', sort: true },
    { title: 'School name', sort: true },
    { title: 'Direction', sort: true },
  ];

  const data: IDataItem[] = props.items.map((item) => {
    return {
      id: item.id,
      onClick: (event: React.SyntheticEvent) => {
        event.preventDefault();
        setActiveRoute(item.id);
        setUpdateOpen(true);
      },
      values: [item.title, item.facility?.name, mapDirection(item.direction)],
    };
  });

  return (
    <React.Fragment>
      <CommonDialog onClose={handleCreateFormClose} aria-labelledby="simple-dialog-title" open={isCreateFormOpen}>
        <RouteForm
          onSubmit={onCreateFormSubmit}
          initialData={initialData}
          schema={schema}
          name="Create route"
          schools={schools}
        />
      </CommonDialog>
      <CommonDialog onClose={handleUploadFormClose} aria-labelledby="simple-dialog-title" open={isUploadFormOpen}>
        <UploadRoutesForm onSubmit={() => setUploadFormOpen(false)} />
      </CommonDialog>
      <CommonDialog onClose={handleUpdateClose} aria-labelledby="assistant-update-dialog" open={isUpdateOpen}>
        {activeRouteId && <RouteDetail routeId={activeRouteId} onSubmit={onUpdateSubmit} />}
      </CommonDialog>
      <List
        header={header}
        data={data}
        buttons={[
          <PlusButton
            id={buildId(props, 'add-route-button')}
            key={0}
            onClick={() => setCreateFormOpen(true)}
            style={{ marginRight: '10px' }}
          />,
          <UploadButton
            id={buildId(props, 'upload-routes-button')}
            key={0}
            onClick={() => setUploadFormOpen(true)}
            style={{ marginRight: '10px' }}
          />,
        ]}
      />
    </React.Fragment>
  );
};

export default RouteList;
