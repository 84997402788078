import { INavigation } from '../../types';

export const GET_NAVIGATION = '[NAVIGATION] GET NAVIGATION';
export const SET_NAVIGATION = '[NAVIGATION] SET NAVIGATION';

interface IGetNavigation {
  type: typeof GET_NAVIGATION;
}

interface ISetNavigation {
  type: typeof SET_NAVIGATION;
  navigation: INavigation;
}

export type NavigationActionTypes = IGetNavigation | ISetNavigation;

export function getNavigation(): NavigationActionTypes {
  return {
    type: GET_NAVIGATION,
  };
}

export function setNavigation(navigation: INavigation): NavigationActionTypes {
  return {
    type: SET_NAVIGATION,
    navigation,
  };
}
