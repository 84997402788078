import * as React from 'react';
import { useSelector } from 'react-redux';
import { Route } from 'react-router';
import { Grid, Card, CardContent } from '@material-ui/core';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import { IStore } from '../types';
import RequestAuth from './RequestAuth';

const styles = createStyles({
  grid: {
    background: '#22262f',
  },
  card: {
    width: '515px',
    borderRadius: '4px',
    'text-align': 'center',
  },
  cardHeader: {
    background: '#ddd',
  },
});

export interface IProps extends WithStyles<typeof styles> {}

function PublicLayout(props: IProps) {
  const { classes } = props;
  const progress = useSelector((state: IStore) => state.progress);

  return (
    <Grid container direction="column" justify="center" alignItems="center" className={classes.grid}>
      {!progress.show ? (
        <Card className={classes.card}>
          <CardContent>
            <Route exact path="/login" component={RequestAuth} />
          </CardContent>
        </Card>
      ) : (
        <div>
          <Route exact path="/login" component={RequestAuth} />
        </div>
      )}
    </Grid>
  );
}

export default withStyles(styles)(PublicLayout);
