import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IFormState } from '../../helpers/form';
import { IStore } from '../../types';
import * as Actions from '../../store/actions';
import { IAddRoute, schemaUpdate } from '../../validation/addRoute';
import RouteForm from './Form';

interface IProps {
  routeId: string;
  onSubmit: (form: IFormState<IAddRoute>) => void;
}

const RouteDetail = (props: IProps) => {
  const dispatch = useDispatch();
  const route = useSelector((state: IStore) => state.routes.item);
  const { items: schools, params } = useSelector((state: IStore) => state.schools.list);

  const fetchSchools = useCallback(() => {
    dispatch(Actions.fetchSchools(params));
  }, [dispatch, params]);

  const fetchRoute = useCallback(() => {
    dispatch(Actions.fetchRouteById(props.routeId));
  }, [dispatch, props.routeId]);

  useEffect(() => {
    fetchSchools();
  }, [fetchSchools]);

  useEffect(() => {
    fetchRoute();
  }, [fetchRoute]);

  return (
    <React.Fragment>
      {route && route.id === props.routeId && (
        <RouteForm
          onSubmit={props.onSubmit}
          initialData={route}
          schools={schools}
          schema={schemaUpdate}
          name="Update route"
        />
      )}
    </React.Fragment>
  );
};

export default RouteDetail;
