import React, { Component /*, MouseEvent*/ } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';

class Main extends Component<RouteComponentProps> {
  // public openDialog = (/*event: MouseEvent*/) => {
  //   this.setState({ learnMoredialog: true });
  // };
  //
  // public dialogClose = (/*event: MouseEvent*/) => {
  //   this.setState({ learnMoredialog: false });
  // };

  public render() {
    return (
      <React.Fragment>
        <CssBaseline />
        <div className="root">
          <Grid container justify="center">
            DTTA Admin App
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(Main);
