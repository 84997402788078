import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../types';
import CustomizedSnackbar from './CustomizedSnackbar';
import * as Actions from '../store/actions';

const prepareMessage = (message: unknown) => {
  if (Array.isArray(message)) {
    return message.join(', ')
  }

  return message as string;
}

function Error() {
  const message = useSelector((state: IStore) => state.error.message);
  const dispatch = useDispatch();
  return (
    <CustomizedSnackbar
      variant="error"
      open={!!message}
      message={prepareMessage(message)}
      onClose={() => dispatch(Actions.hideError())}
    />
  );
}

export default Error;
