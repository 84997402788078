import * as Actions from '../actions';

const initialState = { show: false };

const progress = (state = initialState, action: Actions.LoadingActionTypes) => {
  switch (action.type) {
    case Actions.SHOW_PROGRESS: {
      return {
        show: true,
        message: action.message,
      };
    }
    case Actions.HIDE_PROGRESS: {
      return {
        show: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default progress;
