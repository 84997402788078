import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { AssistantForm } from './Form';
import List, { IDataItem } from '../List';
import { buildId } from '../../helpers/utils';
import * as Actions from '../../store/actions';
import { IFormState } from '../../helpers/form';
import { PlusButton } from '../UI/buttons/PlusButton';
import { IAssistant, IListParams } from '../../types';
import { IAddAssistant, initialData, schema } from '../../validation/addAssistant';
import CommonDialog from '../UI/dialog';
import AssistantDetail from './Details';

interface IProps {
  items: IAssistant[];
  params: IListParams;
  totalCount: number;
}

const AssistantList = (props: IProps) => {
  const dispatch = useDispatch();

  const setHeader = useCallback(() => {
    dispatch(Actions.setSubheader('Trackers'));
  }, [dispatch]);

  useEffect(() => {
    setHeader();
  }, [setHeader]);

  const [isCreateOpen, setCreateOpen] = useState(false);
  const onCreateSubmit = (form: IFormState<IAddAssistant>) => {
    dispatch(
      Actions.addAssistant({
        ...form.values,
        routes: form.values.routes.filter((route) => !!route), // todo change select logic
        password: form.values.rawPassword,
      }),
    );
    setCreateOpen(false);
  };
  const handleCreateClose = () => {
    setCreateOpen(false);
  };

  const [activeAssistantId, setActiveAssistant] = useState<string>('null');
  const [isUpdateOpen, setUpdateOpen] = useState(false);
  const onUpdateSubmit = (form: IFormState<IAddAssistant>) => {
    dispatch(
      Actions.updateAssistantById(activeAssistantId, {
        ...form.values,
        routes: form.values.routes.filter((route) => !!route), // todo change select logic
        password: form.values.rawPassword,
      }),
    );
  };
  const handleUpdateClose = () => {
    setUpdateOpen(false);
  };

  const header = [
    { title: 'First name', sort: true },
    { title: 'Last name', sort: true },
  ];

  const data: IDataItem[] = props.items.map((item) => {
    return {
      id: item.id,
      onClick: (event: React.SyntheticEvent) => {
        event.preventDefault();
        setActiveAssistant(item.id);
        setUpdateOpen(true);
      },
      values: [item.firstName, item.lastName],
    };
  });

  return (
    <React.Fragment>
      <CommonDialog onClose={handleCreateClose} aria-labelledby="create-tracker" open={isCreateOpen} width={780}>
        <AssistantForm onSubmit={onCreateSubmit} initialData={initialData} schema={schema} name="Create tracker" />
      </CommonDialog>
      <CommonDialog onClose={handleUpdateClose} aria-labelledby="tracker-update-dialog" open={isUpdateOpen} width={780}>
        {activeAssistantId && (
          <AssistantDetail assistantId={activeAssistantId} onSubmit={onUpdateSubmit} onClose={handleUpdateClose} />
        )}
      </CommonDialog>
      <List
        header={header}
        data={data}
        buttons={[<PlusButton id={buildId(props, 'add-tracker-button')} key={0} onClick={() => setCreateOpen(true)} />]}
      />
    </React.Fragment>
  );
};

export default AssistantList;
