import React, { FunctionComponent } from 'react';
import RefreshIcon from '@material-ui/icons/Refresh';
import { ButtonProps } from '@material-ui/core/Button';
import { RoundButton } from './RoundButton';

interface IProps {
  onClick: () => void;
}

export const ReloadButton: FunctionComponent<IProps & ButtonProps> = (props) => {
  return (
    <RoundButton {...props} color="primary" onClick={props.onClick}>
      <RefreshIcon />
    </RoundButton>
  );
};
