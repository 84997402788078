import * as Actions from '../actions';
import { ITrip, IListParams } from '../../types';

interface IInitialState {
  item: ITrip | {};
  list: {
    items: ITrip[];
    params: IListParams;
    totalCount: number;
  };
  currentSignature: string;
}

const initialState: IInitialState = {
  item: {},
  list: {
    items: [],
    params: {
      limit: 20,
      offset: 0,
    },
    totalCount: 0,
  },
  currentSignature: '',
};

const trips = (state = initialState, action: Actions.TripActionTypes | Actions.ErrorActionTypes) => {
  switch (action.type) {
    case Actions.FETCH_TRIPS_SUCCESS: {
      const { items, totalCount, params } = action.payload;
      const paramsChanged = state.list.params.limit !== params.limit || state.list.params.offset !== params.offset;
      return {
        ...state,
        list: {
          ...state.list,
          items,
          ...(paramsChanged && { params }),
          totalCount,
        },
      };
    }
    case Actions.FETCH_TRIP_SIGNATURE_SUCCESS: {
      return {
        ...state,
        currentSignature: action.payload,
      };
    }
    case '@@router/LOCATION_CHANGE':
    default: {
      return state;
    }
  }
};

export default trips;
