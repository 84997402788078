import DoneIcon from '@material-ui/icons/Done';
import React, { FunctionComponent } from 'react';
import { ButtonProps } from '@material-ui/core/Button';
import { RoundButton } from './RoundButton';

interface IProps {}

export const DoneButton: FunctionComponent<IProps & ButtonProps> = (props) => {
  return (
    <RoundButton {...props} color="primary">
      <DoneIcon />
    </RoundButton>
  );
};
