import * as Actions from '../actions';
import { IUser, IListParams } from '../../types';

interface IInitialState {
  send: boolean;
  list: {
    items: IUser[];
    params: IListParams;
    totalCount: number;
  };
}

const initialState: IInitialState = {
  send: false,
  list: {
    items: [],
    params: {
      limit: 100,
      offset: 0,
    },
    totalCount: 0,
  },
};

const users = (state = initialState, action: Actions.UserActionTypes | Actions.ErrorActionTypes) => {
  switch (action.type) {
    case Actions.CHECK_USER_USE_SUCCESS: {
      return {
        ...state,
        send: true,
      };
    }
    case Actions.CREATE_USER_SUCCESS: {
      const newUser = action.payload;
      return {
        ...state,
        send: true,
        list: { ...state.list, items: [...state.list.items, newUser] },
      };
    }
    case Actions.FETCH_USERS_SUCCESS: {
      const { items, totalCount } = action.payload;
      return {
        ...state,
        list: { ...state.list, items, totalCount },
      };
    }
    case Actions.ACTIVATE_USER_SUCCESS: {
      const id = action.payload;
      return {
        ...state,
        list: {
          ...state.list,
          items: state.list.items.filter((user) => user.id !== id),
          totalCount: state.list.totalCount - 1,
        },
      };
    }
    case '@@router/LOCATION_CHANGE':
    default: {
      return state;
    }
  }
};

export default users;
