import * as Actions from '../actions';
import { ISchool, IListParams } from '../../types';

interface IInitialState {
  item: ISchool | null;
  list: {
    items: ISchool[];
    params: IListParams;
    totalCount: number;
  };
}

const initialState: IInitialState = {
  item: null,
  list: {
    items: [],
    params: {
      limit: 100,
      offset: 0,
    },
    totalCount: 0,
  },
};

const schools = (state = initialState, action: Actions.SchoolActionTypes | Actions.ErrorActionTypes) => {
  switch (action.type) {
    case Actions.FETCH_SCHOOLS_SUCCESS: {
      const { items, totalCount } = action.payload;
      return {
        ...state,
        list: { ...state.list, items, totalCount },
      };
    }
    case Actions.CREATE_SCHOOL_SUCCESS: {
      const newSchool = action.payload;
      return {
        ...state,
        list: { ...state.list, items: [...state.list.items, newSchool] },
      };
    }
    case Actions.FETCH_SCHOOL_SUCCESS:
    case Actions.UPDATE_SCHOOL_SUCCESS: {
      const school = action.payload;
      return {
        ...state,
        item: school,
        list: {
          ...state.list,
          items: state.list.items.map((i) => (i.id === school.id ? school : i)),
        },
      };
    }
    case '@@router/LOCATION_CHANGE':
    default: {
      return state;
    }
  }
};

export default schools;
