import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RouteList from './List';
import { IStore } from '../../types';
import * as Actions from '../../store/actions';

const Route = () => {
  const dispatch = useDispatch();
  const { items, params, totalCount } = useSelector((state: IStore) => state.routes.list);

  const fetchRoutes = useCallback(() => {
    dispatch(Actions.fetchRoutes(params));
  }, [dispatch, params]);

  useEffect(() => {
    fetchRoutes();
  }, [fetchRoutes]);

  return (
    <React.Fragment>
      <RouteList items={items.length ? items : []} params={params} totalCount={totalCount} />
    </React.Fragment>
  );
};

export default Route;
