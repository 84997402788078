import 'react-dates/initialize';
import moment, { Moment } from 'moment';
import { Grid, Typography, ListItemText, Tooltip, Button } from '@material-ui/core';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker, isInclusivelyBeforeDay } from 'react-dates';
import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import Date from '../UI/Date';
import CommonDialog from '../UI/dialog';
import List, { IDataItem } from '../List';
import * as Actions from '../../store/actions';
import { TripSignature } from './TripSignature';
import { IListParams, IStore, IMarker, ITrip, IPoint } from '../../types';
import { mapDirection } from '../../helpers/utils';
import { ExportButton } from '../UI/buttons/ExportButton';
import { InfoButton } from '../UI/buttons/InfoButton';
import { PlusButton } from '../UI/buttons/PlusButton';
import Map from '../map';
import CreateTrip from './CreateTrip';

const useStyles = makeStyles((theme) => ({
  dashboard: {
    marginBottom: theme.spacing(1),
    '& .MuiGrid-item': {
      marginRight: theme.spacing(2),
    },
    signatureImage: {
      width: '420px',
      height: 'auto',
    },
  },
  headerButtons: {
    marginRight: theme.spacing(2),
  },
}));

interface IRange {
  startDate: Moment | null;
  endDate: Moment | null;
}

const TripList = () => {
  const dispatch = useDispatch();
  const { items, params, totalCount } = useSelector((state: IStore) => state.trips.list);
  const { params: paramsAssistants } = useSelector((state: IStore) => state.assistants.list);
  const [geoData, setGeoData] = useState<{ markers: IMarker[]; path: IPoint[] }>();

  const [{ startDate, endDate }, setRange] = useState<IRange>({
    startDate: null,
    endDate: null,
  });
  const [focused, setFocused] = useState<null | 'startDate' | 'endDate'>(null);

  const setHeader = useCallback(() => {
    dispatch(Actions.setSubheader('Trip Logs'));
  }, [dispatch]);

  const fetchTrips = useCallback(() => {
    dispatch(
      Actions.fetchTrips({
        ...params,
        checkedAtFrom: startDate?.startOf('day').format(),
        checkedAtTo: endDate?.endOf('day').format(),
      }),
    );
  }, [dispatch, params, startDate, endDate]);

  const fetchAssistants = useCallback(() => {
    dispatch(Actions.fetchAssistants(paramsAssistants));
  }, [dispatch, paramsAssistants]);

  useEffect(() => {
    setHeader();
  }, [setHeader]);

  useEffect(() => {
    fetchTrips();
  }, [fetchTrips]);

  useEffect(() => {
    fetchAssistants();
  }, [fetchAssistants]);

  const classes = useStyles();
  const header = [
    { title: 'Student name' },
    { title: 'Checked at' },
    { title: 'Route' },
    { title: 'Direction' },
    { title: 'Tracker name' },
    { title: 'Signature' },
    { title: '' },
  ];

  const changeRange = (data: IRange) => {
    setRange(data);
  };

  const exportTrips = () => {
    dispatch(
      Actions.exportTrips({
        offset: 0,
        limit: 0,
        checkedAtFrom: startDate?.startOf('day').format(),
        checkedAtTo: endDate?.endOf('day').format(),
      }),
    );
  };

  const [isOpen, setOpen] = useState(false);
  const [tripId, setTripId] = useState<string>('null');
  const handleClose = () => {
    setOpen(false);
  };

  const [isInfoOpen, setInfoOpen] = useState(false);
  const handleInfoClose = () => {
    setInfoOpen(false);
  };

  const [isCreateTripOpen, setCreateTripOpen] = useState(false);
  const handleCreateTripClose = () => {
    setCreateTripOpen(false);
  };

  const [isMapOpen, setMapOpen] = useState(false);
  const handleMapClose = () => {
    setMapOpen(false);
  };

  const handleTripGeoData = (trip: ITrip) => {
    const markers = [
      {
        lat: trip.coordinates[0],
        lng: trip.coordinates[1],
        text: `${trip.childName} at ${moment(trip.checkedAt).format('LLL')}`,
      },
    ];

    const path = trip.track.map(([lat, lng]) => ({ lat, lng }));
    setGeoData({ markers, path });
  };

  const data: IDataItem[] = items.map((item, index) => {
    return {
      id: item.id,
      values: [
        item.childName,
        <Date value={item.checkedAt} format={'MM/DD/YYYY, HH:mm'} key={index} />,
        item.routeTitle,
        mapDirection(item.direction),
        item.assistantName,
        <AssignmentTurnedInIcon
          onClick={() => {
            setTripId(item.tripId);
            setOpen(true);
          }}
          key={index}
          style={{ cursor: 'pointer' }}
        />,
        <Button
          key={item.id}
          disabled={!(item.coordinates && item.coordinates.length)}
          onClick={() => {
            handleTripGeoData(item);
            setMapOpen(true);
          }}
        >
          Show on map
        </Button>,
      ],
    };
  });

  const updateDataAction = (queryParams: IListParams) =>
    Actions.fetchTrips({
      ...queryParams,
      checkedAtFrom: startDate?.startOf('day').format(),
      checkedAtTo: endDate?.endOf('day').format(),
    });

  return (
    <React.Fragment>
      <CommonDialog onClose={handleClose} aria-labelledby="trip-signature-details" open={isOpen} width={435}>
        <TripSignature tripId={tripId} />
      </CommonDialog>
      <CommonDialog onClose={handleInfoClose} aria-labelledby="show-info" open={isInfoOpen} width={435}>
        <div>
          <Typography variant="h5" component="h5">
            The trip log is uploaded to the panel according to the following logic:
          </Typography>
          <ListItemText>
            1. By pressing the &quot;Update&quot; button in the mobile application (If there is no running trip at this
            time)
          </ListItemText>
          <ListItemText>
            2. Twice a day at 8:00 PM and 16:00 AM (time zone -5), if the application is active at that moment, there is
            an Internet connection and no running trip at this time
          </ListItemText>
        </div>
      </CommonDialog>
      <CommonDialog onClose={handleCreateTripClose} open={isCreateTripOpen} aria-labelledby="create-trip">
        <CreateTrip onFinish={handleCreateTripClose} />
      </CommonDialog>
      <CommonDialog onClose={handleMapClose} aria-labelledby="show-map" open={isMapOpen} width={600}>
        <div style={{ width: '100%', height: 500 }}>
          <Map markers={geoData?.markers} path={geoData?.path} />
        </div>
      </CommonDialog>
      <Grid className={classes.dashboard} container direction="row" justify="flex-end" alignItems="baseline">
        <Grid item>Select dates</Grid>
        <Grid item>
          <DateRangePicker
            startDate={startDate}
            startDateId="fromDate"
            endDate={endDate}
            endDateId="toDate"
            onDatesChange={changeRange}
            focusedInput={focused}
            minimumNights={0}
            onFocusChange={(focusedInput) => setFocused(focusedInput)}
            isOutsideRange={(day) => !isInclusivelyBeforeDay(day, moment())}
          />
        </Grid>
      </Grid>
      <List
        header={header}
        data={data}
        buttons={[
          <Tooltip key="add" title="Create trip record" arrow>
            <PlusButton
              id="create-button"
              key={0}
              onClick={() => {
                setCreateTripOpen(true);
              }}
              className={classes.headerButtons}
            ></PlusButton>
          </Tooltip>,
          <Tooltip key="download" title="Download as csv" arrow>
            <ExportButton id="export-trips-button" key={1} onClick={exportTrips} className={classes.headerButtons} />
          </Tooltip>,
          <Tooltip key="info" title="Show info about synchronization with mobile app" arrow>
            <InfoButton
              id="info-button"
              key={2}
              onClick={() => {
                setInfoOpen(true);
              }}
              className={classes.headerButtons}
            ></InfoButton>
          </Tooltip>,
        ]}
        showPagination={true}
        totalCount={totalCount}
        paginationColSpan={5}
        updateDataAction={updateDataAction}
        paginationParams={params}
      />
    </React.Fragment>
  );
};

export default TripList;
