import { NavigationItemType, INavigation } from '../types';
import DashboardIcon from '../assets/images/icons/DashboardIcon.svg';
import UsersIcon from '../assets/images/icons/UsersIcon.svg';
import ArrowIcon from '../assets/images/icons/arrow.svg';
import TripIcon from '../assets/images/icons/initiatedTransaction.svg';
import ProfileIcon from '../assets/images/icons/userJoined.svg';

export const navigationConfig: INavigation = [
  // {
  //   title: 'Dashboard',
  //   type: NavigationItemType.Group,
  //   icon: DashboardIcon,
  //   url: '/dashboard',
  //   active: false,
  // },
  {
    title: 'Schools',
    type: NavigationItemType.Group,
    icon: DashboardIcon,
    url: '/schools',
    active: false,
  },
  {
    title: 'Trackers',
    type: NavigationItemType.Group,
    icon: UsersIcon,
    url: '/trackers',
    active: false,
  },
  {
    title: 'Routes',
    type: NavigationItemType.Group,
    icon: ArrowIcon,
    url: '/routes',
    active: false,
  },
  {
    title: 'Students',
    type: NavigationItemType.Group,
    icon: UsersIcon,
    url: '/students',
    active: false,
  },
  {
    title: 'Trip logs',
    type: NavigationItemType.Group,
    icon: TripIcon,
    url: '/trips',
    active: false,
  },
  {
    title: 'Invite',
    type: NavigationItemType.Group,
    icon: UsersIcon,
    url: '/invite',
    active: false,
  },
  {
    title: 'Profile',
    type: NavigationItemType.Group,
    icon: ProfileIcon,
    url: '/profile',
    active: false,
  },
];
