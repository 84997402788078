import * as Actions from '../actions';

const initialState = {};

const error = (state = initialState, action: Actions.ErrorActionTypes) => {
  switch (action.type) {
    case Actions.SHOW_ERROR: {
      console.log('SHOW_ERROR', action);
      return {
        ...action.payload,
      };
    }
    case '@@router/LOCATION_CHANGE':
    case Actions.HIDE_ERROR: {
      return {};
    }

    default: {
      return state;
    }
  }
};

export default error;
