import { ThunkAction } from 'redux-thunk';
import { IApi, IStudent, IListParams, IStore } from '../../types';
import { showError } from './error.actions';
import { showNotification } from './notification.actions';
import { startLoading, completeLoading } from './loading.actions';
import { IAddStudent } from '../../validation/addStudent';

export const FETCH_STUDENTS_BEGIN = 'FETCH_STUDENTS_BEGIN';

export const FETCH_STUDENTS_SUCCESS = 'FETCH_STUDENTS_SUCCESS';

export const CREATE_STUDENT_BEGIN = 'CREATE_STUDENT_BEGIN';

export const CREATE_STUDENT_SUCCESS = 'CREATE_STUDENT_SUCCESS';

export const FETCH_STUDENT_BEGIN = 'FETCH_STUDENT_BEGIN';

export const FETCH_STUDENT_SUCCESS = 'FETCH_STUDENT_SUCCESS';

export const UPDATE_STUDENT_BEGIN = 'UPDATE_STUDENT_BEGIN';

export const UPDATE_STUDENT_SUCCESS = 'UPDATE_STUDENT_SUCCESS';

interface IStudentsFetchBegin {
  type: typeof FETCH_STUDENTS_BEGIN;
}

interface IStudentsFetchSuccess {
  type: typeof FETCH_STUDENTS_SUCCESS;
  payload: {
    items: IStudent[];
    totalCount: number;
  };
}

interface IStudentCreateBegin {
  type: typeof CREATE_STUDENT_BEGIN;
}

interface IStudentCreateSuccess {
  type: typeof CREATE_STUDENT_SUCCESS;
  payload: IStudent;
}

interface IStudentFetchBegin {
  type: typeof FETCH_STUDENT_BEGIN;
}

interface IStudentFetchSuccess {
  type: typeof FETCH_STUDENT_SUCCESS;
  payload: IStudent;
}

interface IStudentUpdateBegin {
  type: typeof UPDATE_STUDENT_BEGIN;
}

interface IStudentUpdateSuccess {
  type: typeof UPDATE_STUDENT_SUCCESS;
  payload: IStudent;
}

export type StudentActionTypes =
  | IStudentsFetchBegin
  | IStudentsFetchSuccess
  | IStudentCreateBegin
  | IStudentCreateSuccess
  | IStudentFetchBegin
  | IStudentFetchSuccess
  | IStudentUpdateBegin
  | IStudentUpdateSuccess;

export const fetchStudents = (
  params: IListParams,
): ThunkAction<void, IStore, { Api: IApi }, StudentActionTypes> => async (dispatch, getState, { Api }) => {
  try {
    dispatch(startLoading());
    dispatch({ type: FETCH_STUDENTS_BEGIN });
    const students = await Api.StudentsService.setAccessToken(getState().auth.accessToken).fetchAll(params);
    return dispatch({
      type: FETCH_STUDENTS_SUCCESS,
      payload: students,
    });
  } catch (e) {
    dispatch(showError(e));
  } finally {
    dispatch(completeLoading());
  }
};

export const addStudent = (form: IAddStudent): ThunkAction<void, IStore, { Api: IApi }, StudentActionTypes> => async (
  dispatch,
  getState,
  { Api },
) => {
  try {
    dispatch(startLoading());
    dispatch({
      type: CREATE_STUDENT_BEGIN,
    });
    const student = await Api.StudentsService.setAccessToken(getState().auth.accessToken).addStudent(form);
    dispatch(showNotification('Saved successfully'));
    dispatch({
      type: CREATE_STUDENT_SUCCESS,
      payload: student,
    });
    return dispatch(fetchStudents(getState().routes.list.params));
  } catch (e) {
    dispatch(showError(e));
  } finally {
    dispatch(completeLoading());
  }
};

export const fetchStudentById = (id: string): ThunkAction<void, IStore, { Api: IApi }, StudentActionTypes> => async (
  dispatch,
  getState,
  { Api },
) => {
  try {
    dispatch(startLoading());
    dispatch({ type: FETCH_STUDENT_BEGIN });
    const student = await Api.StudentsService.setAccessToken(getState().auth.accessToken).fetchStudent(id);
    return dispatch({
      type: FETCH_STUDENT_SUCCESS,
      payload: student,
    });
  } catch (e) {
    dispatch(showError(e));
  } finally {
    dispatch(completeLoading());
  }
};

export const updateStudentById = (
  id: string,
  form: IAddStudent,
): ThunkAction<void, IStore, { Api: IApi }, StudentActionTypes> => async (dispatch, getState, { Api }) => {
  try {
    dispatch(startLoading());
    dispatch({
      type: UPDATE_STUDENT_BEGIN,
    });
    const student = await Api.StudentsService.setAccessToken(getState().auth.accessToken).updateStudent(id, form);
    dispatch(showNotification('Updated successfully'));
    return dispatch({
      type: UPDATE_STUDENT_SUCCESS,
      payload: student,
    });
  } catch (e) {
    dispatch(showError(e));
  } finally {
    dispatch(completeLoading());
  }
};
