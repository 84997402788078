import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SchoolList from './List';
import { IStore } from '../../types';
import * as Actions from '../../store/actions';

const School = () => {
  const dispatch = useDispatch();
  const { items, params, totalCount } = useSelector((state: IStore) => state.schools.list);

  const fetchSchools = useCallback(() => {
    dispatch(Actions.fetchSchools(params));
  }, [dispatch, params]);

  useEffect(() => {
    fetchSchools();
  }, [fetchSchools]);

  return (
    <React.Fragment>
      <SchoolList items={items.length ? items : []} params={params} totalCount={totalCount} />
    </React.Fragment>
  );
};

export default School;
