import React from 'react';
import UserList from './List';

const Invite = () => {
  return (
    <React.Fragment>
      <UserList />
    </React.Fragment>
  );
};

export default Invite;
