import React, { FunctionComponent } from 'react';
import InfoIcon from '@material-ui/icons/Info';
import { ButtonProps } from '@material-ui/core/Button';
import { RoundButton } from './RoundButton';

interface IProps {}

export const InfoButton: FunctionComponent<IProps & ButtonProps> = (props) => {
  return (
    <RoundButton {...props} color="secondary">
      <InfoIcon />
    </RoundButton>
  );
};
