import React, { useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { IMarker, IPoint } from '../../types';
import { config } from '../../config';

type State = {
  center: {
    lat: number;
    lng: number;
  };
  zoom: number;
};

interface IProps {
  markers?: IMarker[];
  path?: IPoint[];
}

export default function MapCointainer(props: IProps) {
  const centerLat = props.markers && props.markers.length && +props.markers[0].lat;
  const centerLng = props.markers && props.markers.length && +props.markers[0].lng;

  const [state] = useState<State>({
    center: {
      lat: centerLat || 37.188493,
      lng: centerLng || -93.636304,
    },
    zoom: 13,
  });
  const renderMarkers = (map: any, maps: any) => {
    if (props.markers) {
      props.markers.forEach((m) => {
        const infowindow = new maps.InfoWindow({
          content: m.text,
        });

        const marker = new maps.Marker({
          position: { lat: +m.lat, lng: +m.lng },
          map,
          title: m.text,
        });

        marker.addListener('click', () => {
          infowindow.open(map, marker);
        });
      });
    }

    if (props.path) {
      const track = new maps.Polyline({
        path: props.path.map(p => ({ lat: +p.lat, lng: +p.lng })),
        geodesic: true,
        strokeColor: '#FF0000',
        strokeOpacity: 1.0,
        strokeWeight: 2,
      });

      track.setMap(map);
    }
  };

  return (
    <GoogleMapReact
      bootstrapURLKeys={{ key: config.googleApiKey }}
      defaultCenter={state.center}
      defaultZoom={state.zoom}
      yesIWantToUseGoogleMapApiInternals
      onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
    />
  );
}
