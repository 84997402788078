import * as Yup from 'yup';

export interface IAddAssistant {
  mobilePhone: string;
  firstName: string;
  lastName: string;
  password?: string;
  routes: string[];
  externalId: number | null;
  internal: boolean;
  rawPassword?: string;
}

export const schema = Yup.object().shape({
  firstName: Yup.string()
    .label('First name')
    .matches(/^[a-zA-Z]+$/, 'First name must contain alphabetic characters.')
    .required(),
  lastName: Yup.string()
    .label('Last name')
    .matches(/^[a-zA-Z]+$/, 'Last name must contain alphabetic characters.')
    .required(),
  password: Yup.string()
    .label('Pin')
    .matches(/^([0-9]+|\*\*\*\*)$/, 'Pin should contain only be digits.')
    .length(4, 'Pin must be a four-digit number.')
    .required(),
  routes: Yup.array().of(Yup.string()),
  mobilePhone: Yup.string().required(),
  externalId: Yup.number().nullable(),
  internal: Yup.boolean(),
});

export const schemaUpdate = Yup.object().shape({
  firstName: Yup.string()
    .label('First name')
    .matches(/^[a-zA-Z]+$/, 'First name must contain alphabetic characters.'),
  lastName: Yup.string()
    .label('Last name')
    .matches(/^[a-zA-Z]+$/, 'Last name must contain alphabetic characters.'),
  mobilePhone: Yup.string(),
  password: Yup.string()
    .label('Pin')
    .matches(/^([0-9]+|\*\*\*\*)$/, 'Pin should contain only be digits.')
    .length(4, 'Pin must be a four-digit number.'),
  routes: Yup.array().of(Yup.string()),
  externalId: Yup.number().nullable(),
  internal: Yup.boolean(),
});

export const initialData: IAddAssistant = {
  firstName: '',
  lastName: '',
  mobilePhone: '',
  password: '',
  routes: [],
  externalId: null,
  internal: true,
  rawPassword: '',
};
