import AssitantsService from './AssistantsService';
import AuthService from './auth';
import SchoolsService from './SchoolsService';
import RoutesService from './RoutesService';
import StudentsService from './StudentsService';
import TripsService from './TripsService';
import UserService from './UserService';

export default {
  AuthService: new AuthService(),
  AssitantsService: new AssitantsService(),
  SchoolsService: new SchoolsService(),
  RoutesService: new RoutesService(),
  StudentsService: new StudentsService(),
  TripsService: new TripsService(),
  UserService: new UserService(),
};
