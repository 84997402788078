import React, { useEffect, useCallback } from 'react';
import clsx from 'clsx';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import { useDispatch, useSelector } from 'react-redux';
import { createStyles, Theme, withStyles, makeStyles } from '@material-ui/core/styles';
import { IStore } from '../types';
import * as Actions from '../store/actions';
import CollapseClose from '../assets/images/icons/backburger.svg';

const styles = (theme: Theme) =>
  createStyles({
    header: {
      height: '61px',
      background: 'white',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      position: 'fixed',
      zIndex: 200,
    },
    headerOpen: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      width: 'calc(100% - 240px)',
    },
    headerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: 'calc(100% - 66px)',
    },
    name: {
      fontFamily: 'Muli',
      color: 'black',
      fontSize: '13px',
      opacity: '0.87',
      lineHeight: '1.2',
      marginRight: '10px',
    },
    collapseBtn: {
      width: '64px',
      height: '100%',
      borderRight: '1px solid #f5f5f5',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
    },
    activityBtn: {
      width: '64px',
      height: '100%',
      borderLeft: '1px solid #f5f5f5',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
    },
    rightBlock: {
      display: 'flex',
      height: '100%',
      alignItems: 'center',
    },
    collapseImg: {
      width: '24px',
      height: '24px',
    },
    collapseImgOpen: {
      transform: 'rotate(0deg)',
      transition: theme.transitions.create('transform', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    collapseImgClose: {
      transition: theme.transitions.create('transform', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      transform: 'rotate(180deg)',
    },
    activityImg: {
      width: '24px',
      height: '24px',
    },
    activityBlock: {
      width: '24px',
      height: '24px',
      position: 'relative',
    },
    activityCircle: {
      width: '12px',
      height: '12px',
      background: 'white',
      borderRadius: '50%',
      position: 'absolute',
      left: '-7px',
      top: '-2px',
      zIndex: 11,
      '&::after': {
        content: '""',
        display: 'block',
        width: '8px',
        height: '8px',
        borderRadius: '50%',
        position: 'absolute',
        left: '2px',
        top: '2px',
        background: '#d0333f',
        zIndex: -1,
      },
    },
  });
const useStyles = makeStyles(styles);

function Header() {
  const appSettings = useSelector((state: IStore) => state.appSettings);
  const auth = useSelector((state: IStore) => state.auth);
  const classes = useStyles();
  const dispatch = useDispatch();

  const fetchSelfData = useCallback(() => {
    dispatch(Actions.getSelfUserData());
  }, [dispatch]);

  useEffect(() => {
    fetchSelfData();
  }, [fetchSelfData]);

  const districts = auth.user.districts;
  const currentDistrict = auth.user.currentDistrict;
  const userFirstNameLastName = `${auth.user.firstName} ${auth.user.lastName}`;

  function handleDrawer() {
    dispatch(Actions.setDrawer(!appSettings.isDrawerOpen));
  }

  const handleChanges = (event: React.ChangeEvent<any>) => {
    dispatch(Actions.setCurrentDistrict(event.target.value));
    console.log(Actions.getNavigation());
    //dispatch(Actions.setNavigation());
  };

  return (
    <div
      className={clsx(classes.header, {
        [classes.headerOpen]: appSettings.isDrawerOpen,
        [classes.headerClose]: !appSettings.isDrawerOpen,
      })}
    >
      <div onClick={handleDrawer} className={classes.collapseBtn}>
        <img
          className={clsx(classes.collapseImg, {
            [classes.collapseImgOpen]: appSettings.isDrawerOpen,
            [classes.collapseImgClose]: !appSettings.isDrawerOpen,
          })}
          src={CollapseClose}
          alt={'navigator'}
        />
      </div>
      <div className={classes.rightBlock}>
        <PersonIcon />
        <div>
          <div className={classes.name}>{userFirstNameLastName}</div>
        </div>
        <div>
          <FormControl component="fieldset">
            <Select
              name="districtId"
              id="select-district"
              onChange={handleChanges}
              value={currentDistrict.id}
              className={classes.name}
            >
              {districts.map(({ id, name }) => (
                <MenuItem key={id} value={id} className={classes.name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
    </div>
  );
}

export default withStyles(styles)(Header);
