import * as Yup from 'yup';

export interface IAddSchool {
  name: string;
  address: string | null;
  contactInfo: string | null;
  districtId?: string;
  externalId: number | null;
}

export const schema = Yup.object().shape({
  name: Yup.string().required(),
  address: Yup.string().nullable(),
  contactInfo: Yup.string().nullable(),
  externalId: Yup.number().nullable(),
});

export const schemaUpdate = Yup.object().shape({
  name: Yup.string(),
  address: Yup.string().nullable(),
  contactInfo: Yup.string().nullable(),
  externalId: Yup.number().nullable(),
});

export const initialData: IAddSchool = {
  name: '',
  address: null,
  contactInfo: null,
  externalId: null,
};
