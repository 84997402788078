import axios from 'axios';
import { config } from '../config';
import { IRoute, IListParams, IGetListAPIResponse } from '../types';
import { IAddRoute } from '../validation/addRoute';

interface IGetAllRoutesData {
  items: IRoute[];
  totalCount: number;
}

export default class RoutesService {
  private apiBaseUrl: string;

  private headers: { [key: string]: string } = {};

  constructor() {
    this.apiBaseUrl = config.apiBaseUrl;
  }

  public setAccessToken(token: string): RoutesService {
    this.headers['X-Session-Token'] = token;
    return this;
  }

  public async fetchAll(params: IListParams): Promise<IGetAllRoutesData> {
    const response = await axios.request<IGetListAPIResponse<IRoute[]>>({
      params,
      method: 'get',
      url: `${this.apiBaseUrl}/routes`,
      headers: this.headers,
    });
    const {
      data: items,
      headers: { 'x-total-count': totalCount },
    } = response;
    return { items, totalCount: Number.parseInt(totalCount) };
  }

  public async addRoute(data: IAddRoute): Promise<IRoute> {
    const response = await axios.request<{ data: IRoute }>({
      method: 'post',
      url: `${this.apiBaseUrl}/routes`,
      headers: this.headers,
      data,
    });
    const { data: newRoute } = response;
    return newRoute;
  }

  public async fetchRoute(id: string): Promise<IRoute> {
    const response = await axios.request<{ data: IRoute }>({
      method: 'get',
      url: `${this.apiBaseUrl}/routes/${id}`,
      headers: this.headers,
    });
    const { data: route } = response;
    return route;
  }

  public async updateRoute(id: string, data: IAddRoute): Promise<IRoute> {
    const response = await axios.request<{ data: IRoute }>({
      method: 'post',
      url: `${this.apiBaseUrl}/routes/${id}/update`,
      headers: this.headers,
      data,
    });
    const { data: route } = response;
    return route;
  }

  public async importRoute(files: File[]): Promise<IRoute[]> {
    const [file] = files;
    const formData = new FormData();
    formData.append('file', file);

    const response = await axios.request<{ data: IRoute[] }>({
      method: 'post',
      url: `${this.apiBaseUrl}/routes/import`,
      headers: {
        ...this.headers,
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
    });
    const { data } = response;
    return data;
  }
}
