import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { useParams } from 'react-router-dom';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Button, Grid, Card, CardContent, TextField } from '@material-ui/core';
import * as Actions from '../store/actions';
import { initialData, schema } from '../validation/setPassword';
import { IFormState, useForm } from '../helpers/form';
import { IStore, ISetPasswordForm } from '../types';
import ErrorSnackbar from './ErrorSnackbar';

const useStyles = makeStyles(() =>
  createStyles({
    grid: {
      background: '#22262f',
    },
    card: {
      width: '515px',
      borderRadius: '4px',
      'text-align': 'center',
    },
    textField: {
      marginTop: 0,
      fontFamily: 'Muli',
      marginBottom: '26px',
    },
  }),
);

function SetPassword() {
  const dispatch = useDispatch();
  const { code } = useParams();
  const classes = useStyles();

  const accessToken = useSelector((state: IStore) => state.auth?.accessToken);

  const onSubmit = (form: IFormState<ISetPasswordForm>) => {
    dispatch(Actions.setPassword(form.values.code, form.values.password));
  };
  const [formState, , handleChanges, handleSubmit] = useForm<ISetPasswordForm>(
    { ...initialData, code },
    schema,
    onSubmit,
  );
  const { values, errors } = formState;
  if (accessToken) {
    return <Redirect to="/trackers" />;
  }

  return (
    <Grid container direction="column" justify="center" alignItems="center" className={classes.grid}>
      <Card className={classes.card}>
        <CardContent>
          <Grid item xs={11}>
            <TextField
              required
              id="code"
              label="code"
              variant="outlined"
              name="code"
              value={values.code}
              error={!!errors.code}
              helperText={errors.code}
              className={classes.textField}
              onChange={handleChanges}
            />
            <TextField
              required
              id="password"
              label="password"
              variant="outlined"
              name="password"
              value={values.password}
              error={!!errors.password}
              helperText={errors.password}
              className={classes.textField}
              onChange={handleChanges}
              type="password"
            />
            <TextField
              required
              id="passwordConfirm"
              label="Confirm password"
              variant="outlined"
              name="passwordConfirm"
              value={values.passwordConfirm}
              error={!!errors.passwordConfirm}
              helperText={errors.passwordConfirm}
              className={classes.textField}
              onChange={handleChanges}
              type="password"
            />
            <ErrorSnackbar />
            <Button variant="contained" color="primary" id="set-password" onClick={handleSubmit}>
              SEND
            </Button>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}

export default SetPassword;
