import React, { FunctionComponent } from 'react';
import { ButtonProps } from '@material-ui/core/Button';
import { RoundButton } from './RoundButton';
import plusIcon from '../../../assets/images/icons/plus_icon.png';

interface IProps {}

export const PlusButton: FunctionComponent<IProps & ButtonProps> = (props) => {
  return (
    <RoundButton {...props} color="primary">
      <img src={plusIcon} alt='' />
    </RoundButton>
  );
};
