import React from 'react';
import {
  TextField,
  FormControl,
  FormLabel,
  Grid,
  FormHelperTextTypeMap,
  Select,
  MenuItem,
  InputLabel,
  Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { TrendingFlat, SyncAlt } from '@material-ui/icons';
import { OverrideProps } from '@material-ui/core/OverridableComponent';
import { useForm, IFormState } from '../../helpers/form';
import { IAddRoute, schema, schemaUpdate } from '../../validation/addRoute';
import { RouteDirection, RouteDirectionTitle, ISchool } from '../../types';
import { DoneButton } from '../UI/buttons/DoneButton';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiFormControl-root': {
      marginTop: 0,
      fontFamily: 'Muli',
      marginBottom: '26px',
    },
  },
  reflectedArrow: {
    transform: 'scaleX(-1)',
    '-webkit-transform': 'scaleX(-1)',
    '-moz-transform': 'scaleX(-1)',
    '-o-transform': 'scaleX(-1)',
    '-ms-transform': 'scaleX(-1)',
  },
  modalHeader: {
    fontFamily: 'Muli',
    fontSize: '35px',
    lineHeight: 0.51,
    color: '#2d323e',
    marginBottom: '40px',
  },
  container: {
    minWidth: 400,
    padding: theme.spacing(2),
  },
  directionLabel: {
    marginBottom: theme.spacing(2),
  },
  toggleLabel: {
    color: '#000000',
  },
}));

const buildId = (suffix: string) => {
  return `create-route-form-${suffix}`;
};

const textInput: Partial<OverrideProps<FormHelperTextTypeMap<{}, 'p'>, 'p'>> = {
  style: {
    paddingLeft: 0,
    marginLeft: 0,
    fontFamily: 'Muli',
    fontSize: '10px',
    color: '#d75a4a',
    fontWeight: 'bold',
  },
};

interface IProps {
  onSubmit: (form: IFormState<IAddRoute>) => void;
  initialData: IAddRoute;
  schema: typeof schema | typeof schemaUpdate;
  schools: ISchool[];
  name: string;
}

const RouteForm = (props: IProps) => {
  const classes = useStyles();
  const [formState, , handleChanges, handleSubmit] = useForm<IAddRoute>(
    props.initialData,
    props.schema,
    props.onSubmit,
  );
  const { values, errors } = formState;

  const handleDistrictChanges = (e: any, value: string) => {
    const event = {
      target: { name: 'direction', value, context: e },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      persist() {},
    };
    handleChanges(event as React.ChangeEvent<any>);
  };

  return (
    <Grid container direction="column" justify="center" alignItems="flex-end" className={classes.root}>
      <Grid container direction="column" justify="center" alignItems="flex-start" className={classes.container}>
        <div className={classes.modalHeader} id={buildId('title')}>
          {values.externalId ? "You can't edit the imported data" : props.name}
        </div>
        <Grid item xs={11}>
          <Tooltip placement="left" title="Please enter a route title" arrow>
            <TextField
              name="title"
              label="Title"
              value={values.title}
              error={!!errors.title}
              helperText={errors.title}
              onChange={handleChanges}
              required
              FormHelperTextProps={textInput}
              variant="outlined"
              disabled={Boolean(values.externalId)}
            />
          </Tooltip>
          <Tooltip placement="left" title="Please select the school to which the route belongs" arrow>
            <FormControl component="fieldset">
              <InputLabel id="school-select-label">School</InputLabel>
              <Select
                name="facilityId"
                labelId="school-select-label"
                id="school-select"
                onChange={handleChanges}
                disabled={Boolean(values.externalId)}
                value={values.facilityId}
              >
                {props.schools.map((school) => (
                  <MenuItem key={school.id} value={school.id}>
                    {school.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Tooltip>
          <Tooltip placement="left" title="Please select direction" arrow>
            <FormControl component="fieldset">
              <FormLabel component="legend" className={classes.directionLabel}>
                Direction
              </FormLabel>
              <ToggleButtonGroup value={values.direction} exclusive onChange={handleDistrictChanges}>
                <ToggleButton
                  value={RouteDirection.both}
                  className={classes.toggleLabel}
                  disabled={Boolean(values.externalId)}
                >
                  <SyncAlt /> {RouteDirectionTitle.both}
                </ToggleButton>
                <ToggleButton
                  value={RouteDirection.toSchool}
                  className={classes.toggleLabel}
                  disabled={Boolean(values.externalId)}
                >
                  <TrendingFlat /> {RouteDirectionTitle.toSchool}
                </ToggleButton>
                <ToggleButton
                  value={RouteDirection.fromSchool}
                  className={classes.toggleLabel}
                  disabled={Boolean(values.externalId)}
                >
                  <TrendingFlat className={classes.reflectedArrow} /> {RouteDirectionTitle.fromSchool}
                </ToggleButton>
              </ToggleButtonGroup>
            </FormControl>
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container direction="column" justify="center" alignItems="flex-end">
        <DoneButton
          id={buildId('submit-button')}
          onClick={(event: React.ChangeEvent<any>) => {
            handleSubmit(event);
          }}
          disabled={Boolean(values.externalId)}
        />
      </Grid>
    </Grid>
  );
};

export default RouteForm;
