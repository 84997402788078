import { ThunkAction } from 'redux-thunk';
import { IApi, IStore } from '../../types';
import { ILogoutSuccess, LOGOUT_SUCCESS } from './auth.actions';

export const SHOW_ERROR = 'SHOW_ERROR';
export const HIDE_ERROR = 'HIDE_ERROR';

interface IShowError {
  type: typeof SHOW_ERROR;
  payload: IApiError;
}

interface IApiError {
  code: string;
  error: string;
  message: string;
}

export interface IApiErrorResponse {
  response: {
    data: IApiError;
    status: number;
  };
}

interface IHideError {
  type: typeof HIDE_ERROR | '@@router/LOCATION_CHANGE';
}

export type ErrorActionTypes = IShowError | IHideError | ILogoutSuccess;

const prepareErrorPayload = (error: IApiErrorResponse): IApiError => {
  if (!error.response || !error.response.data) {
    return {
      code: '500',
      error: error.toString(),
      message: error.toString(),
    };
  }

  switch (error.response.status) {
    case 403:
      return {
        code: '403',
        error: 'You do not have permission to complete this request',
        message: 'You do not have permission to complete this request',
      };
    default:
      return error.response.data;
  }
};

export const showError = (
  error: Error | IApiErrorResponse,
): ThunkAction<void, IStore, { Api: IApi }, ErrorActionTypes> => async (dispatch) => {
  const apiErrorResponse = error as IApiErrorResponse;

  console.warn(error);
  if (apiErrorResponse.response && apiErrorResponse.response.status === 401) {
    setTimeout(() => dispatch({ type: LOGOUT_SUCCESS }), 2000);
  }

  return dispatch({
    type: SHOW_ERROR,
    payload: prepareErrorPayload(apiErrorResponse),
  });
};

export const hideError = (): ThunkAction<void, IStore, { Api: IApi }, ErrorActionTypes> => async (dispatch) => {
  return dispatch({
    type: HIDE_ERROR,
  });
};
