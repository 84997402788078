import React from 'react';
import { Grid, Paper } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import * as Actions from '../../store/actions';
import { IFormState } from '../../helpers/form';
import { ISignInForm } from '../../types';
import { SignInTLForm } from '../SignInTLForm';
import ErrorSnackbar from '../ErrorSnackbar';
import SuccessSnackbar from '../SuccessSnackbar';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      minWidth: 300,
      // padding: theme.spacing(2),
      paddingBottom: 0,
    },
    loginHeader: {
      textAlign: 'center',
      fontFamily: 'Muli',
      fontSize: '17px',
      lineHeight: '30px',
      color: '#000000',
      opacity: 0.87,
      textTransform: 'uppercase',
      marginBottom: '30px',
    },
    loginText: {
      marginTop: '30px',
      textAlign: 'center',
      fontFamily: 'Muli',
      fontSize: '15px',
      lineHeight: '30px',
      color: '#000000',
      opacity: 0.87,

      b: {
        fontWeight: 'bold',
      },
    },
    textField: {
      marginTop: 0,
      fontFamily: 'Muli',
      marginBottom: '26px',
    },
  }),
);

function Sync() {
  const dispatch = useDispatch();
  const classes = useStyles();

  const onSubmit = (form: IFormState<ISignInForm>) => {
    dispatch(Actions.syncSelfDistricts({ username: form.values.username, password: form.values.password }));
  };

  return (
    <Grid container justify="center" direction="column" alignItems="center">
      <Paper>
        <div className={classes.loginHeader}>Login to your Therapylog account</div>
        <SignInTLForm onSubmit={onSubmit} />
      </Paper>
      <ErrorSnackbar />
      <SuccessSnackbar />
    </Grid>
  );
}

export default Sync;
