import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import * as Actions from '../../store/actions';
import { IFormState } from '../../helpers/form';
import { IAddSchool, initialData, schema } from '../../validation/addSchool';
import { ISchool, IListParams } from '../../types';
import List, { IDataItem } from '../List';
import SchoolForm from './Form';
import { PlusButton } from '../UI/buttons/PlusButton';
import { buildId } from '../../helpers/utils';
import CommonDialog from '../UI/dialog';
import SchoolDetail from './Details';

interface IProps {
  items: ISchool[];
  params: IListParams;
  totalCount: number;
}

const SchoolList = (props: IProps) => {
  const dispatch = useDispatch();

  const setHeader = useCallback(() => {
    dispatch(Actions.setSubheader('Schools'));
  }, [dispatch]);

  useEffect(() => {
    setHeader();
  }, [setHeader]);

  const [isCreateOpen, setCreateOpen] = useState(false);
  const onCreateSubmit = (form: IFormState<IAddSchool>) => {
    dispatch(Actions.addSchool({ ...form.values }));
    setCreateOpen(false);
  };
  const handleCreateClose = () => {
    setCreateOpen(false);
  };

  const [activeSchoolId, setActiveSchool] = useState<string>('null');
  const [isUpdateOpen, setUpdateOpen] = useState(false);
  const onUpdateSubmit = (form: IFormState<IAddSchool>) => {
    dispatch(Actions.updateSchoolById(activeSchoolId, { ...form.values }));
  };
  const handleUpdateClose = () => {
    setUpdateOpen(false);
  };

  const header = [
    { title: 'Name', sort: true },
    { title: 'Address', sort: false },
    { title: 'Contact info', sort: false },
  ];

  const data: IDataItem[] = props.items.map((item) => {
    return {
      id: item.id,
      onClick: (event: React.SyntheticEvent) => {
        event.preventDefault();
        setActiveSchool(item.id);
        setUpdateOpen(true);
      },
      values: [item.name, item.address, item.contactInfo],
    };
  });

  return (
    <React.Fragment>
      <CommonDialog onClose={handleCreateClose} aria-labelledby="school-create-dialog" open={isCreateOpen}>
        <SchoolForm onSubmit={onCreateSubmit} initialData={initialData} schema={schema} name="Create school" />
      </CommonDialog>
      <CommonDialog onClose={handleUpdateClose} aria-labelledby="school-update-dialog" open={isUpdateOpen}>
        {activeSchoolId && <SchoolDetail schoolId={activeSchoolId} onSubmit={onUpdateSubmit} />}
      </CommonDialog>
      <List
        header={header}
        data={data}
        buttons={[
          <PlusButton id={buildId(props, 'add-assistant-button')} key={0} onClick={() => setCreateOpen(true)} />,
        ]}
      />
    </React.Fragment>
  );
};

export default SchoolList;
