import * as Yup from 'yup';
import { IForgotPasswordForm } from '../types';

export const schema = Yup.object().shape({
  username: Yup.string().required(),
});

export const initialData: IForgotPasswordForm = {
  username: '',
};
