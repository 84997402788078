import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IFormState } from '../../helpers/form';
import { IStore } from '../../types';
import * as Actions from '../../store/actions';
import { IAddSchool, schemaUpdate } from '../../validation/addSchool';
import SchoolForm from './Form';

interface IProps {
  schoolId: string;
  onSubmit: (form: IFormState<IAddSchool>) => void;
}

const SchoolDetail = (props: IProps) => {
  const dispatch = useDispatch();
  const school = useSelector((state: IStore) => state.schools.item);

  const fetchSchool = useCallback(() => {
    dispatch(Actions.fetchSchoolById(props.schoolId));
  }, [dispatch, props.schoolId]);

  useEffect(() => {
    fetchSchool();
  }, [fetchSchool]);

  return (
    <React.Fragment>
      {school && school.id === props.schoolId && (
        <SchoolForm onSubmit={props.onSubmit} initialData={school} schema={schemaUpdate} name="Update school" />
      )}
    </React.Fragment>
  );
};

export default SchoolDetail;
