import * as Yup from 'yup';

export interface IAddRoute {
  title: string;
  direction: string;
  facilityId: string | null;
  externalId: number | null;
}

export const schema = Yup.object().shape({
  title: Yup.string().required(),
  direction: Yup.string().required(),
  facilityId: Yup.string().nullable(),
  externalId: Yup.number().nullable(),
});

export const schemaUpdate = Yup.object().shape({
  title: Yup.string(),
  direction: Yup.string(),
  facilityId: Yup.string(),
  externalId: Yup.number().nullable(),
});

export const initialData: IAddRoute = {
  title: '',
  direction: 'both',
  facilityId: null,
  externalId: null,
};
