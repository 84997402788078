import * as Actions from '../actions';

const initialState = {
  message: '',
};

const notification = (state = initialState, action: Actions.NotificationActionTypes | Actions.LocationActionTypes) => {
  switch (action.type) {
    case Actions.SHOW_NOTIFICATION: {
      return {
        ...action.payload,
      };
    }
    case Actions.LOCATION_CHANGE:
    case Actions.HIDE_NOTIFICATION: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default notification;
