import axios from 'axios';
import { config } from '../config';
import { IAuthToken } from '../types';

declare module 'axios' {
  // eslint-disable-next-line
  export interface AxiosResponse<T = any> extends Promise<T> {}
}

class AuthService {
  private apiBaseUrl: string;

  private headers: { [key: string]: string } = {};

  constructor() {
    this.apiBaseUrl = config.apiBaseUrl;
  }

  // public setAccessToken(token: string): AuthService {
  //   this.headers['X-Access-Token'] = token;
  //   return this;
  // }

  public async sigIn(username: string, password: string): Promise<IAuthToken> {
    const response = await axios.request<{ data: IAuthToken }>({
      method: 'post',
      url: `${this.apiBaseUrl}/users/sign-in`,
      headers: this.headers,
      data: {
        username,
        password,
      },
    });
    const { data } = response;
    return data;
  }

  public async restoreAccess(email: string): Promise<void> {
    await axios.request<void>({
      method: 'post',
      url: `${this.apiBaseUrl}/users/reset-password/request`,
      headers: this.headers,
      data: {
        email,
      },
    });
  }

  public async setPassword(token: string, password: string): Promise<IAuthToken> {
    const response = await axios.request<{ data: IAuthToken }>({
      method: 'post',
      url: `${this.apiBaseUrl}/users/reset-password/confirm`,
      headers: this.headers,
      data: {
        token,
        password,
      },
    });

    const { data } = response;
    return data;
  }
}

export default AuthService;
