import * as Actions from '../actions';

const initialState = {
  isDrawerOpen: true,
  subheader: '',
  activity: true,
};

const asset = (state = initialState, action: Actions.AppSettingsActionTypes) => {
  switch (action.type) {
    case Actions.SET_DRAWER: {
      return {
        ...state,
        isDrawerOpen: action.payload,
      };
    }
    case Actions.SET_SUBHEADER: {
      return {
        ...state,
        subheader: action.payload,
      };
    }
    case Actions.SET_ACTIVITY: {
      return {
        ...state,
        isActivityOpen: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default asset;
