// eslint-disable-next-line @typescript-eslint/no-explicit-any
import BigNumber from 'bignumber.js';
import { RouteDirection } from '../types';

export const buildId = (props: any, suffix: string) => {
  let prefix = 'root';
  if (props.location && props.location.pathname) {
    prefix = props.location.pathname[0] === '/' ? props.location.pathname.substr(1) : props.location.pathname;
    prefix = prefix.replace('/', '-');
  }
  return `${prefix}-${suffix}`;
};

export const weiToETH = (wei: string) => {
  const decimals = 18;
  const source = new BigNumber(wei);
  const multiplier = new BigNumber(10).pow(new BigNumber(decimals));
  return source.dividedBy(multiplier);
};

export const ETHToWei = (eth: string | number | BigNumber) => {
  const decimals = 18;
  const source = new BigNumber(eth);
  const multiplier = new BigNumber(10).pow(new BigNumber(decimals));
  return source.multipliedBy(multiplier);
};

export const mapDirection = (code: string): string => {
  switch (code) {
    case RouteDirection.both:
      return 'Both';
    case RouteDirection.toSchool:
      return 'To School';
    case RouteDirection.fromSchool:
      return 'From School';
    default:
      return '';
  }
};
