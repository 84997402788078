import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as Actions from '../store/actions';

function Logout() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(Actions.unsetAuthorizationToken());
  });

  return <React.Fragment />;
}

export default Logout;
