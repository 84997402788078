import React from 'react';
import { Redirect } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import SignInForm from './SignInForm';
import { Grid } from '@material-ui/core';
import * as Actions from '../store/actions';
import { IFormState } from '../helpers/form';
import { IStore, ISignInForm } from '../types';
import Logo from '../assets/images/LogoSmall.svg';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      minWidth: 300,
      // padding: theme.spacing(2),
      paddingBottom: 0,
    },
    logoWrap: {
      width: '120px',
      height: '120px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      // background: '#03a9f4',
      marginBottom: '49px',
    },
    logo: {
      width: '120px',
      height: 'auto',
    },
    loginHeader: {
      textAlign: 'center',
      fontFamily: 'Muli',
      fontSize: '17px',
      lineHeight: '30px',
      color: '#000000',
      opacity: 0.87,
      // textTransform: 'uppercase',
      marginBottom: '30px',
    },
    loginText: {
      marginTop: '30px',
      textAlign: 'center',
      fontFamily: 'Muli',
      fontSize: '15px',
      lineHeight: '30px',
      color: '#000000',
      opacity: 0.87,

      b: {
        fontWeight: 'bold',
      },
    },
    textField: {
      marginTop: 0,
      fontFamily: 'Muli',
      marginBottom: '26px',
    },
  }),
);

function RequestAuth() {
  const dispatch = useDispatch();

  const onSubmit = (form: IFormState<ISignInForm>) => {
    dispatch(Actions.submitSignIn(form.values.username, form.values.password));
  };

  const classes = useStyles();
  const accessToken = useSelector((state: IStore) => state.auth?.accessToken);
  // const showProgress = useSelector((state: IStore) => state.progress.show);

  if (accessToken) {
    return <Redirect to="/trackers" />;
  }

  return (
    <Grid container justify="center" direction="column" alignItems="center">
      <div className={classes.logoWrap}>
        <img src={Logo} className={classes.logo} alt={'logo1'} />
      </div>
      <div className={classes.loginHeader}>Login to your admin DTTA account</div>
      <SignInForm onSubmit={onSubmit} needRedirect={true} />
      <input type="hidden" id="app-version" value={`${process.env.REACT_APP_VERSION}`} />
    </Grid>
  );
}

export default RequestAuth;
