import React, { useEffect, useCallback, useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { IFormState } from '../../helpers/form';
import { IStore, IAssistant } from '../../types';
import * as Actions from '../../store/actions';
import { IAddAssistant, schemaUpdate } from '../../validation/addAssistant';
import { AssistantForm } from './Form';

const BlockGroup = (props: { assistant: IAssistant; onClose: () => void }) => {
  const { assistant } = props;
  const dispatch = useDispatch();
  const [showConfirm, setShowConfirm] = useState(false);

  return (
    <React.Fragment>
      <Grid container>
        {assistant.status === 'active' ? (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              dispatch(Actions.disableAssistantById(assistant.id));
            }}
          >
            Disable
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              dispatch(Actions.activateAssistantById(assistant.id));
            }}
          >
            Activate
          </Button>
        )}
      </Grid>
      {showConfirm ? (
        <Grid container style={{ marginTop: '10px' }}>
          <Grid item style={{ marginRight: '10px' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setShowConfirm(false);
              }}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item style={{ marginRight: '10px' }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                dispatch(Actions.deleteAssistantById(assistant.id));
                props.onClose();
              }}
            >
              Yes, remove
            </Button>
          </Grid>
        </Grid>
      ) : (
        <Grid container style={{ marginTop: '10px' }}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              setShowConfirm(true);
            }}
          >
            Remove
          </Button>
        </Grid>
      )}
    </React.Fragment>
  );
};

interface IProps {
  assistantId: string;
  onSubmit: (form: IFormState<IAddAssistant>) => void;
  onClose: () => void;
}

const AssistantDetail = (props: IProps) => {
  const dispatch = useDispatch();
  const assistant = useSelector((state: IStore) => state.assistants.item);

  const fetchAssistant = useCallback(() => {
    dispatch(Actions.fetchAssistantById(props.assistantId));
  }, [dispatch, props.assistantId]);

  useEffect(() => {
    fetchAssistant();
  }, [fetchAssistant]);

  return (
    <React.Fragment>
      {assistant && assistant.id === props.assistantId && (
        <React.Fragment>
          <AssistantForm
            onSubmit={props.onSubmit}
            initialData={{
              ...assistant,
              routes: assistant.routes.map(({ id }) => id),
              password: '****',
              rawPassword: '',
            }}
            schema={schemaUpdate}
            name="Update tracker"
            additional={<BlockGroup assistant={assistant} onClose={props.onClose} />}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default AssistantDetail;
