import { ThunkAction } from 'redux-thunk';
import { IRoute, IListParams, IStore, IApi } from '../../types';
import { showError } from './error.actions';
import { startLoading, completeLoading } from './loading.actions';

import { IAddRoute } from '../../validation/addRoute';
import { showNotification } from './notification.actions';

export const FETCH_ROUTES_BEGIN = 'FETCH_ROUTES_BEGIN';

export const FETCH_ROUTES_SUCCESS = 'FETCH_ROUTES_SUCCESS';

export const CREATE_ROUTE_BEGIN = 'CREATE_ROUTE_BEGIN';

export const CREATE_ROUTE_SUCCESS = 'CREATE_ROUTE_SUCCESS';

export const FETCH_ROUTE_BEGIN = 'FETCH_ROUTE_BEGIN';

export const FETCH_ROUTE_SUCCESS = 'FETCH_ROUTE_SUCCESS';

export const UPDATE_ROUTE_BEGIN = 'UPDATE_ROUTE_BEGIN';

export const UPDATE_ROUTE_SUCCESS = 'UPDATE_ROUTE_SUCCESS';

export const IMPORT_ROUTES_BEGIN = 'IMPORT_ROUTES_BEGIN';

export const IMPORT_ROUTES_SUCCESS = 'IMPORT_ROUTES_SUCCESS';

interface IRoutesFetchBegin {
  type: typeof FETCH_ROUTES_BEGIN;
}

interface IRoutesFetchSuccess {
  type: typeof FETCH_ROUTES_SUCCESS;
  payload: {
    items: IRoute[];
    totalCount: number;
  };
}

interface IRouteCreateBegin {
  type: typeof CREATE_ROUTE_BEGIN;
}

interface IRouteCreateSuccess {
  type: typeof CREATE_ROUTE_SUCCESS;
  payload: IRoute;
}

interface IRouteFetchBegin {
  type: typeof FETCH_ROUTE_BEGIN;
}

interface IRouteFetchSuccess {
  type: typeof FETCH_ROUTE_SUCCESS;
  payload: IRoute;
}

interface IRouteUpdateBegin {
  type: typeof UPDATE_ROUTE_BEGIN;
}

interface IRouteUpdateSuccess {
  type: typeof UPDATE_ROUTE_SUCCESS;
  payload: IRoute;
}

interface IRouteImportBegin {
  type: typeof IMPORT_ROUTES_BEGIN;
}

interface IRouteImportSuccess {
  type: typeof IMPORT_ROUTES_SUCCESS;
  payload: IRoute[];
}

export type RouteActionTypes =
  | IRoutesFetchBegin
  | IRoutesFetchSuccess
  | IRouteCreateBegin
  | IRouteCreateSuccess
  | IRouteFetchBegin
  | IRouteFetchSuccess
  | IRouteUpdateBegin
  | IRouteUpdateSuccess
  | IRouteImportBegin
  | IRouteImportSuccess;

export const fetchRoutes = (params: IListParams): ThunkAction<void, IStore, { Api: IApi }, RouteActionTypes> => async (
  dispatch,
  getState,
  { Api },
) => {
  try {
    dispatch(startLoading());
    dispatch({ type: FETCH_ROUTES_BEGIN });
    const routes = await Api.RoutesService.setAccessToken(getState().auth.accessToken).fetchAll(params);
    return dispatch({
      type: FETCH_ROUTES_SUCCESS,
      payload: routes,
    });
  } catch (e) {
    dispatch(showError(e));
  } finally {
    dispatch(completeLoading());
  }
};

export const addRoute = (form: IAddRoute): ThunkAction<void, IStore, { Api: IApi }, RouteActionTypes> => async (
  dispatch,
  getState,
  { Api },
) => {
  try {
    dispatch(startLoading());
    dispatch({
      type: CREATE_ROUTE_BEGIN,
    });
    const route = await Api.RoutesService.setAccessToken(getState().auth.accessToken).addRoute(form);
    dispatch(showNotification('Saved successfully'));
    dispatch({
      type: CREATE_ROUTE_SUCCESS,
      payload: route,
    });
    return dispatch(fetchRoutes(getState().routes.list.params));
  } catch (e) {
    dispatch(showError(e));
  } finally {
    dispatch(completeLoading());
  }
};

export const fetchRouteById = (id: string): ThunkAction<void, IStore, { Api: IApi }, RouteActionTypes> => async (
  dispatch,
  getState,
  { Api },
) => {
  try {
    dispatch(startLoading());
    dispatch({ type: FETCH_ROUTE_BEGIN });
    const route = await Api.RoutesService.setAccessToken(getState().auth.accessToken).fetchRoute(id);
    return dispatch({
      type: FETCH_ROUTE_SUCCESS,
      payload: route,
    });
  } catch (e) {
    dispatch(showError(e));
  } finally {
    dispatch(completeLoading());
  }
};

export const updateRouteById = (
  id: string,
  form: IAddRoute,
): ThunkAction<void, IStore, { Api: IApi }, RouteActionTypes> => async (dispatch, getState, { Api }) => {
  try {
    dispatch(startLoading());
    dispatch({
      type: UPDATE_ROUTE_BEGIN,
    });
    const route = await Api.RoutesService.setAccessToken(getState().auth.accessToken).updateRoute(id, form);
    dispatch(showNotification('Updated successfully'));
    return dispatch({
      type: UPDATE_ROUTE_SUCCESS,
      payload: route,
    });
  } catch (e) {
    dispatch(showError(e));
  } finally {
    dispatch(completeLoading());
  }
};

export const importRoutes = (files: File[]): ThunkAction<void, IStore, { Api: IApi }, RouteActionTypes> => async (
  dispatch,
  getState,
  { Api },
) => {
  try {
    dispatch(startLoading());
    dispatch({
      type: IMPORT_ROUTES_BEGIN,
    });
    const routes = await Api.RoutesService.setAccessToken(getState().auth.accessToken).importRoute(files);
    dispatch({
      type: IMPORT_ROUTES_SUCCESS,
      payload: routes,
    });
    dispatch(showNotification('Imported successfully'));
    return dispatch(fetchRoutes(getState().routes.list.params));
  } catch (e) {
    dispatch(showError(e));
  } finally {
    dispatch(completeLoading());
  }
};
