import React from 'react';
import { Fab, Grid, TextField, Tooltip } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { useForm, IFormState } from '../../helpers/form';
import * as Actions from '../../store/actions';
import { ICheckUser, initialData, schemaCheck } from '../../validation/addUser';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    '& .MuiFormControl-root': {
      margin: theme.spacing(2),
      width: 400,
    },
  },
  container: {
    minWidth: 400,
    padding: theme.spacing(2),
  },
  modalHeader: {
    fontFamily: 'Muli',
    fontSize: '20px',
    lineHeight: 0.51,
    color: '#2d323e',
    marginBottom: '40px',
  },
  textField: {
    marginTop: 0,
    fontFamily: 'Muli',
    marginBottom: '26px',
  },
  upload: {
    cursor: 'pointer',
    textAlign: 'center',
    border: '1px dashed',
    width: '100%',
    padding: theme.spacing(2),
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    paddingTop: '16%',
    paddingBottom: '16%',
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

const buildId = (suffix: string) => {
  return `upload-routes-form-${suffix}`;
};

interface IProps {
  onSubmit: () => void;
}

export const InviteUserForm = (props: IProps) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const onSubmit = (form: IFormState<ICheckUser>) => {
    dispatch(Actions.checkUserUse({ ...form.values, username: form.values.username.trim() }));
  };
  const [formState, , handleChanges, handleSubmit] = useForm<ICheckUser>(initialData, schemaCheck, onSubmit);
  const { values, errors } = formState;

  return (
    <Grid container direction="column" justify="center" alignItems="flex-end">
      <Grid container direction="column" justify="center" alignItems="flex-start" className={classes.container}>
        <div className={classes.modalHeader} id={buildId('title')}>
          Create the invitation letter for a new Administrator
        </div>
        <Tooltip placement="left" title="Please enter the email of invited administrator" arrow>
          <TextField
            name="username"
            label="email"
            value={values.username}
            error={!!errors.username}
            helperText={errors.username}
            onChange={handleChanges}
            required
            variant="outlined"
          />
        </Tooltip>
      </Grid>
      <Grid container direction="row" justify="flex-end">
        <Grid item key={1}>
          <Fab
            variant="extended"
            color="primary"
            aria-label="add"
            className={classes.margin}
            onClick={(event: React.ChangeEvent<any>) => {
              handleSubmit(event);
              props.onSubmit();
            }}
          >
            <SendIcon className={classes.extendedIcon} />
            Send
          </Fab>
        </Grid>
      </Grid>
    </Grid>
  );
};
