import axios from 'axios';
import { config } from '../config';
import { IUser, IUserData, IListParams, IGetListAPIResponse, IUserPlainData } from '../types';
import { IAddUser, ICheckUser } from '../validation/addUser';

export default class UsersService {
  private apiBaseUrl: string;

  private headers: { [key: string]: string } = {};

  constructor() {
    this.apiBaseUrl = config.apiBaseUrl;
  }

  public setAccessToken(token: string): UsersService {
    this.headers['X-Session-Token'] = token;
    return this;
  }

  public async checkUserUse(data: ICheckUser): Promise<void> {
    await axios.request<{ data: ICheckUser }>({
      method: 'post',
      url: `${this.apiBaseUrl}/users/check-use`,
      headers: this.headers,
      data,
    });
  }

  public async addUser(data: IAddUser): Promise<IUser> {
    const response = await axios.request<{ data: IUser }>({
      method: 'post',
      url: `${this.apiBaseUrl}/users`,
      headers: this.headers,
      data,
    });
    const { data: newUser } = response;
    return newUser;
  }

  public async fetchAll(params: IListParams & { status?: string }) {
    const response = await axios.request<IGetListAPIResponse<IUser[]>>({
      params,
      method: 'get',
      url: `${this.apiBaseUrl}/users`,
      headers: this.headers,
    });
    const {
      data: items,
      headers: { 'x-total-count': totalCount },
    } = response;
    return { items, totalCount: Number.parseInt(totalCount) };
  }

  public async activateUser(id: string) {
    await axios.request<void>({
      method: 'post',
      url: `${this.apiBaseUrl}/users/${id}/activate`,
      headers: this.headers,
    });
  }

  public async getSelf() {
    const response = await axios.request<{
      data: IUserPlainData;
    }>({
      method: 'get',
      url: `${this.apiBaseUrl}/users/my`,
      headers: this.headers,
    });

    const { data } = response;
    return data;
  }

  public async getSelfDistricts() {
    const response = await axios.request<{
      data: IUserData;
    }>({
      method: 'get',
      url: `${this.apiBaseUrl}/districts/my`,
      headers: this.headers,
    });

    const { data } = response;
    return data;
  }

  public async syncDistricts(data: { username: string; password: string }) {
    const response = await axios.request<{ data: IUserData }>({
      method: 'post',
      url: `${this.apiBaseUrl}/districts/my/sync-with-therapy`,
      headers: this.headers,
      data,
    });

    return response.data;
  }

  public async setDistrict(id: string) {
    await axios.request<void>({
      method: 'post',
      url: `${this.apiBaseUrl}/districts/my/set-district-id/${id}`,
      headers: this.headers,
    });
  }

  public async setSelf(data: { firstName: string; lastName: string }) {
    const response = await axios.request<{ data: IUserPlainData }>({
      method: 'post',
      url: `${this.apiBaseUrl}/users/my`,
      headers: this.headers,
      data,
    });
    return response.data;
  }
}
