import { ThunkAction } from 'redux-thunk';
import { showError } from './error.actions';
import { showNotification } from './notification.actions';
import { startLoading, completeLoading } from './loading.actions';
import { IAddAssistant } from '../../validation/addAssistant';
import { IAssistant, IApi, IListParams, IStore } from '../../types';

export const FETCH_ASSISTANTS_BEGIN = 'FETCH_ASSISTANTS_BEGIN';

export const FETCH_ASSISTANTS_SUCCESS = 'FETCH_ASSISTANTS_SUCCESS';

export const CREATE_ASSISTANT_BEGIN = 'CREATE_ASSISTANT_BEGIN';

export const CREATE_ASSISTANT_SUCCESS = 'CREATE_ASSISTANT_SUCCESS';

export const FETCH_ASSISTANT_BEGIN = 'FETCH_ASSISTANT_BEGIN';

export const FETCH_ASSISTANT_SUCCESS = 'FETCH_ASSISTANT_SUCCESS';

export const UPDATE_ASSISTANT_BEGIN = 'UPDATE_ASSISTANT_BEGIN';

export const UPDATE_ASSISTANT_SUCCESS = 'UPDATE_ASSISTANT_SUCCESS';

export const DISABLE_ASSISTANT_BEGIN = 'DISABLE_ASSISTANT_BEGIN';

export const DISABLE_ASSISTANT_SUCCESS = 'DISABLE_ASSISTANT_SUCCESS';

export const ACTIVATE_ASSISTANT_BEGIN = 'ACTIVATE_ASSISTANT_BEGIN';

export const ACTIVATE_ASSISTANT_SUCCESS = 'ACTIVATE_ASSISTANT_SUCCESS';

export const DELETE_ASSISTANT_BEGIN = 'DELETE_ASSISTANT_BEGIN';

export const DELETE_ASSISTANT_SUCCESS = 'DELETE_ASSISTANT_SUCCESS';

interface IAssistantsFetchBegin {
  type: typeof FETCH_ASSISTANTS_BEGIN;
}

interface IAssistantsFetchSuccess {
  type: typeof FETCH_ASSISTANTS_SUCCESS;
  payload: {
    items: IAssistant[];
    totalCount: number;
  };
}

interface IAssistantCreateBegin {
  type: typeof CREATE_ASSISTANT_BEGIN;
}

interface IAssistantCreateSuccess {
  type: typeof CREATE_ASSISTANT_SUCCESS;
  payload: IAssistant;
}

interface IAssistantFetchBegin {
  type: typeof FETCH_ASSISTANT_BEGIN;
}

interface IAssistantFetchSuccess {
  type: typeof FETCH_ASSISTANT_SUCCESS;
  payload: IAssistant;
}

interface IAssistantUpdateBegin {
  type: typeof UPDATE_ASSISTANT_BEGIN;
}

interface IAssistantUpdateSuccess {
  type: typeof UPDATE_ASSISTANT_SUCCESS;
  payload: IAssistant;
}

interface IAssistantDisableBegin {
  type: typeof DISABLE_ASSISTANT_BEGIN;
}

interface IAssistantDisableSuccess {
  type: typeof DISABLE_ASSISTANT_SUCCESS;
  payload: IAssistant;
}

interface IAssistantActivateBegin {
  type: typeof ACTIVATE_ASSISTANT_BEGIN;
}

interface IAssistantActivateSuccess {
  type: typeof ACTIVATE_ASSISTANT_SUCCESS;
  payload: IAssistant;
}

interface IAssistantDeleteBegin {
  type: typeof DELETE_ASSISTANT_BEGIN;
}

interface IAssistantDeleteSuccess {
  type: typeof DELETE_ASSISTANT_SUCCESS;
  payload: { id: string };
}

export type AssistantActionTypes =
  | IAssistantsFetchBegin
  | IAssistantsFetchSuccess
  | IAssistantCreateBegin
  | IAssistantCreateSuccess
  | IAssistantFetchBegin
  | IAssistantFetchSuccess
  | IAssistantUpdateBegin
  | IAssistantUpdateSuccess
  | IAssistantDisableBegin
  | IAssistantDisableSuccess
  | IAssistantActivateBegin
  | IAssistantActivateSuccess
  | IAssistantDeleteBegin
  | IAssistantDeleteSuccess;

export const fetchAssistants = (
  params: IListParams,
): ThunkAction<void, IStore, { Api: IApi }, AssistantActionTypes> => async (dispatch, getState, { Api }) => {
  try {
    dispatch(startLoading());
    dispatch({ type: FETCH_ASSISTANTS_BEGIN });
    const assitants = await Api.AssitantsService.setAccessToken(getState().auth.accessToken).fetchAll(params);
    return dispatch({
      type: FETCH_ASSISTANTS_SUCCESS,
      payload: assitants,
    });
  } catch (e) {
    dispatch(showError(e));
  } finally {
    dispatch(completeLoading());
  }
};

export const addAssistant = (
  form: IAddAssistant,
): ThunkAction<void, IStore, { Api: IApi }, AssistantActionTypes> => async (dispatch, getState, { Api }) => {
  try {
    dispatch(startLoading());
    dispatch({
      type: CREATE_ASSISTANT_BEGIN,
    });
    const assistant = await Api.AssitantsService.setAccessToken(getState().auth.accessToken).addAssistant(form);
    dispatch({
      type: CREATE_ASSISTANT_SUCCESS,
      payload: assistant,
    });
    dispatch(showNotification('Saved successfully'));
    return dispatch(fetchAssistants(getState().assistants.list.params));
  } catch (e) {
    dispatch(showError(e));
  } finally {
    dispatch(completeLoading());
  }
};

export const fetchAssistantById = (
  id: string,
): ThunkAction<void, IStore, { Api: IApi }, AssistantActionTypes> => async (dispatch, getState, { Api }) => {
  try {
    dispatch(startLoading());
    dispatch({ type: FETCH_ASSISTANT_BEGIN });
    const assitant = await Api.AssitantsService.setAccessToken(getState().auth.accessToken).fetchAssistant(id);
    return dispatch({
      type: FETCH_ASSISTANT_SUCCESS,
      payload: assitant,
    });
  } catch (e) {
    dispatch(showError(e));
  } finally {
    dispatch(completeLoading());
  }
};

export const updateAssistantById = (
  id: string,
  form: IAddAssistant,
): ThunkAction<void, IStore, { Api: IApi }, AssistantActionTypes> => async (dispatch, getState, { Api }) => {
  try {
    dispatch(startLoading());
    dispatch({
      type: UPDATE_ASSISTANT_BEGIN,
    });
    if (!form.password) delete form.password;
    const assistant = await Api.AssitantsService.setAccessToken(getState().auth.accessToken).updateAssistant(id, form);
    dispatch(showNotification('Updated successfully'));
    return dispatch({
      type: UPDATE_ASSISTANT_SUCCESS,
      payload: assistant,
    });
  } catch (e) {
    dispatch(showError(e));
  } finally {
    dispatch(completeLoading());
  }
};

export const disableAssistantById = (
  id: string,
): ThunkAction<void, IStore, { Api: IApi }, AssistantActionTypes> => async (dispatch, getState, { Api }) => {
  try {
    dispatch(startLoading());
    dispatch({
      type: DISABLE_ASSISTANT_BEGIN,
    });
    const assistant = await Api.AssitantsService.setAccessToken(getState().auth.accessToken).disableAssistant(id);
    return dispatch({
      type: DISABLE_ASSISTANT_SUCCESS,
      payload: assistant,
    });
  } catch (e) {
    dispatch(showError(e));
  } finally {
    dispatch(completeLoading());
  }
};

export const activateAssistantById = (
  id: string,
): ThunkAction<void, IStore, { Api: IApi }, AssistantActionTypes> => async (dispatch, getState, { Api }) => {
  try {
    dispatch(startLoading());
    dispatch({
      type: ACTIVATE_ASSISTANT_BEGIN,
    });
    const assistant = await Api.AssitantsService.setAccessToken(getState().auth.accessToken).activateAssistant(id);
    return dispatch({
      type: ACTIVATE_ASSISTANT_SUCCESS,
      payload: assistant,
    });
  } catch (e) {
    dispatch(showError(e));
  } finally {
    dispatch(completeLoading());
  }
};

export const deleteAssistantById = (
  id: string,
): ThunkAction<void, IStore, { Api: IApi }, AssistantActionTypes> => async (dispatch, getState, { Api }) => {
  try {
    dispatch(startLoading());
    dispatch({
      type: DELETE_ASSISTANT_BEGIN,
    });
    await Api.AssitantsService.setAccessToken(getState().auth.accessToken).deleteAssistant(id);
    dispatch({
      type: DELETE_ASSISTANT_SUCCESS,
      payload: { id },
    });
    dispatch(showNotification('Tracker successfully removed'));
    return dispatch(fetchAssistants(getState().assistants.list.params));
  } catch (e) {
    dispatch(showError(e));
  } finally {
    dispatch(completeLoading());
  }
};
