import * as Yup from 'yup';

export interface ICheckUser {
  username: string;
}

export interface IAddUser extends ICheckUser {
  password: string;
  passwordConfirmation: string;
  firstName: string;
  lastName: string;
}

export interface IUpdateUser {
  username: string;
  firstName: string;
  lastName: string;
}

export const schemaCheck = Yup.object().shape({
  username: Yup.string()
    .email()
    .transform(function (value) {
      return value.trim();
    })
    .required(),
});

export const schemaAddUser = Yup.object().shape({
  username: Yup.string().email().required(),
  password: Yup.string().required(),
  firstName: Yup.string()
    .label('First name')
    .matches(/^[a-zA-Z]+$/, 'First name must contain alphabetic characters.')
    .required(),
  lastName: Yup.string()
    .label('Last name')
    .matches(/^[a-zA-Z]+$/, 'First name must contain alphabetic characters.')
    .required(),
  passwordConfirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
});

export const schemaUpdateUser = Yup.object().shape({
  username: Yup.string().email(),
  firstName: Yup.string()
    .label('First name')
    .matches(/^[a-zA-Z]+$/, 'First name must contain alphabetic characters.'),
  lastName: Yup.string()
    .label('Last name')
    .matches(/^[a-zA-Z]+$/, 'First name must contain alphabetic characters.'),
});

export const initialData: ICheckUser = {
  username: '',
};
