import * as Yup from 'yup';
import { ISignInForm } from '../types';

export const schema = Yup.object().shape({
  username: Yup.string().required(),
  password: Yup.string().required(),
});

export const initialData: ISignInForm = {
  username: '',
  password: '',
};
