import * as Actions from '../actions';
import { IStudent, IListParams } from '../../types';

interface IInitialState {
  item: IStudent | null;
  list: {
    items: IStudent[];
    params: IListParams;
    totalCount: number;
  };
}

const initialState: IInitialState = {
  item: null,
  list: {
    items: [],
    params: {
      limit: 100,
      offset: 0,
    },
    totalCount: 0,
  },
};

const routes = (state = initialState, action: Actions.StudentActionTypes | Actions.ErrorActionTypes) => {
  switch (action.type) {
    case Actions.FETCH_STUDENTS_BEGIN: {
      return {
        ...state,
        list: {
          ...initialState.list,
          items: [],
        },
      };
    }
    case Actions.FETCH_STUDENTS_SUCCESS: {
      const { items, totalCount } = action.payload;
      return {
        ...state,
        list: { ...state.list, items, totalCount },
      };
    }
    case Actions.CREATE_STUDENT_SUCCESS: {
      const newStudent = action.payload;
      return {
        ...state,
        list: { ...state.list, items: [...state.list.items, newStudent] },
      };
    }
    case Actions.FETCH_STUDENT_BEGIN: {
      return {
        ...state,
        item: null,
      };
    }
    case Actions.FETCH_STUDENT_SUCCESS:
    case Actions.UPDATE_STUDENT_SUCCESS: {
      const student = action.payload;
      return {
        ...state,
        item: student,
        list: {
          ...state.list,
          items: state.list.items.map((i) => (i.id === student.id ? student : i)),
        },
      };
    }
    case '@@router/LOCATION_CHANGE':
    default: {
      return state;
    }
  }
};

export default routes;
