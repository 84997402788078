import { ThunkAction } from 'redux-thunk';
import { ISchool, IListParams, IStore, IApi } from '../../types';
import { showError } from './error.actions';
import { showNotification } from './notification.actions';
import { startLoading, completeLoading } from './loading.actions';

import { IAddSchool } from '../../validation/addSchool';

export const FETCH_SCHOOLS_SUCCESS = 'FETCH_SCHOOLS_SUCCESS';

export const CREATE_SCHOOL_BEGIN = 'CREATE_SCHOOL_BEGIN';

export const CREATE_SCHOOL_SUCCESS = 'CREATE_SCHOOL_SUCCESS';

export const FETCH_SCHOOL_SUCCESS = 'FETCH_SCHOOL_SUCCESS';

export const UPDATE_SCHOOL_BEGIN = 'UPDATE_SCHOOL_BEGIN';

export const UPDATE_SCHOOL_SUCCESS = 'UPDATE_SCHOOL_SUCCESS';

interface ISchoolsFetchSuccess {
  type: typeof FETCH_SCHOOLS_SUCCESS;
  payload: {
    items: ISchool[];
    totalCount: number;
  };
}

interface ISchoolCreateBegin {
  type: typeof CREATE_SCHOOL_BEGIN;
}

interface ISchoolCreateSuccess {
  type: typeof CREATE_SCHOOL_SUCCESS;
  payload: ISchool;
}

interface ISchoolFetchSuccess {
  type: typeof FETCH_SCHOOL_SUCCESS;
  payload: ISchool;
}

interface ISchoolUpdateBegin {
  type: typeof UPDATE_SCHOOL_BEGIN;
}

interface ISchoolUpdateSuccess {
  type: typeof UPDATE_SCHOOL_SUCCESS;
  payload: ISchool;
}

export type SchoolActionTypes =
  | ISchoolsFetchSuccess
  | ISchoolCreateBegin
  | ISchoolCreateSuccess
  | ISchoolFetchSuccess
  | ISchoolUpdateBegin
  | ISchoolUpdateSuccess;

export const fetchSchools = (
  params: IListParams,
): ThunkAction<void, IStore, { Api: IApi }, SchoolActionTypes> => async (dispatch, getState, { Api }) => {
  try {
    dispatch(startLoading());
    const schools = await Api.SchoolsService.setAccessToken(getState().auth.accessToken).fetchAll(params);
    return dispatch({
      type: FETCH_SCHOOLS_SUCCESS,
      payload: schools,
    });
  } catch (e) {
    dispatch(showError(e));
  } finally {
    dispatch(completeLoading());
  }
};

export const addSchool = (form: IAddSchool): ThunkAction<void, IStore, { Api: IApi }, SchoolActionTypes> => async (
  dispatch,
  getState,
  { Api },
) => {
  try {
    dispatch(startLoading());
    dispatch({
      type: CREATE_SCHOOL_BEGIN,
    });
    const school = await Api.SchoolsService.setAccessToken(getState().auth.accessToken).addSchool(form);
    dispatch({
      type: CREATE_SCHOOL_SUCCESS,
      payload: school,
    });
    dispatch(showNotification('Saved successfully'));
    return dispatch(fetchSchools(getState().schools.list.params));
  } catch (e) {
    dispatch(showError(e));
  } finally {
    dispatch(completeLoading());
  }
};

export const fetchSchoolById = (id: string): ThunkAction<void, IStore, { Api: IApi }, SchoolActionTypes> => async (
  dispatch,
  getState,
  { Api },
) => {
  try {
    dispatch(startLoading());
    const school = await Api.SchoolsService.setAccessToken(getState().auth.accessToken).fetchSchool(id);
    return dispatch({
      type: FETCH_SCHOOL_SUCCESS,
      payload: school,
    });
  } catch (e) {
    dispatch(showError(e));
  } finally {
    dispatch(completeLoading());
  }
};

export const updateSchoolById = (
  id: string,
  form: IAddSchool,
): ThunkAction<void, IStore, { Api: IApi }, SchoolActionTypes> => async (dispatch, getState, { Api }) => {
  try {
    dispatch(startLoading());
    dispatch({
      type: UPDATE_SCHOOL_BEGIN,
    });
    const school = await Api.SchoolsService.setAccessToken(getState().auth.accessToken).updateSchool(id, form);
    dispatch(showNotification('Updated successfully'));
    return dispatch({
      type: UPDATE_SCHOOL_SUCCESS,
      payload: school,
    });
  } catch (e) {
    dispatch(showError(e));
  } finally {
    dispatch(completeLoading());
  }
};
