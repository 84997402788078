/* eslint-disable @typescript-eslint/no-explicit-any */
import { Schema } from 'yup';
import { get, set } from 'lodash';

export function makeValidate<T>(validator: Schema<T>) {
  return (values: T) => {
    try {
      validator.validateSync(values, { abortEarly: false });
    } catch (err) {
      return err.inner.reduce((errors: any, { path, message }: any) => {
        if (errors.hasOwnProperty(path)) {
          set(errors, path, get(errors, path) + ' ' + message);
        } else {
          set(errors, path, message);
        }
        return errors;
      }, {});
    }
  };
}
