import { ThunkAction } from 'redux-thunk';
import { INotification, IStore } from '../../types';
import { IApi } from '../../types';

export const SHOW_NOTIFICATION = 'WSHOW_NOTIFICATION';
export const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION';

interface IHideNotification {
  type: typeof HIDE_NOTIFICATION;
}
interface IShowNotification {
  type: typeof SHOW_NOTIFICATION;
  payload: INotification;
}

export type NotificationActionTypes = IHideNotification | IShowNotification;

export const hideNotification = (): ThunkAction<void, IStore, { Api: IApi }, NotificationActionTypes> => async (
  dispatch,
) => {
  return dispatch({
    type: HIDE_NOTIFICATION,
  });
};

export const showNotification = (
  message: string,
  type?: INotification['type'],
): ThunkAction<void, IStore, { Api: IApi }, NotificationActionTypes> => async (dispatch) => {
  return dispatch({
    type: SHOW_NOTIFICATION,
    payload: {
      message,
      type,
    },
  });
};
