import React from 'react';
import { AccountCircle } from '@material-ui/icons';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Grid, TextField, InputAdornment, Button } from '@material-ui/core';
import { useForm, IFormState } from '../helpers/form';
import { IForgotPasswordForm } from '../types';
import { initialData, schema } from '../validation/forgotPassword';
import ErrorSnackbar from './ErrorSnackbar';

const buildId = (suffix: string) => {
  return `forgot-password-form-${suffix}`;
};

const useStyles = makeStyles(() =>
  createStyles({
    username: {
      marginTop: 0,
      fontFamily: 'Muli',
      marginBottom: '26px',
    },
  }),
);

interface IProps {
  onSubmit: (form: IFormState<IForgotPasswordForm>) => void;
}

function ForgotPasswordForm(props: IProps) {
  const classes = useStyles();

  const [formState, , handleChanges, handleSubmit] = useForm<IForgotPasswordForm>(initialData, schema, props.onSubmit);
  const { values, errors } = formState;

  return (
    <Grid item xs={11}>
      <TextField
        required
        id={buildId('username')}
        label="Username"
        defaultValue="User name"
        variant="outlined"
        className={classes.username}
        autoComplete="admin-login"
        name="username"
        value={values.username}
        error={!!errors.username}
        helperText={errors.username}
        onChange={handleChanges}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <AccountCircle />
            </InputAdornment>
          ),
        }}
      />
      <ErrorSnackbar />
      <Button variant="contained" color="primary" id={buildId('submit-button')} onClick={handleSubmit}>
        SEND
      </Button>
    </Grid>
  );
}

export default ForgotPasswordForm;
