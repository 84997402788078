import Api from '../services';

export interface IProgress {
  show: boolean;
  message?: string;
}

export interface IStore {
  navigation: INavigation;
  auth: IAuth;
  error: IError;
  loading: boolean;
  progress: IProgress;
  notification: INotification;
  appSettings: IAppSettings;
  schools: {
    item: ISchool;
    list: {
      items: ISchool[];
      params: IListParams;
      totalCount: number;
    };
  };
  assistants: {
    item: IAssistant;
    list: {
      items: IAssistant[];
      params: IListParams;
      totalCount: number;
    };
  };
  routes: {
    item: IRoute;
    list: {
      items: IRoute[];
      params: IListParams;
      totalCount: number;
    };
  };
  students: {
    item: IStudent;
    list: {
      items: IStudent[];
      params: IListParams;
      totalCount: number;
    };
  };
  trips: {
    list: {
      items: ITrip[];
      params: IListParams;
      totalCount: number;
    };
    currentSignature: string;
  };
  users: {
    send: boolean;
    list: {
      items: IUser[];
      params: IListParams;
      totalCount: number;
    };
  };
}

export interface IGetListAPIResponse<T> {
  data: T;
  headers: {
    'x-total-count': string;
  };
}

export interface IListParams {
  limit: number;
  offset: number;
}
export interface ISchool {
  id: string;
  name: string;
  address: string;
  contactInfo: string;
  routes: [];
  externalId: number | null;
}

export interface IAssistant {
  id: string;
  firstName: string;
  lastName: string;
  mobilePhone: string;
  password?: string;
  routes: IRoute[];
  status: 'active' | 'blocked';
  externalId: number | null;
  internal: boolean;
}

export enum RouteDirection {
  both = 'both',
  toSchool = 'toSchool',
  fromSchool = 'fromSchool',
}

export enum RouteDirectionTitle {
  both = 'Both',
  toSchool = 'To School',
  fromSchool = 'From School',
}

export interface IRoute {
  id: string;
  title: string;
  direction: RouteDirection;
  facilityId: string | null;
  weekDays?: {
    [key: string]: boolean;
  };
  externalId: number | null;
  facility?: ISchool;
  students: IStudent[];
}

export interface IStudent {
  id: string;
  firstName: string;
  lastName: string;
  pickUpNotes: string;
  routes: Array<{
    id: IRoute['id'];
    title: IRoute['title'];
  }>;
  weekDays?: {
    [key: string]: boolean;
  };
  externalId: number | null;
}

export interface ITrip {
  id: string;
  childName: string;
  routeTitle: IRoute['id'];
  checkedAt: Date;
  tripId: string;
  facilityName: string;
  direction: RouteDirection;
  assistantName: string;
  coordinates: number[];
  track: number[][];
}

export interface IUser {
  id: string;
  email: string;
  password?: string;
  firstName: string;
  lastName: string;
  status: string;
}

export interface ISignInForm {
  username: string;
  password: string;
}

export interface IForgotPasswordForm {
  username: string;
}

export interface ISetPasswordForm {
  code: string;
  password: string;
  passwordConfirm: string;
}

export interface IAppSettings {
  isDrawerOpen: boolean;
  isActivityOpen: boolean;
  isCalendarOpen: boolean;
  subheader: string;
  activity: boolean;
  startDate: Date;
  endDate: Date;
}

export interface INotification {
  message: string;
  type?: 'success' | 'warning' | 'error' | 'info';
}

export type IApi = typeof Api;

export interface INavigationItem {
  title: string;
  type: NavigationItemType;
  icon: string;
  active: boolean;
  url?: string;
}

export enum NavigationItemType {
  Group,
  Item,
}

export interface INavigation extends Array<INavigationItem> {}

export interface IUserDistrict {
  hasToken: boolean;
  id: string;
  name: string;
}

export interface IUserPlainData {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
}

export interface IUserData extends IUserPlainData {
  currentDistrictId: string | null;
  currentDistrict: {
    id: string | null;
    name: string;
  };
  districts: IUserDistrict[];
}

export interface IAuth {
  accessToken: string;
  user: IUserData;
  send: boolean;
}

export interface IAuthToken {
  authToken: string;
}

export interface IError {
  message: string;
}

export interface ICreateTrip {
  id: string;
  startDate: Date;
  finishDate: Date;
  routeId: string;
  assistantId: string;
  direction: string;
  deviceId: string;
  passengers: {
    childId: string;
    checkedAt: Date;
    firstName: string;
    lastName: string;
  }[];
  signature: string;
}

export interface IPoint {
  lat: number;
  lng: number;
}

export interface IMarker extends IPoint {
  text: string;
}
