import * as Yup from 'yup';
import { ISetPasswordForm } from '../types';

export const schema = Yup.object().shape({
  code: Yup.string().required(),
  password: Yup.string().required(),
  passwordConfirm: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
});

export const initialData: ISetPasswordForm = {
  code: '',
  password: '',
  passwordConfirm: '',
};
