import { ThunkAction } from 'redux-thunk';
import { IStore, IAuth, IApi, IAuthToken, IUserData, IUserPlainData } from '../../types';
import { showError } from './error.actions';
import { startLoading, completeLoading } from './loading.actions';
import { showNotification } from './notification.actions';

export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT_ERROR = 'LOGOUT_ERROR';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const RESTORE_PASSWORD_BEGIN = 'RESTORE_PASSWORD_BEGIN';
export const RESTORE_PASSWORD_SUCCESS = 'RESTORE_PASSWORD_SUCCESS';
export const SET_PASSWORD_BEGIN = 'SET_PASSWORD_BEGIN';
export const SET_PASSWORD_SUCCESS = 'SET_PASSWORD_SUCCESS';
export const AUTH_SET_DATA = 'AUTH_SET_DATA';
export const AUTH_SET_TOKEN = 'AUTH_SET_TOKEN';
export const REQUEST_AUTH_CONFIRMATION_SUCCESS = 'user:requestAuthorization';
export const AUTH_CONFIRMED = 'user:signInConfirmed';
export const FETCH_SELF_DISTRICTS_SUCCESS = 'FETCH_SELF_DISTRICTS_SUCCESS';
export const FETCH_SELF_DATA_SUCCESS = 'FETCH_SELF_DATA_SUCCESS';
export const UPDATE_SELF_DATA_BEGIN = 'UPDATE_SELF_DATA_BEGIN';
export const UPDATE_SELF_DATA_SUCCESS = 'SET_SELF_DATA_SUCCESS';
export const SYNC_SELF_DISTRICTS_BEGIN = 'SYNC_SELF_DISTRICTS_BEGIN';
export const SYNC_SELF_DISTRICTS_SUCCESS = 'SYNC_SELF_DISTRICTS_SUCCESS';
export const SET_DISTRICT_SUCCESS = 'SET_DISTRICT_SUCCESS';

interface ILoginError {
  type: typeof LOGIN_ERROR;
  error: Error;
}

interface ILoginSuccess {
  type: typeof LOGIN_SUCCESS;
}

interface ILogoutError {
  type: typeof LOGOUT_ERROR;
  error: Error;
}

export interface ILogoutSuccess {
  type: typeof LOGOUT_SUCCESS;
}

interface IAuthSetData {
  type: typeof AUTH_SET_DATA;
  payload: IAuth;
}

interface IAuthSetToken {
  type: typeof AUTH_SET_TOKEN;
  payload: { accessToken: string };
}

interface IUserFetchSelf {
  type: typeof FETCH_SELF_DATA_SUCCESS;
  payload: IUserPlainData;
}

interface IUserUpdateSelfBegin {
  type: typeof UPDATE_SELF_DATA_BEGIN;
}

interface IUserUpdateSelfSuccess {
  type: typeof UPDATE_SELF_DATA_SUCCESS;
  payload: IUserPlainData;
}

interface IRequestAuthConfirmationSuccess {
  type: typeof REQUEST_AUTH_CONFIRMATION_SUCCESS;
  payload: { connectionUid: string };
}

interface IAuthConfirmed {
  type: typeof AUTH_CONFIRMED;
  payload: IAuth;
}

interface IUserFetchSelfDistrictsSuccess {
  type: typeof FETCH_SELF_DISTRICTS_SUCCESS;
  payload: IAuth['user'];
}

interface IUserSyncSelfDistrictsBegin {
  type: typeof SYNC_SELF_DISTRICTS_BEGIN;
}

interface IUserSyncSelfDistrictsSuccess {
  type: typeof SYNC_SELF_DISTRICTS_SUCCESS;
  payload: IUserData;
}

interface IUserSetDistrictSuccess {
  type: typeof SET_DISTRICT_SUCCESS;
  payload: string;
}

interface IUserRestorePasswordBegin {
  type: typeof RESTORE_PASSWORD_BEGIN;
}

interface IUserRestorePasswordSuccess {
  type: typeof RESTORE_PASSWORD_SUCCESS;
}

interface IUserSetPasswordBegin {
  type: typeof SET_PASSWORD_BEGIN;
}

interface IUserSetPasswordSuccess {
  type: typeof SET_PASSWORD_SUCCESS;
}

export type AuthActionTypes =
  | ILoginSuccess
  | ILoginError
  | ILogoutError
  | ILogoutSuccess
  | IAuthConfirmed
  | IAuthSetData
  | IAuthSetToken
  | IRequestAuthConfirmationSuccess
  | IUserFetchSelfDistrictsSuccess
  | IUserFetchSelf
  | IUserUpdateSelfBegin
  | IUserUpdateSelfSuccess
  | IUserSyncSelfDistrictsBegin
  | IUserSyncSelfDistrictsSuccess
  | IUserSetDistrictSuccess
  | IUserRestorePasswordBegin
  | IUserRestorePasswordSuccess
  | IUserSetPasswordBegin
  | IUserSetPasswordSuccess;

export const setAuthorizationToken = (
  auth: IAuthToken,
): ThunkAction<void, IStore, { Api: IApi }, AuthActionTypes> => async (dispatch) => {
  const payload = {
    accessToken: auth.authToken,
    user: {},
  };
  dispatch({
    type: AUTH_SET_TOKEN,
    payload,
  });
};

export const unsetAuthorizationToken = (): ThunkAction<void, IStore, { Api: IApi }, AuthActionTypes> => async (
  dispatch,
) => {
  const payload = {
    accessToken: '',
    user: {},
  };
  dispatch({
    type: AUTH_SET_TOKEN,
    payload,
  });
};

export const submitSignIn = (
  username: string,
  password: string,
): ThunkAction<void, IStore, { Api: IApi }, AuthActionTypes> => async (dispatch, getStore, { Api }) => {
  try {
    dispatch(startLoading());
    const user = await Api.AuthService.sigIn(username, password);
    dispatch(setAuthorizationToken(user));
    return dispatch({
      type: LOGIN_SUCCESS,
    });
  } catch (e) {
    dispatch(showError(e));
    return dispatch({
      type: LOGIN_ERROR,
      error: e.message,
    });
  } finally {
    dispatch(completeLoading());
  }
};

export const getSelfDistrictsData = (): ThunkAction<void, IStore, { Api: IApi }, AuthActionTypes> => async (
  dispatch,
  getState,
  { Api },
) => {
  try {
    dispatch(startLoading());

    const selfDistricts = await Api.UserService.setAccessToken(getState().auth.accessToken).getSelfDistricts();

    if (selfDistricts.districts && selfDistricts.districts.length && !getState().auth.user.currentDistrict.id) {
      const [{ id }] = selfDistricts.districts;
      dispatch({
        type: FETCH_SELF_DISTRICTS_SUCCESS,
        payload: selfDistricts,
      });
      await Api.UserService.setAccessToken(getState().auth.accessToken).setDistrict(id);

      return dispatch({
        type: SET_DISTRICT_SUCCESS,
        payload: id,
      });
    }

    return dispatch({
      type: FETCH_SELF_DISTRICTS_SUCCESS,
      payload: selfDistricts,
    });
  } catch (e) {
    if (e.response && e.response.status === 401) {
      return;
    }
    dispatch(showError(e));
  } finally {
    dispatch(completeLoading());
  }
};

export const getSelfUserData = (): ThunkAction<void, IStore, { Api: IApi }, AuthActionTypes> => async (
  dispatch,
  getState,
  { Api },
) => {
  try {
    dispatch(startLoading());

    const self = await Api.UserService.setAccessToken(getState().auth.accessToken).getSelf();

    return dispatch({
      type: FETCH_SELF_DATA_SUCCESS,
      payload: self,
    });
  } catch (e) {
    dispatch(showError(e));
  } finally {
    dispatch(completeLoading());
  }
};

export const syncSelfDistricts = (form: {
  username: string;
  password: string;
}): ThunkAction<void, IStore, { Api: IApi }, AuthActionTypes> => async (dispatch, getState, { Api }) => {
  try {
    dispatch(startLoading());
    dispatch({
      type: SYNC_SELF_DISTRICTS_BEGIN,
    });
    const userData = await Api.UserService.setAccessToken(getState().auth.accessToken).syncDistricts(form);
    dispatch({
      type: SYNC_SELF_DISTRICTS_SUCCESS,
      payload: userData,
    });

    const [{ id }] = userData.districts || [];
    await Api.UserService.setAccessToken(getState().auth.accessToken).setDistrict(id);

    dispatch(showNotification('Synchronization with Therapylog is successfully completed'));
    return dispatch({
      type: SET_DISTRICT_SUCCESS,
      payload: id,
    });
  } catch (e) {
    dispatch(showError(e));
  } finally {
    dispatch(completeLoading());
  }
};

export const setCurrentDistrict = (
  districtId: string,
): ThunkAction<void, IStore, { Api: IApi }, AuthActionTypes> => async (dispatch, getState, { Api }) => {
  try {
    dispatch(startLoading());
    await Api.UserService.setAccessToken(getState().auth.accessToken).setDistrict(districtId);

    dispatch({
      type: SET_DISTRICT_SUCCESS,
      payload: districtId,
    });
    window.location.reload();
  } catch (e) {
    dispatch(showError(e));
  } finally {
    dispatch(completeLoading());
  }
};

export const updateSelf = (data: {
  firstName: string;
  lastName: string;
}): ThunkAction<void, IStore, { Api: IApi }, AuthActionTypes> => async (dispatch, getState, { Api }) => {
  try {
    dispatch(startLoading());
    dispatch({
      type: UPDATE_SELF_DATA_BEGIN,
    });
    const user = await Api.UserService.setAccessToken(getState().auth.accessToken).setSelf(data);
    dispatch(showNotification('Updated successfully'));
    return dispatch({
      type: UPDATE_SELF_DATA_SUCCESS,
      payload: user,
    });
  } catch (e) {
    dispatch(showError(e));
  } finally {
    dispatch(completeLoading());
  }
};

export const restoreAccess = (email: string): ThunkAction<void, IStore, { Api: IApi }, AuthActionTypes> => async (
  dispatch,
  getState,
  { Api },
) => {
  try {
    dispatch(startLoading());
    dispatch({
      type: RESTORE_PASSWORD_BEGIN,
    });
    await Api.AuthService.restoreAccess(email);
    return dispatch({
      type: RESTORE_PASSWORD_SUCCESS,
    });
  } catch (e) {
    dispatch(showError(e));
  } finally {
    dispatch(completeLoading());
  }
};

export const setPassword = (
  token: string,
  password: string,
): ThunkAction<void, IStore, { Api: IApi }, AuthActionTypes> => async (dispatch, getState, { Api }) => {
  try {
    dispatch(startLoading());
    dispatch({
      type: SET_PASSWORD_BEGIN,
    });
    const user = await Api.AuthService.setPassword(token, password);
    dispatch(setAuthorizationToken(user));
    return dispatch({
      type: LOGIN_SUCCESS,
    });
  } catch (e) {
    dispatch(showError(e));
  } finally {
    dispatch(completeLoading());
  }
};
