import * as Actions from '../actions';

const initialState = false;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const loading = (state = initialState, action: Actions.LoadingActionTypes | any) => {
  switch (action.type) {
    case Actions.LOADING_STARTED: {
      return true;
    }
    case Actions.LOADING_COMPLETED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

export default loading;
