import * as Actions from '../actions';
import { IRoute, IListParams } from '../../types';

interface IInitialState {
  item: IRoute | null;
  list: {
    items: IRoute[];
    params: IListParams;
    totalCount: number;
  };
}

const initialState: IInitialState = {
  item: null,
  list: {
    items: [],
    params: {
      limit: 100,
      offset: 0,
    },
    totalCount: 0,
  },
};

const routes = (state = initialState, action: Actions.RouteActionTypes | Actions.ErrorActionTypes) => {
  switch (action.type) {
    case Actions.FETCH_ROUTES_BEGIN: {
      return {
        ...state,
        list: {
          ...initialState.list,
          items: [],
        },
      };
    }
    case Actions.FETCH_ROUTES_SUCCESS: {
      const { items, totalCount } = action.payload;
      return {
        ...state,
        list: { ...state.list, items, totalCount },
      };
    }
    case Actions.CREATE_ROUTE_SUCCESS: {
      const newRoute = action.payload;
      return {
        ...state,
        list: { ...state.list, items: [...state.list.items, newRoute] },
      };
    }
    case Actions.FETCH_ROUTE_BEGIN: {
      return {
        ...state,
        item: null,
      };
    }
    case Actions.FETCH_ROUTE_SUCCESS:
    case Actions.UPDATE_ROUTE_SUCCESS: {
      const route = action.payload;
      return {
        ...state,
        item: route,
        list: {
          ...state.list,
          items: state.list.items.map((i) => (i.id === route.id ? route : i)),
        },
      };
    }
    case Actions.IMPORT_ROUTES_SUCCESS: {
      return {
        ...state,
        list: initialState.list,
      };
    }
    case '@@router/LOCATION_CHANGE':
    default: {
      return state;
    }
  }
};

export default routes;
