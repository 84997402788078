import * as Actions from '../actions';
import { IAuth, IUserDistrict } from '../../types';

const initialState: IAuth = {
  accessToken: '',
  user: {
    districts: [],
    currentDistrictId: null,
    id: '',
    email: '',
    firstName: '',
    lastName: '',
    currentDistrict: {
      id: null,
      name: '',
    },
  },
  send: false,
};

const getCurrentDistrict = (districts: IUserDistrict[], currentDistrictId: string | null) => {
  const district = districts.find(({ id }) => id && id === currentDistrictId);
  return {
    id: currentDistrictId,
    name: district?.name || '',
  };
};

const auth = (state: IAuth = initialState, action: Actions.AuthActionTypes) => {
  switch (action.type) {
    case Actions.LOGIN_SUCCESS: {
      return state;
    }
    case Actions.LOGIN_ERROR: {
      return {
        error: action.error,
        ...state,
      };
    }
    case Actions.LOGOUT_SUCCESS: {
      return initialState;
    }
    case Actions.LOGOUT_ERROR: {
      return {
        error: action.error,
        ...state,
      };
    }
    case Actions.AUTH_CONFIRMED:
    case Actions.AUTH_SET_TOKEN:
    case Actions.AUTH_SET_DATA: {
      return {
        ...state,
        ...action.payload,
        user: {
          ...state.user,
          ...action.payload,
        },
      };
    }
    case Actions.FETCH_SELF_DISTRICTS_SUCCESS:
    case Actions.SYNC_SELF_DISTRICTS_SUCCESS: {
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
          currentDistrict: getCurrentDistrict(state.user.districts, action.payload.currentDistrictId),
          currentDistrictId: action.payload.currentDistrictId,
        },
      };
    }
    case Actions.SET_DISTRICT_SUCCESS: {
      return {
        ...state,
        user: {
          ...state.user,
          currentDistrict: getCurrentDistrict(state.user.districts, action.payload),
          currentDistrictId: action.payload,
        },
      };
    }
    case Actions.FETCH_SELF_DATA_SUCCESS:
    case Actions.UPDATE_SELF_DATA_SUCCESS: {
      return {
        ...state,
        user: {
          ...state.user,
          id: action.payload.id,
          email: action.payload.email,
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
        },
      };
    }
    case Actions.RESTORE_PASSWORD_SUCCESS: {
      return {
        ...state,
        send: true,
      };
    }
    default: {
      return state;
    }
  }
};

export default auth;
