import React, { FunctionComponent } from 'react';
import Button, { ButtonProps } from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

interface IProps {}

const useStyles = makeStyles(() => ({
  plusButton: {
    borderRadius: '28px',
    minWidth: '56px',
    minHeight: '56px',
    padding: '0',
    '& > span': {
      fontSize: 30,
    },
  },
}));

export const RoundButton: FunctionComponent<IProps & ButtonProps> = (props) => {
  const classes = useStyles();
  return (
    <Button
      variant="contained"
      {...props}
      className={`${classes.plusButton} ${props.className ? props.className : ''}`}
    >
      {props.children}
    </Button>
  );
};
