import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Drawer, { DrawerProps } from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { Omit } from '@material-ui/types';
import { INavigationItem, IStore } from '../types';
import LogoSmall from '../assets/images/LogoSmall.svg';
import LogoutIcon from '../assets/images/icons/LogoutIcon.svg';

const drawerWidth = 240;
const styles = (theme: Theme) =>
  createStyles({
    categoryHeader: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    categoryHeaderPrimary: {
      color: theme.palette.common.white,
    },
    item: {
      color: '#ffffff',
      height: '36px',
      maxWidth: '224px',
      minWidth: '224px',
      borderTopRightRadius: '20px',
      borderBottomRightRadius: '20px',
      paddingTop: 1,
      paddingBottom: 1,
      paddingLeft: '21px',
      marginBottom: '35px',
      '&:hover,&:focus': {
        backgroundColor: 'rgba(255, 255, 255, 0.08)',
      },
      '&.active': {
        background: '#039be5',
      },
    },
    itemCategory: {
      backgroundColor: '#232f3e',
      boxShadow: '0 -1px 0 #404854 inset',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    firebase: {
      fontSize: 24,
      color: theme.palette.common.white,
    },
    itemActiveItem: {
      color: '#4fc3f7',
    },
    itemPrimary: {
      fontFamily: 'Muli !important',
      fontSize: '13px',
      fontWidth: 'normal',
    },
    itemIcon: {
      minWidth: 'auto',
      marginRight: theme.spacing(2),
    },
    divider: {
      marginTop: theme.spacing(2),
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: '66px',
    },
    logo: {
      height: '61px',
      background: '#495062',
      boxShadow: '0px 1px 0 0 rgba(0, 0, 0, 0.12)',
      display: 'flex',
      alignItems: 'center',
      // paddingLeft: '24px',
      position: 'relative',
      overflow: 'hidden',
    },
    logoSmall: {
      width: '66px',
      height: 'auto',
      position: 'relative',
      top: '-3px',
    },
    logoTitle: {
      color: '#ffffff',
      fontSize: '13px',
      fontFamily: 'Muli',
      lineHeight: '1.38',
      textTransform: 'uppercase',
      width: '174px',
      display: 'block',
      whiteSpace: 'pre-wrap',
      padding: '8px',
    },
    list: {
      paddingTop: '50px',
      paddingBottom: '50px',
      position: 'static',
      overflowY: 'auto',
    },
    drawerPaper: {
      background: '#303030',
      border: 'none',
    },
    iconRoot: {
      minWidth: '38px',
    },
    disableText: {
      display: 'none',
    },
    logout: {
      minWidth: drawerWidth,
      borderRadius: 0,
      paddingRight: 0,
      background: '#039be5',
      height: '60px',
      position: 'absolute',
      bottom: 0,
      left: 0,
      marginBottom: 0,
    },
    logoutClose: {
      // position: 'static',
    },
  });

export interface INavigatorProps extends Omit<DrawerProps, 'classes'>, WithStyles<typeof styles> {}

function Navigator(props: INavigatorProps) {
  const { classes, ...other } = props;
  const appSettings = useSelector((state: IStore) => state.appSettings);
  const navigationItems = useSelector((state: IStore) => state.navigation);

  return (
    <Drawer
      variant="permanent"
      {...other}
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: appSettings.isDrawerOpen,
        [classes.drawerClose]: !appSettings.isDrawerOpen,
      })}
      classes={{
        paper: clsx(classes.drawerPaper, {
          [classes.drawerOpen]: appSettings.isDrawerOpen,
          [classes.drawerClose]: !appSettings.isDrawerOpen,
        }),
      }}
    >
      <div className={classes.logo}>
        <img src={LogoSmall} alt="DTTA small logo" className={classes.logoSmall} />
        {appSettings.isDrawerOpen ? (
          <span className={classes.logoTitle}>District Transportation Tracking Application</span>
        ) : (
          ''
        )}
      </div>
      <List className={classes.list}>
        {navigationItems.map((navElement: INavigationItem, id: number) => (
          <React.Fragment key={id}>
            <ListItem
              id={`root-navigation-${navElement.url?.substr(1)}`}
              className={classes.item}
              component={NavLink}
              to={navElement.url || ''}
              exact
            >
              <ListItemIcon
                classes={{
                  root: classes.iconRoot,
                }}
              >
                <img src={navElement.icon} alt={'navigate'} />
              </ListItemIcon>
              <ListItemText
                classes={{
                  primary: clsx(classes.itemPrimary, {
                    [classes.disableText]: !appSettings.isDrawerOpen,
                  }),
                }}
              >
                {navElement.title}
              </ListItemText>
            </ListItem>
          </React.Fragment>
        ))}
        <ListItem
          id={`root-navigation-logout`}
          className={clsx(classes.item, classes.logout, {
            [classes.logoutClose]: !appSettings.isDrawerOpen,
          })}
          component={NavLink}
          to={'/logout'}
          exact
        >
          <ListItemIcon
            classes={{
              root: classes.iconRoot,
            }}
          >
            <img src={LogoutIcon} alt={'logout'} />
          </ListItemIcon>
          <ListItemText
            classes={{
              primary: clsx(classes.itemPrimary, {
                [classes.disableText]: !appSettings.isDrawerOpen,
              }),
            }}
          >
            Logout
          </ListItemText>
        </ListItem>
      </List>
    </Drawer>
  );
}

export default withStyles(styles)(Navigator);
